import { PortfolioConstants } from "./portfolio.constants";

const initialState = {
  allPortfolios: [],
  isInitializedAllPortfolios: false,
  portfolioTransactions: [],
  isInitializedAllPortfolioTransactions: false,
};

export const portfolioReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PortfolioConstants.PORTFOLIOS_USERS_FETCH_SUCCESS:
      return {
        ...state,
        allPortfolios: payload.allPortfolios,
        isInitializedAllPortfolios: true,
      };
      case PortfolioConstants.PORTFOLIOS_TRANSACTIONS_SUCCESS:
        return {
          ...state,
          portfolioTransactions: payload.portfolioTransactions,
          isInitializedAllPortfolioTransactions: true,
        };  
    default:
      return state;
  }
};


