export const isValidName = (name) => {
  const re = /^([\p{L} ,.'-]{1,30})+$/u;
  return re.test(name);
};

export const isValidEmail = (email) => {
  const re =
    /^([a-zA-Z0-9_\.\-]{1,20})+\@(([a-zA-Z0-9\-]{1,20})+\.)+([a-zA-Z0-9]{2,4})+$/;
  return re.test(email);
};

export const isValidPostalCode = (postalCode, countryCode = "US") => {
  var re;
  switch (countryCode) {
    case "US":
      re = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      break;
    case "CA":
      re = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
      break;
    default:
      re = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
  }
  return re.test(postalCode);
};

export const isValidAddress = (address) => {
  const re = /^[a-zA-Z0-9\s,.'-]{3,100}$/;
  return re.test(address);
};
