import React, { useState, useReducer } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { NotificationManager } from "react-notifications";
import { Button, Form, Image } from "react-bootstrap";

import classNames from "classnames";

import { useAuth } from "../_utils/auth_context";

import { formatFireError } from "../firebase";

import { isValidPassword } from "../_utils/utils";

import { userActions } from "../_redux/users/user.actions";

import { signOnStyles } from "../_ui/theme";
import { userService } from "../_api/user.service";

import BackgroundImage from "../_assets/img/signinbg.png";
import { display } from "@mui/system";
import { callService } from "../_api/api";

const initialState = {
  formData: {
    email: "",
    password: "",
  },
  formErrors: {
    email: "",
    password: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFormData":
      let _formData = { ...state.formData };
      let _formErrors = { ...state.formErrors };

      _formData[action.field] = action.payload;

      if (action.field === "password") {
        _formData.password = action.payload.replace(/\s+/g, "");
      }

      return {
        ...state,
        formData: _formData,
        formErrors: { ..._formErrors, [action.field]: "" },
      };

    case "setFormErrors":
      return {
        ...state,
        formErrors: action.payload,
      };

    // case "reset":
    //   const { user } = action.payload;
    //   let resetState = JSON.parse(JSON.stringify(initialState)); //deep copy
    //   return resetState;
    default:
      throw new Error();
  }
};

const Login = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  loginSuccess,
  register,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { formData, formErrors } = state;
  //const {} = props;

  /** authentification helpers (hook) */
  const { login } = useAuth();

  /** used for internal button state  */
  const [loading, setLoading] = useState(false);

  /** router hook for navigation */
  const navigate = useNavigate();
  const { location } = useLocation();

  const _handleGoogleLogin = async () => {
    setLoading(true);
    //Sign in with Google popup
    const res = await userService.useGoogle(); 
    try{
      if (res.isNew){
        //Adds user to DB
        await callService("addUser", res.userData)
      }
      loginSuccess(res);
      setLoading(false)
    } catch (e){
      setLoading(false)
      // NotificationManager.error(formatFireError(e));
    }
  };

  const checkAndSumbitLogin = async () => {
    let formData = { ...state.formData };
    let formErrors = { ...state.formErrors };

    // trims all strings
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === "string" ||
        formData[key] instanceof String
      ) {
        formData[key] = formData[key].trim();
      }
    });

    if (!isEmail(formData.email)) {
      formErrors.email = "Empty or invalid value";
    }
    if (!isValidPassword(formData.password)) {
      formErrors.password = "Empty or invalid value";
    }

    const hasErrors = Object.values(formErrors).some(
      (error) => error.length !== 0
    );

    if (hasErrors) {
      dispatch({
        type: "setFormErrors",
        payload: formErrors,
      });
    } else {
      setLoading(true);
      try {
        // handle login via firebase
        const loginResponse = await login(formData.email, formData.password);
        loginSuccess(loginResponse);
        setLoading(false);
        navigate(location ? location.path : "/home");
      } catch (error) {
        setLoading(false);
        NotificationManager.error(formatFireError(error.code));
      }
    }
  };

  return (
    <div
      className="container mx-auto"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "80%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section
        className={classNames(
          "signin section",
          topOuterDivider && "has-top-divider",
          bottomOuterDivider && "has-bottom-divider",
          hasBgColor && "has-bg-color",
          invertColor && "invert-color",
          className
        )}
      >
        <div className="container">
          <div
            className={classNames(
              "signin-inner section-inner",
              topDivider && "has-top-divider",
              bottomDivider && "has-bottom-divider"
            )}
          >
            <text className="mb-3 fw-bold fs-5 text-light">{`👋 Welcome back!`}</text>
            <br />
            <text className="mb-0 fw-bold fs-5 text-light">{`\nLogin and keep going!`}</text>
            <div className="tiles-wrap justify-content-start">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        style={signOnStyles}
                        placeholder="Enter email"
                        name="email"
                        disabled={false}
                        value={formData.email}
                        onChange={(e) =>
                          dispatch({
                            type: "setFormData",
                            field: e.target.name,
                            payload: e.target.value,
                          })
                        }
                      />
                      {formErrors.email && (
                        <Form.Text className="text-danger">
                          {formErrors.email}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        style={signOnStyles}
                        type="password"
                        placeholder="Password"
                        name="password"
                        disabled={false}
                        value={formData.password}
                        onChange={(e) =>
                          dispatch({
                            type: "setFormData",
                            field: e.target.name,
                            payload: e.target.value,
                          })
                        }
                      />
                      {formErrors.password && (
                        <Form.Text className="text-danger">
                          {formErrors.password}
                        </Form.Text>
                      )}
                    </Form.Group>

                    <Button
                      style={{
                        color: "black",
                        border: 0,
                        backgroundColor: "#00EBAA",
                        width: "20rem",
                      }}
                      variant="primary"
                      background-color="#00EBAA"
                      type="submit"
                      wide
                      disabled={loading}
                      onClick={async () => checkAndSumbitLogin()}
                    >
                      Sign in
                    </Button>
                  </Form>

                  <Form.Group
                    className="my-3 d-flex justify-content-between align-items-center"
                    controlId="formBasicCheckbox"
                  >
                    {/* MAKE FUNCTIONAL */}
                    <Form.Check>
                      <Form.Check.Input className="bg-dark border-primary" />
                      <Form.Check.Label style={{ fontSize: "16px" }}>
                        Remember me
                      </Form.Check.Label>
                    </Form.Check>
                    {/* MAKE FUNCTIONAL */}
                    <Link
                      to="/login"
                      className="func-link text-xs"
                      style={{ color: "#306BF1" }}
                    >
                      Forgot password?
                    </Link>
                  </Form.Group>

                  {/* MUST UPDATE PATHS AND MAKE THEM ACTUALLY WORK */}

    
                  <Button onClick={_handleGoogleLogin}>
                    <img
                      width="20px"
                      alt="Google sign-in"
                      style={{ display: "inline-block", marginRight: "1rem" }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                    />
                    Continue with Google
                  </Button>

                 

                  <div className="signin-bottom has-top-divider">
                    <div className="mt-2 mb-5 text-xs text-color-low">
                      Don't have an account?{" "}
                      <Link to="/signup/" className="func-link text-primary">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const actionCreators = {
  register: userActions.register,
};

export default connect(mapStateToProps, {
  loginSuccess: userActions.loginSuccess,
  register: userActions.register,
})(Login);
