import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import BrokerSettingsCard from "./broker-settings-card";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { config } from "../config";

const BrokersConnectPage = (props) => {
  const { user } = props;
  const [showingNewAccounts, setShowingNewAccounts] = useState(true);
  const [showingConnectedAccounts, setShowingConnectedAccounts] =
    useState(false);

  const allActiveBrokers = [
    config.alpaca,
    config.ftx,
    config.binance,
    config.coinbase,
    config.robinhood,
  ];
  // const allActiveBrokers = [config.alpaca];

  const availableBrokers = allActiveBrokers.filter(
    (exchange) =>
      !user.connectedAccounts.find(
        (account) => account.name.toLowerCase() === exchange.name.toLowerCase()
      )
  );

  const alreadyConnectedBrokers = allActiveBrokers.filter((exchange) =>
    user.connectedAccounts.find(
      (account) => account.name.toLowerCase() === exchange.name.toLowerCase()
    )
  );

  return (
    <div className="d-flex flex-column" style={{ paddingBottom: "50vh" }}>
      <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
        <Button
          style={{ color: "white", border: "none" }}
          onClick={() => window.history.back()}
        >
          <ArrowBackIcon />
        </Button>
        Connect new Accounts to Daedalus
      </h1>

      <div
        style={{
          padding: "10px",
          width: "80vw",
          marginTop: "2vh",
          marginBottom: "6vh",
          display: "flex",
          backgroundColor: "#0E0E13",
          borderRadius: "20px",
        }}
      >
        <Button
          style={{
            width: "39vw",
            backgroundColor: `${showingNewAccounts ? "#191922" : "#0E0E13"}`,
            border: "none",
          }}
          onClick={() => {
            setShowingNewAccounts(true);
            setShowingConnectedAccounts(false);
            console.log("showing new accounts" + showingNewAccounts);
          }}
        >
          New Accounts
        </Button>
        <span style={{ width: "2vw" }}></span>
        <Button
          style={{
            padding: "10px",
            width: "39vw",
            backgroundColor: `${
              showingConnectedAccounts ? "#191922" : "#0E0E13"
            }`,
            border: "none",
          }}
          onClick={() => {
            setShowingConnectedAccounts(true);
            setShowingNewAccounts(false);
          }}
        >
          Connected Accounts
        </Button>
      </div>
      <div class="alert alert-secondary" style={{backgroundColor: "#316CF4", textAlign:"center", color: "white", border:"none"}} role="alert">
        ⚠️ Currently we only support Alpaca. We are working on adding more exchanges soon!
      </div>
      {availableBrokers.length > 0 && showingNewAccounts && (
        <div className="row">
          {availableBrokers.map((broker, index) => (
            <BrokerSettingsCard disabled={false} broker={broker} />
          ))}
        </div>
      )}

      {alreadyConnectedBrokers.length > 0 && showingConnectedAccounts && (
        <div>
          <h1 align="center">Connected Accounts</h1>
          {alreadyConnectedBrokers.map((broker, index) => (
            <BrokerSettingsCard
              disabled={false}
              broker={broker}
              linkedAccount={user.connectedAccounts.find(
                (account) => account.name === broker.name
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {})(BrokersConnectPage);
