import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Input from "../_layout/Input";
import { connect } from "react-redux";
import { addPortfolio } from "../_redux/portfolio/portfolio.actions";
import { useDispatch } from "react-redux";

const AddPortfiloModal = ({ isOpen, onClose, onSave, user, portfolioDoc }) => {
  const [portFolioName, setPortFolioName] = useState(null);
  const [processing, setProcessing] = useState(null);
  const dispatch = useDispatch();

  const process = async () => {
    setProcessing(true);

    const resData = dispatch(
      addPortfolio(
        {
          id: Date.now().toString(),
          name: portFolioName,
          user_id: user.uid,
          value: 0,
        },
        user
      )
    );
    resData.then((res) => {
      onSave();
    });
    setProcessing(false);
  };

  return (
    <Modal show={isOpen} contentClassName="bg-dark">
      <ModalHeader>
        <h1>Add New ortfolio</h1>
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          label="Portfolio name"
          size="sm"
          placeholder="Portfolio name"
          onChange={(e) => setPortFolioName(e.target.value)}
          value={portFolioName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="success"
          size="sm"
          onClick={() => process()}
          disabled={processing}
        >
          Save
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => onClose()}
          disabled={processing}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const actionCreators = {
  addPortfolio: addPortfolio,
};

export default connect(null, actionCreators)(AddPortfiloModal);
