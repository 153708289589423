import axios from "axios";
import qs from "querystring";
import firebase from "firebase/compat/app";
import {
  doc,
  collection,
  addDoc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { config } from "../config";
import { constants } from "buffer";

const functions = getFunctions();
export const callService = async (serviceName, params = {}) => {
  const callableFirebaseFunction = httpsCallable(functions, serviceName);
  return callableFirebaseFunction(params);
};

const setHeaders = (accessToken) => ({
  headers: { Authorization: `Bearer ${accessToken}` },
});

const ALPACA_API_URL = "https://api.alpaca.markets";
const alpacaDefaultParams = {
  client_id: config.alpaca.client_id,
  redirect_uri: config.globalVariables.oauth_redirect_uri,
};

const COINBASE_API_URL = "https://api.coinbase.com";
const coinbaseDefaultParams = {
  client_id: config.coinbase.client_id,
  redirect_uri: config.globalVariables.oauth_redirect_uri,
};

const BINANCE_API_URL = "https://accounts.binance.com";
const binanceDefaultParams = {
  client_id: config.binance.client_id,
  client_secret: config.binance.client_secret,
  redirect_uri: config.globalVariables.oauth_redirect_uri,
};

export default {
  admin: {
    fetchUsersForAdmin: async () => {
      const snapshots = await firebase.firestore().collection("USERS").get();
      const allUsers = snapshots.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        uid: doc.id,
      }));

      return allUsers;
    },
  },
  portfolios: {
    fetchPortfolios: async (user) => {
      const usersRef = query(
        collection(db, "portfolios"),
        where("user_id", "==", user.uid)
      );
      const snapshots = await getDocs(usersRef);
      console.log(snapshots.docs, "snapshots");
      const allPortfolios = snapshots.docs.map((doc) => ({
        ...doc.data(),
      }));
      return allPortfolios;
    },
    fetchPortfolioTransactions: async (portfolio_id) => {
      const transactionsRef = query(
        collection(db, "portfolios", portfolio_id, "transactions")
      );
      const snapshots = await getDocs(transactionsRef);
      const transactions = snapshots.docs.map((doc) => ({
        ...doc.data(),
      }));
      return transactions;
    },
    addPortfolio: async (portfolio, user) => {
      const portfolio_ids = user.portfolio_ids ? user.portfolio_ids : [];
      await setDoc(doc(collection(db, "portfolios"), portfolio.id), portfolio);

      const docRef = doc(db, "portfolios", portfolio.id);
      const docSnap = await getDoc(docRef);
      const docSnapData = docSnap.data();
      const portfolio_id = docSnapData.id;

      portfolio_ids.push(portfolio_id);
      setDoc(
        doc(db, `USERS`, user.uid),
        { portfolio_ids: portfolio_ids },
        { merge: true }
      );

      // const tansactionDocRef = doc(db, `portfolios`, docSnap.id, `transactions`, "1238327373838399");
      // await setDoc(tansactionDocRef, {"id": "1238327373838399"});

      return docSnapData;
    },
    editPortfolio: async (id, portfolio) => {
      const docRef = doc(db, `portfolios`, id);
      return await setDoc(docRef, portfolio, { merge: true });
    },
    deletePortfolio: async (id, user) => {
      const available_portfolio_ids = user.portfolio_ids;
      const current_portfolio_ids = available_portfolio_ids.filter((item) => {
        return item !== id;
      });
      setDoc(
        doc(db, `USERS`, user.uid),
        { portfolio_ids: current_portfolio_ids },
        { merge: true }
      );

      const docRef = doc(db, `portfolios`, id);
      return await deleteDoc(docRef);
    },
  },
  transaction: {
    addTransaction: async (portfolio, transaction) => {
      const docRef = await setDoc(
        doc(db, `portfolios`, portfolio.id, `transactions`, transaction.id),
        transaction
      );
      return docRef;
    },
    deleteTransaction: async (portfolio_id, transaction_id) => {
      const docRef = doc(
        db,
        `portfolios`,
        portfolio_id,
        `transaction_id`,
        transaction_id
      );
      return await deleteDoc(docRef);
    },
  },
  listings: {
    addTransaction: async (portfolio, transaction) => {
      const docRef = await setDoc(
        doc(db, `portfolios`, portfolio.id, `transactions`, transaction.id),
        transaction
      );
      return docRef;
    },
  },
  brokers: {
    getFTXAssets: () => callService("getFTXAssets").then((res) => res.data),
    getAlpacaAssets: () =>
      callService("getAlpacaAssets").then((res) => res.data),
    getAlpacaAccount: (code) =>
      axios
        .get(ALPACA_API_URL + "/v2/account", setHeaders(code.access_token))
        .then((res) => res.data),
    //
    getAllCoinbaseAssets: () =>
      callService("getCoinbaseAssets").then((res) => res.data),
    getCoinbaseUser: (code) =>
      axios
        .get(COINBASE_API_URL + "/v2/user", setHeaders(code.access_token))
        .then((res) => res.data),
    getCoinbaseAccount: (code) =>
      axios
        .get(COINBASE_API_URL + "/v2/accounts", setHeaders(code.access_token))
        .then((res) => res.data),
    //
    getBinanceAuthToken: (code) =>
      axios
        .post(
          BINANCE_API_URL +
            "/oauth/token?" +
            qs.stringify({
              ...binanceDefaultParams,
              grant_type: "authorization_code",
              code: code,
            })
        )
        .then((res) => res.data),
    getBinanceAccount: (code) =>
      axios
        .get(
          BINANCE_API_URL + "/oauth-api/v1/balance",
          setHeaders(code.access_token)
        )
        .then((res) => res.data),

    getCryptoListings: () =>
      callService("getCryptoListing").then((res) => res.data),
    getStockListings: () =>
      callService("getStockListing").then((res) => res.data),
    getStockPrice: (payload) =>
      callService("getStockPrice", payload).then((res) => res.data),
  },
  payments: {
    getStripeConfig: () =>
      callService("getStripeConfig").then((res) => res.data),
    stripeCreateCheckoutSession: (data) =>
      callService("stripeCreateCheckoutSession", data).then((res) => res.data),
    stripeCancelSubscription: () =>
      callService("stripeCancelSubscription").then((res) => res.data),
    createCustomer: () =>
      callService("createUserAccountOnStripe").then((res) => res.data),
    updateUserCreditCardInfo: () =>
      callService("updateUserCreditCardInfo").then((res) => res.data),
  },
  strategies: {
    createStrategy: (input) =>
      callService("saveStrategy", input).then((res) => res.data),
    updateStrategy: (input) =>
      callService("updateStrategy", input).then((res) => res.data),
    getStrategies: () => callService("getStrategies").then((res) => res.data),
  },
  templates: {
    getTemplateStrategies: () =>
      callService("getTemplateStrategy").then((res) => res.data),
  },
  user: {
    updateUser: (user) =>
      callService("updateUser", user).then((res) => res.data),
    addUser: (user) => callService("addUser", user).then((res) => res.data),
  },
  mockup: {
    mockupFunction: (input) =>
      new Promise((resolve, reject) => {
        console.log(input);
        let error = false;
        if (!error) {
          let res = {
            success: true,
            messsage: "everything fine",
          };
          resolve(res);
        } else {
          //let err = new Error("Wrong username/password");
          let err = {
            success: false,
            message: "awful error",
          };
          reject(err);
        }
      }),
  },
};
