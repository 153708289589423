import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  numberFormatter,
  percentageDifference,
  getProfitLossColor,
  aggregateListPrice,
} from "../_utils/utils-portfolio";

const Asssets = ({
  portfolioTransactions,
  openEditPortfolioModal,
  handleDeleteTransaction,
  openTradingModal,
}) => {
  const [transactions, setTransactions] = useState([]);
  const [newTransactions, setNewTransactions] = useState([]);

  const getAssetValue = async (portfolioTransactions) => {
    const newTransactions_ = [];
    for (let transaction of portfolioTransactions) {
      const url = `https://min-api.cryptocompare.com/data/price?fsym=${transaction.symbol}&tsyms=USD&api_key=5a76a5adc984a5eaf9eadb1a2fbfbbb273141d318043c8ffb987ffd8e3932349`;
      const response = await axios.get(url);
      const result = await response.data;
      const currentPrice = result.USD * transaction.amount;

      transaction.currentPrice = currentPrice;
      newTransactions_.push(transaction);
    }
    setNewTransactions(newTransactions_);
  };

  useEffect(() => {
    console.log("newTransactions", newTransactions);
  }, [newTransactions]);

  useEffect(() => {
    const aggregatedPrice = aggregateListPrice(portfolioTransactions);
    getAssetValue(aggregatedPrice);

    setTransactions(aggregatedPrice);
  }, [portfolioTransactions]);

  return (
    <table className="min-w-full">
      <thead className="text-white">
        <tr>
          <th className="px-6 py-3">Symbol</th>
          <th className="px-6 py-3">Price</th>
          <th className="px-6 py-3">Amount</th>
          <th className="px-6 py-3">Profile/Loss</th>

          <th className="px-6 py-3">
            Action
            <Button
              variant="success"
              size="sm"
              className="ml-4"
              style={{ marginLeft: "0.8rem" }}
              onClick={() => openTradingModal("buy")}
            >
              + Create
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {newTransactions.map((transaction, index) => (
          <tr
            key={index}
            className="cursor-pointer border-b transition duration-300 ease-in-out hover:bg-gray-300 hover:text-black"
          >
            <td className="px-6 py-4">{transaction.symbol}</td>
            <td className="px-6 py-4">${numberFormatter(transaction.price)}</td>
            <td className="px-6 py-4">{transaction.amount}</td>
            <td className="px-6 py-4">
              {numberFormatter(transaction.price - transaction.currentPrice)}
            </td>

            <td className="px-6 py-4">
              <Button
                variant="success"
                size="sm"
                className="mr-4 ml-4"
                onClick={() => openEditPortfolioModal(transaction)}
              >
                EDIT
              </Button>
              <Button
                variant="danger"
                size="sm"
                className="ml-4"
                onClick={() => handleDeleteTransaction(transaction.id)}
              >
                DELETE
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Asssets;
