import { brokersConstants } from "./brokers.actions";

const initialState = {
  alpacaAccount: null,
  allAssetsAlpaca: [],
  stockAssetsAlpaca: [],
  cryptoAssetsAlpaca: [],
  //
  allAssetsFTX: [],
  //
  coinbaseAccount: null,
  coinbaseAllAssets: [],
  coinbaseCryptoAssets: [],
  cryptoListings: [],
  stockListings: [],
  stockPrice: null,
  // isInitialized: false,
  selectedBroker: null,
};

export const brokersReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case brokersConstants.SET_SELECTED_BROKER:
      console.log(payload);
      return {
        ...state,
        selectedBroker: action.payload, //this is the way to refactor this
      };

    case brokersConstants.FETCH_ASSETS_ALPACA_SUCCESS:
      const { allAssetsAlpaca, stockAssetsAlpaca, cryptoAssetsAlpaca } =
        payload;

      return {
        ...state,
        allAssetsAlpaca: allAssetsAlpaca
          .sort((a, b) => a.localeCompare(b))
          .map((asset) => asset),
        stockAssetsAlpaca: stockAssetsAlpaca
          .filter(
            (asset) =>
              // check also the shortable attribute here
              asset.tradable &&
              asset.status === "active" &&
              ["NYSE", "NASDAQ"].includes(asset.exchange)
          )
          .sort((a, b) => a.symbol.localeCompare(b.symbol))
          .map((asset) => asset.symbol),
        cryptoAssetsAlpaca: cryptoAssetsAlpaca
          .filter(
            (asset) =>
              // check also the shortable attribute here
              asset.tradable && asset.status === "active"
          )
          .sort((a, b) => a.symbol.localeCompare(b.symbol))
          .map((asset) => asset.symbol),
      };

    case brokersConstants.FETCH_ASSETS_FTX_SUCCESS:
      const { allAssetsFTX } = payload;

      return {
        ...state,
        allAssetsFTX: allAssetsFTX
          .filter(
            (asset) =>
              // check also the shortable attribute here
              asset.enabled && asset.type !== "future"
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((asset) => asset.name),
      };

    case brokersConstants.FETCH_ASSETS_COINBASE_SUCCESS:
      return {
        ...state,
        coinbaseAllAssets: payload.coinbaseAllAssets.sort((a, b) =>
          a.localeCompare(b)
        ),
        coinbaseCryptoAssets: payload.coinbaseAllAssets.sort((a, b) =>
          a.localeCompare(b)
        ),
      };

    case brokersConstants.FETCH_CRYPTO_LISTIGS_SUCCESS:
      return {
        ...state,
        cryptoListings: payload.cryptoListings,
      };
    case brokersConstants.FETCH_STOCK_LISTIGS_SUCCESS:
      return {
        ...state,
        stockListings: payload.stockListings,
      };
    case brokersConstants.FETCH_STOCK_PRICE_SUCCESS:
      return {
        ...state,
        stockPrice: payload.stockPrice,
      };
  
    default:
      return state;
  }
};
