import { NotificationManager } from "react-notifications";
import api from "../../_api/api";

export const templatesConstants = {
  TEMPLATESTRATEGIES_FETCH_START: "TEMPLATESTRATEGIES_FETCH_START",
  TEMPLATESTRATEGIES_FETCH_SUCCESS: "TEMPLATESTRATEGIES_FETCH_SUCCESS",
  TEMPLATESTRATEGIES_FETCH_FAIL: "TEMPLATESTRATEGIES_FETCH_FAIL",
};

export const fetchTemplateStrategies = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: templatesConstants.TEMPLATESTRATEGIES_FETCH_START,
        payload: {},
      });

      const res = await api.templates.getTemplateStrategies();
      const { status, err, strategies } = res;
      const templateStrategies = strategies || [];

      dispatch({
        type: templatesConstants.TEMPLATESTRATEGIES_FETCH_SUCCESS,
        payload: { templateStrategies },
      });
      return true;
    } catch (e) {
      dispatch({
        type: templatesConstants.TEMPLATESTRATEGIES_FETCH_FAIL,
        payload: {},
      });
      return false;
    }
  };
};
