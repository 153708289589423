import * as Blockly from "blockly/core";

// ---------- //

Blockly.Blocks["send_email_block"] = {
  init: function () {
    this.jsonInit({
      type: "send_email_block",
      message0: "Send message:%1",
      args0: [
        {
          type: "field_input",
          name: "MESSAGE",
        },
      ],
      message1: "to email address: %1 ",
      args1: [
        {
          type: "field_input",
          name: "EMAIL_ADDRESS",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
    });
  },
};

Blockly.JavaScript["send_email_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let message = block.getFieldValue("MESSAGE");
  let email = block.getFieldValue("EMAIL_ADDRESS");
  var code = `result= ACTmessage: ${message} email:${email}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// ---------- //

Blockly.Blocks["send_text_block"] = {
  init: function () {
    this.jsonInit({
      type: "send_text_block",
      message0: "Send Text:%1",
      args0: [
        {
          type: "field_input",
          name: "MESSAGE",
        },
      ],
      message1: "to phone number: %1 ",
      args1: [
        {
          type: "field_number",
          name: "PHONE_NUMBER",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
    });
  },
};

Blockly.JavaScript["send_text_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let pn = block.getFieldValue("PHONE_NUMBER");
  let message = block.getFieldValue("MESSAGE");
  var code = `result= ACTmessage:${message} phoneNumber:${pn}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// ---------- //

Blockly.Blocks["subredditContains"] = {
  init: function () {
    this.jsonInit({
      type: "subredditContains",
      message0: "Subreddit r/%1",
      args0: [
        {
          type: "field_input",
          name: "USER",
        },
      ],
      message1: " 's discussion contains: %1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      message2: "and Sentiment: %1 ",
      args2: [
        {
          type: "field_dropdown",
          name: "SENTIMENT",
          options: [
            ["Positive", "POS"],
            ["Negative", "NEG"],
          ],
        },
      ],

      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["subredditContains"] = function (block) {
  var text_user = block.getFieldValue("USER");
  var dropdown_crypto = block.getFieldValue("ASSET");
  var dropdown_sentiment = block.getFieldValue("SENTIMENT");
  // TODO: Assemble JavaScript into code variable.
  var code = `${text_user}: post reddit ${dropdown_crypto} with ${dropdown_sentiment}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["tweet_contains"] = {
  init: function () {
    this.jsonInit({
      type: "send_tweet_block",
      message0: "Tweet from Twitter User @%1",
      args0: [
        {
          type: "field_input",
          name: "USER",
        },
      ],
      message1: "contains: %1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      message2: "and Sentiment: %1 ",
      args2: [
        {
          type: "field_dropdown",
          name: "SENTIMENT",
          options: [
            ["Positive", "POS"],
            ["Negative", "NEG"],
          ],
        },
      ],

      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["tweet_contains"] = function (block) {
  var text_user = block.getFieldValue("USER");
  var dropdown_crypto = block.getFieldValue("ASSET");
  var dropdown_sentiment = block.getFieldValue("SENTIMENT");
  // TODO: Assemble JavaScript into code variable.
  var code = `${text_user}: send tweet ${dropdown_crypto} with ${dropdown_sentiment}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["send_tweet_block"] = {
  init: function () {
    this.jsonInit({
      type: "send_tweet_block",
      message0: "Send Tweet:%1",
      args0: [
        {
          type: "field_input",
          name: "Send Text",
        },
      ],
      message1: "from twitter user: %1 ",
      args1: [
        {
          type: "field_input",
          name: "FIELDNAME",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
    });
  },
};

Blockly.Blocks["newsOutletContains_block"] = {
  init: function () {
    this.jsonInit({
      type: "newsOutletContains_block",
      message0: "The latest article from News Outlet ",
      args0: [
        {
          type: "field_dropdown",
          name: "News Outlet",
          options: [
            ["CNN", "CNN"],
            ["Bloomberg", "Bloomberg"],
            ["NBC", "NBC"],
            ["FOX NEWS", "FOX News"],
          ],
        },
      ],
      message1: "contains: %1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      message2: "and Sentiment: %1 ",
      args2: [
        {
          type: "field_dropdown",
          name: "FIELDNAME",
          options: [
            ["Positive", "POS"],
            ["Negative", "NEG"],
          ],
        },
      ],

      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};
