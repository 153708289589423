import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Input from "../_layout/Input";
import { connect } from "react-redux";
import { editPortfolio } from "../_redux/portfolio/portfolio.actions";
import { useDispatch } from "react-redux";

const EditPortfolioModal = ({
  isOpen,
  onClose,
  onSave,
  portfolioDoc,
  updatePortfolioDoc,
}) => {
  const [processing, setProcessing] = useState(null);
  const dispatch = useDispatch();

  const process = async () => {
    setProcessing(true);
    const resData = dispatch(
      editPortfolio(portfolioDoc.id, {
        name: portfolioDoc.name,
      })
    );
    resData.then((res) => {
      onSave();
    });
    setProcessing(false);
  };

  return (
    <Modal show={isOpen} contentClassName="bg-dark">
      <ModalHeader>
        <h1>Edit Portfolio</h1>
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          label="Portfolio name"
          size="sm"
          placeholder="Portfolio name"
          onChange={(e) => updatePortfolioDoc(e.target.value)}
          value={portfolioDoc.name}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="success"
          size="sm"
          onClick={() => process()}
          disabled={processing}
        >
          Save
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => onClose()}
          disabled={processing}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const actionCreators = {
  editPortfolio: editPortfolio,
};

export default connect(null, actionCreators)(EditPortfolioModal);
