import React from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

const LayoutDefault = (props) => {
  const { user, isAdmin } = props;

  const location = useLocation();


  const isShowingSidebar = ["/settings", "/login/", "/login", "/signup", "/signup/"].includes(location.pathname);

  return (
    <div style ={{maxWidth:"100%"}}className ={`d-flex justify-content-${!isShowingSidebar? "end": "center"} align-items-center`}>
      {!isShowingSidebar && <Sidebar />}
      <div style={{width:"calc(100%  - 210px", minHeight: "120vh", display: "flex", flexDirection: "column", justifyContent: (location.pathname == "/my-portfolio")? "flex-start" : "space-between", padding: "30px"}}>
        <Header isShowingLogo={isShowingSidebar} />
        <NotificationContainer />
        <div className="">
          <Outlet />
        </div>
        <Footer  />
      </div>
    </div>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {})(LayoutDefault);
