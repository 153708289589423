export const numberFormatter = (num) => {
  return (Math.round(parseInt(num) * 100) / 100).toLocaleString();
};

export const percentageDifference = (a, b) => {
  a = parseFloat(a);
  b = parseFloat(b);
  const res = 100 * Math.abs((a - b) / ((a + b) / 2));
  return res.toFixed(2);
};

export const getProfitLossColor = (num1, num2) => {
  return num1 > num2 ? "text-danger" : "text-success";
};

const newCoin = {};

export const aggregatePrice = (coins) => {
  for (const coin of coins) {
    if (!newCoin[coin.symbol]) {
      if (coin.per_type == "per coin") {
        coin.usd_price = coin.usd_price * coin.amount;

        newCoin[coin.symbol] = {
          price: coin.usd_price,
          amount: coin.amount,
        };
      } else {
        coin.usd_price = coin.usd_price * 1;
        newCoin[coin.symbol] = {
          price: coin.usd_price,
          amount: coin.amount,
        };
      }
    } else {
      if (coin.per_type == "per coin") {
        newCoin[coin.symbol] = {
          price: newCoin[coin.symbol].price + coin.usd_price * coin.amount,
          amount: newCoin[coin.symbol].amount + coin.amount,
        };
      } else {
        newCoin[coin.symbol] = {
          price: newCoin[coin.symbol].price + coin.usd_price * 1,
          amount: newCoin[coin.symbol].amount + 1,
        };
      }
    }

    if (newCoin[coin.symbol]) {
      newCoin[coin.symbol] = {
        ...newCoin[coin.symbol],
        symbol: coin.symbol,
      };
    }
  }

  const result = Object.values(newCoin);
  let price_ = 0;
  let symbol = "";
  let amount = 0;

  for (let i of result) {
    price_ += i.price;
    amount += i.amount;
    symbol = i.symbol;
  }

  return { price: price_, symbol: symbol, amount: amount };
};

const newListCoin = {};
export const aggregateListPrice = (coins) => {
  for (const coin of coins) {
    if (!newListCoin[coin.symbol]) {
      if (coin.per_type == "per coin") {
        coin.usd_price = coin.usd_price * coin.amount;

        newListCoin[coin.symbol] = {
          price: coin.usd_price,
          amount: coin.amount,
        };
      } else {
        coin.usd_price = coin.usd_price * 1;
        newListCoin[coin.symbol] = {
          price: coin.usd_price,
          amount: coin.amount,
        };
      }
    } else {
      if (coin.per_type == "per coin") {
        newListCoin[coin.symbol] = {
          price: newListCoin[coin.symbol].price + coin.usd_price * coin.amount,
          amount: newListCoin[coin.symbol].amount + coin.amount,
        };
      } else {
        newListCoin[coin.symbol] = {
          price: newListCoin[coin.symbol].price + coin.usd_price * 1,
          amount: newListCoin[coin.symbol].amount + 1,
        };
      }
    }

    if (newListCoin[coin.symbol]) {
      newListCoin[coin.symbol] = {
        ...newListCoin[coin.symbol],
        symbol: coin.symbol,
      };
    }
  }

  return Object.values(newCoin);
};
