import React, { useState, useEffect, useReducer, useRef } from "react";

import { connect } from "react-redux";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import XMLParser from "react-xml-parser";

import { useBlocklyWorkspace } from "react-blockly";
import Blockly from "blockly";
import DarkTheme from "@blockly/theme-dark";
import DarkTheme2 from "./blockly-theme.js";
import "./MainStrategyBuilderViewStyles.css";
import "./blockly-blocks/blocks-constants";

import Loader from "../_layout/Loader";

import BlocklyConfig from "./blockly-config";
import {
  defaultStocksList,
  defaultCryptosList,
} from "./blockly-asset-lists/defaultAssetLists";

import {
  setStrategyToEdit,
  createStrategy,
  updateStrategy,
} from "../_redux/strategies/strategy.actions";

const initialState = {
  initXML: BlocklyConfig.INITIAL_XML,
  assetsList: [],
  // paperTraderPerformance: [],
  // liveTraderPerformance: [],
  // next_execution_at:
  formData: {
    name: "",
    asset1: "_",
    asset2: "_",
    asset3: "_",
    timeframe: "1d",
    marketType: "cryptos",
  },
  formErrors: {
    name: "",
  },
  //
  uid: null,
  userUid: null,
  user: null,
  //
  broker: null,
  exchange: null,
  isLiveWithPaperMoney: true,
  isLiveWithRealMoney: false,
  stopLoss: 0,
  assets: [],
  //
  strategy_code_xml: BlocklyConfig.INITIAL_XML,
  strategy_code_javascript: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFormData":
      let _formData = { ...state.formData };
      let _formErrors = { ...state.formErrors };

      if (action.field === "marketType") {
        //
      }

      _formData[action.field] = action.payload;

      return {
        ...state,
        formData: _formData,
        formErrors: { ..._formErrors, [action.field]: "" },
      };

    case "setFormErrors":
      return {
        ...state,
        formErrors: action.payload,
      };

    case "setAssetsList":
      return {
        ...state,
        assetsList: action.payload.assetsList,
      };

    case "setBlocklyWorkspace":
      return {
        ...state,
        strategy_code_xml: action.payload.strategy_code_xml,
        strategy_code_javascript: action.payload.strategy_code_javascript,
      };

    case "reset":
      const { user, brokerName, strategyToEdit } = action.payload;
      let resetState = JSON.parse(JSON.stringify(initialState)); //deep copy

      switch (brokerName) {
        case "daedalus-paper":
          resetState.broker = "daedalus-paper";
          resetState.exchange = "daedalus-paper";
          break;
        case "alpaca":
          resetState.broker = "alpaca";
          resetState.exchange = "Alpaca"; // uppercase A here
          break;
        case "ftx":
          resetState.broker = "ftx";
          resetState.exchange = "ftx";
          break;
        default:
          // throw new Error();
          break;
      }

      if (strategyToEdit) {
        resetState.initXML = strategyToEdit.xml || BlocklyConfig.INITIAL_XML;
        //
        resetState.uid = strategyToEdit.strategyUid || strategyToEdit.uid;
        resetState.strategyUid =
          strategyToEdit.strategyUid || strategyToEdit.uid;
        resetState.userUid = strategyToEdit.userUid;
        resetState.user = strategyToEdit.user;
        //
        resetState.formData.name = strategyToEdit.name;
        resetState.formData.timeframe = strategyToEdit.timeframe;
        resetState.formData.marketType = strategyToEdit.marketType || "cryptos";
        //
        resetState.isLiveWithPaperMoney = strategyToEdit.isLiveWithPaperMoney;
        resetState.isLiveWithRealMoney = strategyToEdit.isLiveWithRealMoney;
        resetState.stopLoss = strategyToEdit.stopLoss;
        //
        resetState.strategy_code_xml =
          strategyToEdit.strategy_code_xml ||
          strategyToEdit.xml ||
          BlocklyConfig.INITIAL_XML;
        resetState.strategy_code_javascript =
          strategyToEdit.strategy_code_javascript ||
          strategyToEdit.strategy | "";
        //

        if (strategyToEdit.assets) {
          resetState.formData.asset1 = strategyToEdit.assets[0]
            ? strategyToEdit.assets[0]
            : "_";

          resetState.formData.asset2 = strategyToEdit.assets[1]
            ? strategyToEdit.assets[1]
            : "_";

          resetState.formData.asset3 = strategyToEdit.assets[2]
            ? strategyToEdit.assets[2]
            : "_";

          resetState.assets = strategyToEdit.assets;
        }
      }

      return resetState;
    default:
      throw new Error();
  }
};

const StrategyBuilder = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  let blocklyWorkspace = null;

  const location = useLocation();
  const params = useParams();

  const {
    allAssetsAlpaca,
    cryptoAssetsAlpaca,
    stockAssetsAlpaca,
    allAssetsFTX,
    user,
    strategyToEdit,
  } = props;

  const {
    initXML,
    //
    formData,
    formErrors,
    assetsList,
    //
    uid,
    strategy_code_xml,
  } = state;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!Blockly.Extensions.isRegistered("assets_list_extension")) {
      Blockly.Extensions.register("assets_list_extension", function () {
        this.getInput("ASSETSLIST").appendField(
          new Blockly.FieldDropdown(function () {
            return [["_", "_"]];
          }),
          "ASSET"
        );
      });
    }

    dispatch({
      type: "reset",
      payload: {
        user: user,
        brokerName: params.brokerName,
        strategyToEdit: strategyToEdit,
      },
    });

    return () => {
      props.setStrategyToEdit(null);

      if (Blockly.Extensions.isRegistered("assets_list_extension")) {
        Blockly.Extensions.unregister("assets_list_extension");
      }
    };
  }, []);

  useEffect(() => {
    let assetsList = [];
    switch (params.brokerName) {
      case "daedalus-paper":
        // assetsList = defaultStocksList;
        assetsList = defaultCryptosList;
        break;
      case "alpaca":
        assetsList = stockAssetsAlpaca;
        break;
      case "ftx":
        assetsList = allAssetsFTX;
        break;
      default:
        assetsList = defaultCryptosList;
        break;
    }

    dispatch({
      type: "setAssetsList",
      payload: {
        assetsList,
      },
    });
  }, [allAssetsAlpaca, cryptoAssetsAlpaca, stockAssetsAlpaca, allAssetsFTX]);

  // useEffect(() => {
  //     // Blockly.Extensions.unregister("assets_list_extension");

  //   // Blockly.Extensions.register("assets_list_extension", function () {
  //   //   this.getInput("ASSETSLIST").appendField(
  //   //     new Blockly.FieldDropdown(function () {
  //   //       var options = [
  //   //         ["BMAQ", "BMAQ"],
  //   //         ["BMAQR", "BMAQR"],
  //   //       ];

  //   //       // for (var i = 0; i < loaded.data.length; i++) {
  //   //       //   x++;
  //   //       //   options.push(loaded.data[i]);
  //   //       // }
  //   //       return options;
  //   //     }),
  //   //     "ASSET"
  //   //   );
  //   // });

  //   // if (!Blockly.Extensions.isRegistered("assets_list_extension")) {
  //   //   Blockly.Extensions.register("assets_list_extension", function () {
  //   //     this.getInput("ASSETSLIST").appendField(
  //   //       new Blockly.FieldDropdown(function () {
  //   //         return cryptoAssetsAlpaca;
  //   //       }),
  //   //       "ASSET"
  //   //     );
  //   //   });
  //   // }
  // }, [workspace]);

  useEffect(() => {
    console.log(formData.asset1);
    if (
      formData.asset1 !== "_" ||
      formData.asset2 !== "_" ||
      formData.asset3 !== "_"
    ) {
      if (Blockly.Extensions.isRegistered("assets_list_extension")) {
        Blockly.Extensions.unregister("assets_list_extension");

        Blockly.Extensions.register("assets_list_extension", function () {
          this.getInput("ASSETSLIST").appendField(
            new Blockly.FieldDropdown(function () {
              return [formData.asset1, formData.asset2, formData.asset3]
                .filter((symbol) => symbol !== "_")
                .map((symbol) => [symbol, symbol]);
            }),
            "ASSET"
          );
        });
      }
    }
  }, [formData.asset1, formData.asset2, formData.asset3]);

  useEffect(() => {
    console.log(formData.marketType);
    if (Blockly.Extensions.isRegistered("assets_list_extension")) {
      // Blockly.Extensions.unregister("assets_list_extension");

      if (formData.marketType !== "crypto") {
        // Blockly.Extensions.register("assets_list_extension", function () {
        //   this.getInput("ASSETSLIST").appendField(
        //     new Blockly.FieldDropdown(function () {
        //       return stockAssetsAlpaca.map((symbol) => [symbol, symbol]);
        //     }),
        //     "ASSET"
        //   );
        // });
      }
    }
  }, [formData.marketType]);

  useEffect(() => {
    if (
      initXML !== BlocklyConfig.INITIAL_XML &&
      blocklyWorkspace &&
      blocklyWorkspace.workspace &&
      typeof initXML === "string"
    ) {
      // reloads Blockly
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initXML),
        blocklyWorkspace.workspace
      );
    }
  }, [initXML]);

  // BLOCKLY CONFIGURATION
  //
  // https://github.com/nbudin/react-blockly
  //
  // How to Resize Workspace the official way:
  // https://developers.google.com/blockly/guides/configure/web/resizable
  const blocklyRef = useRef(null);

  // let workspace = null
  // let xml = null
  blocklyWorkspace = useBlocklyWorkspace({
    ref: blocklyRef,
    toolboxConfiguration: BlocklyConfig.INITIAL_TOOLBOX_JSON, // this must be a JSON toolbox definition
    initialXml: initXML,
    workspaceConfiguration: {  },
    // onXmlChange: ???
    onWorkspaceChange: () => {
      // when some change is made in the workspace
      // this function is activated and it propagates
      // the change to specific variables.
      const xmlDom = Blockly.Xml.workspaceToDom(blocklyWorkspace.workspace);
      const xmlText = Blockly.Xml.domToPrettyText(xmlDom);

      // generate javascript
      Blockly.JavaScript.addReservedWords("code");
      const code = Blockly.JavaScript.workspaceToCode(
        blocklyWorkspace.workspace
      );
      //

      if (strategy_code_xml !== xmlText) {
        // console.log(strategy_code_xml);
        // console.log(xmlText);

        let unused_xml_but_can_be_useful = new XMLParser().parseFromString(
          xmlText
        );

        // send xml and javascript to state
        dispatch({
          type: "setBlocklyWorkspace",
          payload: {
            strategy_code_xml: xmlText,
            strategy_code_javascript: code,
          },
        });
      }
    },
  });

  // END BLOCKLY CONFIGURATION

  const checkAndSaveStrategy = async (isGoingLive = false) => {
    const { formData, formErrors } = state;

    // this piece is provisional. it may have to be rewritten
    // because the datetime calculation is approximate
    let next_execution_at = new Date();
    switch (state.timeframe) {
      case "1d":
        next_execution_at = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000
        ).getTime();
        break;
      case "4h":
        next_execution_at = new Date(
          new Date().getTime() + 4 * 60 * 60 * 1000
        ).getTime();
        break;
      case "1h":
        next_execution_at = new Date(
          new Date().getTime() + 60 * 60 * 1000
        ).getTime();
        break;
      case "30m":
        next_execution_at = new Date(
          new Date().getTime() + 30 * 60 * 1000
        ).getTime();
        break;
      case "15m":
        next_execution_at = new Date(
          new Date().getTime() + 15 * 60 * 1000
        ).getTime();
        break;
      case "5m":
        next_execution_at = new Date(
          new Date().getTime() + 5 * 60 * 1000
        ).getTime();
        break;
      case "1m":
        // this may have to be deleted
        next_execution_at = new Date(
          new Date().getTime() + 60 * 1000
        ).getTime();
        break;
      default:
        break;
    }

    const strategy = {
      // userId
      next_execution_at,
      name: state.formData.name,
      timeframe: state.formData.timeframe,
      marketType: state.formData.marketType,
      broker: state.broker,
      exchange: state.exchange,
      isLiveWithPaperMoney: false,
      isLiveWithRealMoney: false,
      isStocks: state.isStocks,
      isCryptos: state.isCryptos,
      isForex: state.isForex,
      stopLoss: 0,
      assets: [
        formData.asset1 || "_",
        formData.asset2 || "_",
        formData.asset3 || "_",
      ],
      //
      xml: state.strategy_code_xml, // official field
      strategy: state.strategy_code_javascript, // official field
      strategy_code_xml: state.strategy_code_xml, // preposterous field
      strategy_code_javascript: state.strategy_code_javascript, // preposterous field
    };

    try {
      setLoading(true);
      if (!uid) {
        await props.createStrategy({ ...strategy });
      } else {
        await props.updateStrategy({
          ...strategy,
          uid,
          strategyUid: uid,
          isGoingLive, //activate or deactivate strategy
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="Dashboard flex bg-surface" id="dashboard">
        <div className="flex flex-1 p-8">
          <div className="panel bg-surface flex-1">
            <div className="panel__container flex mt-5">
              <div className="panel__item d-block flex-1 mr-4">
                <Button
                  type="submit"
                  onClick={async () => {
                    Blockly.Extensions.unregister("assets_list_extension");
                  }}
                >
                  test
                </Button>
                <div style={{ color: "white" }}>
                  Create {params.brokerName} Strategy
                </div>

                <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
                  <Col md="3">
                    <Form.Label style={{ fontSize: "20px" }}>Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Strategy name"
                      name="name"
                      disabled={loading}
                      value={formData.name}
                      onChange={(e) =>
                        dispatch({
                          type: "setFormData",
                          field: e.target.name,
                          payload: e.target.value,
                        })
                      }
                    />
                  </Col>

                  <Col md="1">
                    {strategyToEdit ? (
                      <Button
                        type="submit"
                        disabled={loading}
                        onClick={async () => await checkAndSaveStrategy(false)}
                      >
                        {loading ? (
                          <>
                            <Loader />
                            <p>Updating</p>
                          </>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled={loading}
                        onClick={async () => await checkAndSaveStrategy(false)}
                      >
                        {loading ? (
                          <>
                            <Loader />
                            <p>Saving</p>
                          </>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    )}
                  </Col>

                  <Col md="1" style={{ textAlign: "center" }}>
                    {strategyToEdit && (
                      <>
                        {/* <Button
                          disabled={loading}
                          // onClick={async () => await checkAndSaveStrategy(false)}
                        >
                          {isLive ? "Turn off" : "Go Live"}
                        </Button> */}
                        <Button
                          disabled={loading}
                          onClick={async () => {
                            const isGoingLive = true;
                            await checkAndSaveStrategy(isGoingLive);
                          }}
                        >
                          Switch ON
                        </Button>
                        <Button
                          disabled={loading}
                          onClick={async () => {
                            const isGoingLive = false;
                            await checkAndSaveStrategy(isGoingLive);
                          }}
                        >
                          Switch OFF
                        </Button>
                      </>
                    )}
                  </Col>

                  <Col md="1" style={{ textAlign: "right" }}>
                    <Button
                      // disabled={loading}
                      disabled={true}
                      onClick={() => {
                        window.alert("Feature coming soon!");
                      }}
                    >
                      Backtest
                    </Button>
                  </Col>
                </Row>

                <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
                  <Col md="1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      Asset 1
                    </Form.Label>
                    <DropdownButton
                      title={formData.asset1}
                      onSelect={(eventKey, e) =>
                        dispatch({
                          type: "setFormData",
                          field: "asset1",
                          payload: eventKey,
                        })
                      }
                    >
                      {["_"]
                        .concat(assetsList)
                        .slice(0, 5)
                        .filter(
                          (value) =>
                            value === "_" ||
                            (value !== formData.asset1 &&
                              value !== formData.asset2 &&
                              value !== formData.asset3)
                        )
                        .map((value, index) => (
                          <Dropdown.Item key={index} eventKey={value}>
                            {value}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>

                  <Col md="1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      Asset 2
                    </Form.Label>
                    <DropdownButton
                      disabled={formData.asset1 === "_"}
                      title={formData.asset2}
                      onSelect={(eventKey, e) =>
                        dispatch({
                          type: "setFormData",
                          field: "asset2",
                          payload: eventKey,
                        })
                      }
                    >
                      {["_"]
                        .concat(assetsList)
                        .slice(0, 5)
                        .filter(
                          (value) =>
                            value === "_" ||
                            (value !== formData.asset1 &&
                              value !== formData.asset2 &&
                              value !== formData.asset3)
                        )
                        .map((value, index) => (
                          <Dropdown.Item key={index} eventKey={value}>
                            {value}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>

                  <Col md="1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      Asset 3
                    </Form.Label>
                    <DropdownButton
                      disabled={formData.asset3 === "_"}
                      title={formData.asset3}
                      onSelect={(eventKey, e) =>
                        dispatch({
                          type: "setFormData",
                          field: "asset3",
                          payload: eventKey,
                        })
                      }
                    >
                      {["_"]
                        .concat(assetsList)
                        .slice(0, 5)
                        .filter(
                          (value) =>
                            value === "_" ||
                            (value !== formData.asset1 &&
                              value !== formData.asset2 &&
                              value !== formData.asset3)
                        )
                        .map((value, index) => (
                          <Dropdown.Item key={index} eventKey={value}>
                            {value}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>

                  <Col md="1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      Market Type
                    </Form.Label>
                    <Dropdown
                      name="marketType"
                      onSelect={(eventKey, e) =>
                        dispatch({
                          type: "setFormData",
                          field: "marketType",
                          payload: eventKey,
                        })
                      }
                    >
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {formData.marketType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={"cryptos"}>
                          cryptos
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"stocks"}>
                          stocks
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"forex"}>forex</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>

                  <Col md="1">
                    <Form.Label style={{ fontSize: "20px" }}>
                      Timeframe
                    </Form.Label>
                    <Dropdown
                      name="timeframe"
                      onSelect={(eventKey, e) =>
                        dispatch({
                          type: "setFormData",
                          field: "timeframe",
                          payload: eventKey,
                        })
                      }
                    >
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {formData.timeframe || "1d"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {["1d", "4h", "1h", "30m", "15m", "5m", "1m"].map(
                          (value, index) => (
                            <Dropdown.Item key={index} eventKey={value}>
                              {value}
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>

                {/* This is the Blockly Builder */}
                <div style={{ border: "none", width: "1200px" }}>
                  <div ref={blocklyRef} style={{ height: "500px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
  strategy: storeState.strategy,
  strategyToEdit: storeState.strategy.strategyToEdit,
  apiStatus: storeState.apiStatus,
  allAssetsAlpaca: storeState.brokers.allAssetsAlpaca,
  stockAssetsAlpaca: storeState.brokers.stockAssetsAlpaca,
  cryptoAssetsAlpaca: storeState.brokers.cryptoAssetsAlpaca,
  allAssetsFTX: storeState.brokers.allAssetsFTX,
  // coinbaseAllAssets: storeState.brokers.coinbaseAllAssets,
});

export default connect(mapStateToProps, {
  setStrategyToEdit,
  createStrategy,
  updateStrategy,
})(StrategyBuilder);

// field_react_date

// https://codeantenna.com/a/t7GZ80b553

// https://www.csdn.net/tags/MtTaMg2sODI5Njc1LWJsb2cO0O0O.html

// https://github.com/google/blockly/tree/master/blocks

// StandardCategories.coreBlockTypes = [
//   "colour_blend",
//   "colour_picker",
//   "colour_random",
//   "colour_rgb",
//   "controls_flow_statements",
//   "controls_for",
//   "controls_forEach",
//   "controls_if",
//   "controls_repeat_ext",
//   "controls_whileUntil",
//   "logic_boolean",
//   "logic_compare",
//   "logic_negate",
//   "logic_null",
//   "logic_operation",
//   "logic_ternary",
//   "math_arithmetic",
//   "math_change",
//   "math_constant",
//   "math_constrain",
//   "math_modulo",
//   "math_number",
//   "math_number_property",
//   "math_on_list",
//   "math_random_int",
//   "math_random_float",
//   "math_round",
//   "math_single",
//   "math_trig",
//   "procedures_callreturn",
//   "procedures_defnoreturn",
//   "procedures_defreturn",
//   "procedures_ifreturn",
//   "lists_create_with",
//   "lists_getIndex",
//   "lists_getSublist",
//   "lists_indexOf",
//   "lists_isEmpty",
//   "lists_length",
//   "lists_repeat",
//   "lists_setIndex",
//   "lists_sort",
//   "lists_split",
//   "text",
//   "text_append",
//   "text_changeCase",
//   "text_charAt",
//   "text_getSubstring",
//   "text_indexOf",
//   "text_isEmpty",
//   "text_join",
//   "text_length",
//   "text_print",
//   "text_prompt_ext",
//   "text_trim",
//   "variables_get",
//   "variables_set",
// ];
