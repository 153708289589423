import React from "react";

import { useAuth } from "../_utils/auth_context";
import { useLocation, Navigate } from "react-router-dom";
import Loader from "../_layout/Loader";

// just to protect routes like /broker
function RequireAuth({ children }) {
  const { currentUser, loading } = useAuth();

  const location = useLocation();

  if (loading) return <Loader />;

  if (currentUser) {
    if (
      !currentUser.emailVerified &&
      location.pathname.indexOf("/email-verify") === -1
    ) {
      return <Navigate to="/email-verify" />;
    }

    return children;
  }
  return <Navigate to="/login" replace state={{ path: location.pathname }} />;
}

export default RequireAuth;
