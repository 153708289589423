export const userConstants = {
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",

  USER_LOGIN_START: "USER_LOGIN_START",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  USER_LOGOUT_START: "USER_LOGOUT_START",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "USER_LOGOUT_FAIL",

  USER_UPDATE_START: "USER_UPDATE_START",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAIL: "USER_UPDATE_FAIL",
};
