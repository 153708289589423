import { NotificationManager } from "react-notifications";
import api from "../../_api/api";

export const strategyConstants = {
  SET_STRATEGY_TO_EDIT: "SET_STRATEGY_TO_EDIT",
  //
  STRATEGIES_FETCH_START: "STRATEGIES_FETCH_START",
  STRATEGIES_FETCH_SUCCESS: "STRATEGIES_FETCH_SUCCESS",
  STRATEGIES_FETCH_FAIL: "STRATEGIES_FETCH_FAIL",
  STRATEGY_CREATE_START: "STRATEGY_CREATE_START",
  STRATEGY_CREATE_SUCCESS: "STRATEGY_CREATE_SUCCESS",
  STRATEGY_CREATE_FAIL: "STRATEGY_CREATE_FAIL",
  STRATEGY_UPDATE_START: "STRATEGY_UPDATE_START",
  STRATEGY_UPDATE_SUCCESS: "STRATEGY_UPDATE_SUCCESS",
  STRATEGY_UPDATE_FAIL: "STRATEGY_UPDATE_FAIL",
  STRATEGY_DELETE_START: "STRATEGY_DELETE_START",
  STRATEGY_DELETE_SUCCESS: "STRATEGY_DELETE_SUCCESS",
  STRATEGY_DELETE_FAIL: "STRATEGY_DELETE_FAIL",
};

export const deleteStrategy = (input) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: strategyConstants.STRATEGY_DELETE_START,
        payload: {},
      });

      // const { status, err, newStrategy } = await api.strategies.deleteStrategy(
      //   input
      // );

      dispatch({
        type: strategyConstants.STRATEGY_DELETE_SUCCESS,
        payload: {},
      });
      return true;
    } catch (e) {
      dispatch({
        type: strategyConstants.STRATEGY_DELETE_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const createStrategy = (strategy) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: strategyConstants.STRATEGY_CREATE_START,
        payload: {},
      });

      const res = await api.strategies.createStrategy(strategy);
      const { status, err, newStrategy } = res;

      dispatch({
        type: strategyConstants.STRATEGY_CREATE_SUCCESS,
        payload: {},
      });
      NotificationManager.success("Strategy saved successfully!");
      return true;
    } catch (e) {
      dispatch({
        type: strategyConstants.STRATEGY_CREATE_FAIL,
        payload: {},
      });
      NotificationManager.error("Error saving strategy data");
      return false;
    }
  };
};

export const updateStrategy = (input) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: strategyConstants.STRATEGY_UPDATE_START,
        payload: {},
      });

      const { status, err, newStrategy } = await api.strategies.updateStrategy(
        input
      );

      dispatch({
        type: strategyConstants.STRATEGY_UPDATE_SUCCESS,
        payload: {},
      });
      NotificationManager.success("Strategy saved successfully!");
      return true;
    } catch (e) {
      dispatch({
        type: strategyConstants.STRATEGY_UPDATE_FAIL,
        payload: {},
      });
      NotificationManager.error("Error saving strategy data");
      return false;
    }
  };
};

export const fetchStrategies = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: strategyConstants.STRATEGIES_FETCH_START,
        payload: {},
      });

      const res = await api.strategies.getStrategies();
      const { status, err, strategies } = res;

      dispatch({
        type: strategyConstants.STRATEGIES_FETCH_SUCCESS,
        payload: { strategies },
      });
      return true;
    } catch (e) {
      dispatch({
        type: strategyConstants.STRATEGIES_FETCH_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const setStrategyToEdit = (strategy) => {
  return (dispatch) => {
    dispatch({ type: strategyConstants.SET_STRATEGY_TO_EDIT, strategy });
  };
};

export const strategyActions = {
  setStrategyToEdit,
};
