import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GenericSection from "../_layout/GenericSection";
import Pricing from "../_layout/GenericSection";
import { Row, Col, Button } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  getStripeConfig,
  stripeCancelSubscription,
  stripeCreateCheckoutSession,
} from "../_api/stripe.service";
import { config } from "./../config";
import { createCustomerOnStripe } from "../_redux/payments/payments.actions.js";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AddCreditCardDialog from "./AddCreditCardDialog";

const stripePromise = loadStripe(config.stripe.publishable_key_test);

// import {
//   getStripeConfig,
//   stripeCancelSubscription,
//   stripeCreateCheckoutSession,
// } from "../_redux/payments/payments.actions";

const BillingPage = (props) => {
  const { user } = props;

  const [loading, setLoading] = useState(true);
  const [freePrice, setFreePrice] = useState(null);
  const [goldPrice, setGoldPrice] = useState(null);
  const [silverPrice, setSilverPrice] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const options = {
    clientSecret,
  };

  useEffect(() => {
    if(!user.availWeb){
      window.location.href="/home2";
    }
  }, [user])

  //   (async () => {
  //     const {
  //       data: { freePrice, goldPrice, silverPrice },
  //     } = await getStripeConfig();
  //     setFreePrice(freePrice);
  //     setGoldPrice(goldPrice);
  //     setSilverPrice(silverPrice);
  //     setLoading(false);
  //   })();
  // }, []);

  useEffect(() => {
    if(!user.availWeb){
      window.location.href="/home2";
    }
    const createCustomer = async () => {
      try {
        const clientSecret = new URLSearchParams(window.location.search).get(
          "setup_intent_client_secret"
        );
        if (!clientSecret && !user?.is_credit_card_added) {
          const { client_secret } = await props.createCustomerOnStripe();
          setClientSecret(client_secret);
          setOpenDialog(true);
        } else {
          setClientSecret(clientSecret);
          setOpenDialog(true);
        }
      } catch (error) {
        console.log("Error::", error);
      } finally {
        setLoading(false);
      }
    };
    createCustomer();
    //
    if (user.subscription) {
      setCurrentSubscription(user.subscription);
    } else {
      setCurrentSubscription(null);
    }
  }, []);

  const processCheckout = async (priceId) => {
    setIsProcessing(true);
    const { data } = await stripeCreateCheckoutSession({ priceId });
    setIsProcessing(false);
    if (data.sessionUrl) {
      window.location.href = data.sessionUrl;
    } else {
      NotificationManager.error(
        "Something went wrong. Please try again later."
      );
    }
  };

  const cancelSubscription = async () => {
    setIsProcessing(true);
    if (currentSubscription?.subscriptionId) {
      const response = await stripeCancelSubscription();
      setIsProcessing(false);
      if (response.data.success) {
        window.location.href = "/subscription-canceled";
      }
    }
  };

  if (loading) return <h1 className="text-center m-auto">Loading</h1>;

  return (
    <>
      <GenericSection topDivider>
        {/* <Row style={{ marginLeft: "20px" }}>
          <h1>Stripe integration goes in this page</h1>
        </Row>
        <Row>
          <Col xs={3}>
            <Button disabled={false} onClick={() => {}}>
              Check my subscription
            </Button>
          </Col>
        </Row>

        <Row style={{ color: "white" }}>
          <Col xs={3}>
            <div
              style={{
                backgroundColor: "grey",
                width: "400px",
                height: "200px", // was "200px"
                borderRadius: "30px",
                margin: "auto",
                listStyle: "circle",
              }}
            >
              <Row style={{ marginLeft: "20px" }}>
                <h1>Free Plan</h1>
              </Row>
              <Row>
                <ul style={{ marginLeft: "35px" }}>
                  <li>Paper Trading</li>
                </ul>
              </Row>
              <div
                style={{
                  fontSize: "20px",
                  marginTop: "250px",
                  marginLeft: "100px",
                  fontWeight: "bold",
                }}
              >
                $0/M
              </div>
              {!currentSubscription ||
              currentSubscription.priceId !== freePrice ? (
                <Button
                  className="btn-secondary"
                  // disabled={isProcessing}
                  disabled={true}
                  onClick={() => processCheckout(freePrice)}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  I'm a Hodler
                </Button>
              ) : (
                <Button
                  className="btn-danger"
                  // disabled={isProcessing}
                  disabled={true}
                  onClick={() => cancelSubscription()}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Col>

          <Col xs={3}>
            <div
              style={{
                backgroundColor: "grey",
                width: "400px",
                height: "200px", // was "200px"
                borderRadius: "30px",
                margin: "auto",
              }}
            >
              <Row style={{ marginLeft: "20px" }}>
                <h1>Daedalus Bronze</h1>
              </Row>
              <Row>
                <ul style={{ marginLeft: "35px" }}>
                  <li>Paper Trading</li>
                  <li>Live Trading</li>
                </ul>
              </Row>
              <div
                style={{
                  fontSize: "20px",
                  marginTop: "200px",
                  marginLeft: "100px",
                  fontWeight: "bold",
                }}
              >
                $1/M... for the Lulz
              </div>
              {!currentSubscription ||
              currentSubscription.priceId !== silverPrice ? (
                <Button
                  disabled={isProcessing}
                  onClick={() => processCheckout(silverPrice)}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Get this
                </Button>
              ) : (
                <Button
                  className="btn-danger"
                  disabled={isProcessing}
                  onClick={() => cancelSubscription()}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Col>

          <Col xs={3}>
            <div
              style={{
                backgroundColor: "grey",
                width: "400px",
                height: "200px", // was "200px"
                borderRadius: "30px",
                margin: "auto",
              }}
            >
              <Row style={{ marginLeft: "20px" }}>
                <h1>Daedalus Silver</h1>
              </Row>
              <Row>
                <ul style={{ marginLeft: "35px" }}>
                  <li>Paper Trading</li>
                  <li>Live Trading</li>
                </ul>
              </Row>
              <div
                style={{
                  fontSize: "20px",
                  marginTop: "200px",
                  marginLeft: "100px",
                  fontWeight: "bold",
                }}
              >
                $50/M
              </div>
              {!currentSubscription ||
              currentSubscription.priceId !== silverPrice ? (
                <Button
                  disabled={isProcessing}
                  onClick={() => processCheckout(silverPrice)}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Get this
                </Button>
              ) : (
                <Button
                  className="btn-danger"
                  disabled={isProcessing}
                  onClick={() => cancelSubscription()}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Col>

          <Col xs={3}>
            <div
              style={{
                backgroundColor: "grey",
                width: "400px",
                height: "200px", // was "200px"
                borderRadius: "30px",
                margin: "auto",
              }}
            >
              <Row style={{ marginLeft: "20px" }}>
                <h1>Daedalus Gold</h1>
              </Row>
              <Row>
                <ul style={{ marginLeft: "35px" }}>
                  <li>Paper Trading</li>
                  <li>Live Trading</li>
                  <li>Backtesting</li>
                  <li>Trade History</li>
                </ul>
              </Row>
              <div
                style={{
                  fontSize: "20px",
                  marginTop: "100px",
                  marginLeft: "70px",
                  fontWeight: "bold",
                }}
              >
                $75/M
              </div>
              {!currentSubscription ||
              currentSubscription.priceId !== goldPrice ? (
                <Button
                  disabled={isProcessing}
                  onClick={() => processCheckout(goldPrice)}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Get this
                </Button>
              ) : (
                <Button
                  className="btn-danger"
                  disabled={isProcessing}
                  onClick={() => cancelSubscription()}
                  style={{ marginTop: "-2px", marginLeft: "150px" }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Pricing /> */}
      </GenericSection>

      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <AddCreditCardDialog open={openDialog} setOpen={setOpenDialog} />
        </Elements>
      )}
    </>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {
  // getStripeConfig,
  // stripeCancelSubscription,
  // stripeCreateCheckoutSession,
  createCustomerOnStripe,
})(BillingPage);
