import axios from "axios";
import { useEffect, useState } from "react";
import {
  getProfitLossColor,
  numberFormatter,
  percentageDifference,
  aggregatePrice,
} from "../_utils/utils-portfolio";

const PorofolioCard = ({
  portfolioDoc,
  // portfolioCurrentValue,
  // portfolioValue,
  portfolioTransactions,
}) => {
  const [usdTransactions, setUsdTransactions] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState({});
  const [portfolioCurrentValue, setPortfolioCurrentValue] = useState({});

  const handleUsdTransactions = async () => {
    if (usdTransactions.length) return;
    const usdTransactions_ = [];
    for (let transactions of portfolioTransactions) {
      const url = `https://min-api.cryptocompare.com/data/price?fsym=${transactions.currency}&tsyms=USD&api_key=5a76a5adc984a5eaf9eadb1a2fbfbbb273141d318043c8ffb987ffd8e3932349`;
      const response = await axios.get(url);
      const result = await response.data;
      transactions["usd_price"] =
        parseFloat(result["USD"]) * parseFloat(transactions.price);
      transactions["amount"] = parseInt(transactions.amount);
      usdTransactions_.push(transactions);
    }
    setUsdTransactions(usdTransactions_);
  };

  const getCryptoValue = async () => {
    const url = `https://min-api.cryptocompare.com/data/price?fsym=${portfolioValue.symbol}&tsyms=USD&api_key=5a76a5adc984a5eaf9eadb1a2fbfbbb273141d318043c8ffb987ffd8e3932349`;
    const response = await axios.get(url);
    const result = await response.data;
    setPortfolioCurrentValue(portfolioValue.amount * result["USD"]);
  };

  useEffect(() => {
    if (portfolioValue && portfolioValue.price) {
      getCryptoValue();
    }
  }, [portfolioValue]);

  useEffect(() => {
    if (portfolioTransactions && portfolioTransactions.length) {
      handleUsdTransactions();
    }
  }, [portfolioTransactions]);

  useEffect(() => {
    if (usdTransactions && usdTransactions.length) {
      const res = aggregatePrice(usdTransactions);
      setPortfolioValue(res);
    }
  }, [usdTransactions]);

  return (
    <div>
      <h1 className="text-center h5"> {portfolioDoc.name} </h1>
      <h2 className={`text-center h1`}>
        ${numberFormatter(portfolioValue.price)}
      </h2>
      <div className="text-center">
        <small
          className={getProfitLossColor(
            portfolioValue.price,
            portfolioCurrentValue
          )}
        >
          {/* ${numberFormatter(portfolioCurrentValue - portfolioValue.price)} */}
        </small>
        <small
          className={getProfitLossColor(
            portfolioValue.price,
            portfolioCurrentValue
          )}
        >
          {/* &nbsp; |{" "} */}
          {/* {percentageDifference(portfolioValue.price, portfolioCurrentValue)}% */}
        </small>
      </div>
    </div>
  );
};

export default PorofolioCard;
