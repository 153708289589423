import { NotificationManager } from "react-notifications";
import api from "../../_api/api";

export const paymentsConstants = {
  _FETCH_START: "_FETCH_START",
  _FETCH_SUCCESS: "_FETCH_SUCCESS",
  _FETCH_FAIL: "_FETCH_FAIL",

  CREDIR_CARD_SUCCESS: "CREDIR_CARD_SUCCESS",
  CREDIR_CARD_FAIL: "CREDIR_CARD_FAIL",
};

export const getStripeConfig = () => {
  return async (dispatch) => {
    try {
      // await api.payments.getStripeConfig();

      return true;
    } catch (e) {
      return false;
    }
  };
};

export const stripeCreateCheckoutSession = () => {
  return async (dispatch) => {
    try {
      // await api.payments.stripeCreateCheckoutSession();

      return true;
    } catch (e) {
      return false;
    }
  };
};

export const stripeCancelSubscription = () => {
  return async (dispatch) => {
    try {
      // await api.payments.stripeCancelSubscription();

      return true;
    } catch (e) {
      return false;
    }
  };
};

export const createCustomerOnStripe = () => async (dispatch) => {
  try {
    const { success, message, client_secret } =
      await api.payments.createCustomer();

    return { success, message, client_secret };
  } catch (e) {
    NotificationManager.error("Error");
  }
};

export const addCreditCardSuccess = () => async (dispatch) => {
  try {
    await api.payments.updateUserCreditCardInfo();
    dispatch({
      type: paymentsConstants.CREDIR_CARD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: paymentsConstants.CREDIR_CARD_FAIL,
    });
  }
};
