import api from "../../_api/api";
import { PortfolioConstants } from "./portfolio.constants";
import { NotificationManager } from "react-notifications";



export const fetchPortfolios = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PortfolioConstants.PORTFOLIOS_USERS_FETCH_START,
      });

      const allPortfolios = await api.portfolios.fetchPortfolios(user);

      dispatch({
        type: PortfolioConstants.PORTFOLIOS_USERS_FETCH_SUCCESS,
        payload: { allPortfolios },
      });
      return true;
    } catch (e) {
      dispatch({
        type: PortfolioConstants.PORTFOLIOS_USERS_FETCH_FAIL,
      });
      return false;
    }
  };
};


export const fetchPortfolioTransactions = (portfolio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PortfolioConstants.PORTFOLIO_TRANSACTIONS_START,
      });

      const portfolioTransactions = await api.portfolios.fetchPortfolioTransactions(portfolio_id);

      dispatch({
        type: PortfolioConstants.PORTFOLIOS_TRANSACTIONS_SUCCESS,
        payload: { portfolioTransactions },
      });
      return true;
    } catch (e) {
      dispatch({
        type: PortfolioConstants.PORTFOLIOS_TRANSACTIONS_FAIL,
      });
      return false;
    }
  };
};


export const addPortfolio = (portfolio, user) => {
  return async (dispatch) => {
    dispatch({
      type: PortfolioConstants.PORTFOLIO_CREATE_START,
    });

    return await api.portfolios.addPortfolio(portfolio, user).then(
      (user) => {
        NotificationManager.success("Portfolio Created Successfully");
        dispatch({ type: PortfolioConstants.PORTFOLIO_CREATE_SUCCESS, portfolio });
        return user;
      },
      (error) => {
        NotificationManager.error(error.message);
        dispatch({ type: PortfolioConstants.PORTFOLIO_CREATE_FAILED, error: error.message });
      }
    );
  };
};

export const editPortfolio = (id, portfolio) => {
  return async (dispatch) => {
    dispatch({
      type: PortfolioConstants.PORTFOLIO_UPDATE_START,
    });

    return await api.portfolios.editPortfolio(id, portfolio).then(
      (user) => {
        NotificationManager.success("Portfolio Updated Successfully");
        dispatch({ type: PortfolioConstants.PORTFOLIO_UPDATE_SUCCESS, portfolio });
        return user;
      },
      (error) => {
        NotificationManager.error(error.message);
        dispatch({ type: PortfolioConstants.PORTFOLIO_UPDATE_FAILED, error: error.message });
      }
    );
  };
};

export const deletePortfolio = (id, user) => {
  return async (dispatch) => {
    dispatch({
      type: PortfolioConstants.PORTFOLIO_DELETE_START,
    });

    return await api.portfolios.deletePortfolio(id, user).then(
      (user) => {
        NotificationManager.success("Portfolio Deleted Successfully");
        dispatch({ type: PortfolioConstants.PORTFOLIO_DELETE_SUCCESS });
        return user;
      },
      (error) => {
        NotificationManager.error(error.message);
        dispatch({ type: PortfolioConstants.PORTFOLIO_DELETE_FAILED, error: error.message });
      }
    );
  };
};

