import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { strategyActions } from "../_redux/strategies/strategy.actions";
import { useDispatch, useSelector } from "react-redux";

let displayPurposes = Math.random() / 100;
displayPurposes = displayPurposes + 1;
displayPurposes = displayPurposes.toFixed(2);

const TemplatesPage = (props) => {
  const { account, setStrategyToEdit } = props;
  const dispatch = useDispatch();
  const [strategiesList, setStrategiesList] = useState([]);
  useEffect(() => {
    getAllTemplateStrategies();
  }, [account]);
  const getAllTemplateStrategies = async () => {
    // const strategies = await getTemplateStrategies();
    const strategies = [];

    const accountSpecificStrategies = strategies.data.strategies.filter(
      (strategy) => {
        return strategy.account === account.name;
      }
    );

    setStrategiesList(accountSpecificStrategies);
  };

  const navigate = useNavigate();
  const strategyToEdit = (template) => {
    let newStrat = template;
    newStrat.name = template.name + " Copy";
    if (newStrat.uid) {
      delete newStrat.uid;
    }
    dispatch(strategyActions.setStrategyToEdit(template));
    navigate("/strategy-builder");
  };

  // getAllTemplateStrategies();

  return (
    <section className="flex">
      <div className="flex-1 p-8" style={{ color: "white" }}>
        <h1 style={{ color: "white" }}> {account.name} Strategy Templates </h1>
        <table className="table-auto min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">YTD Performance</th>
            </tr>
          </thead>
          <tbody>
            {strategiesList.map((strategy) => {
              return (
                <tr className="border-b">
                  <td className="px-6 py-4">{strategy.name}</td>
                  <td className="px-6 py-4">
                    {strategy.performance < 0 ? "-" : "+"} {displayPurposes}%
                  </td>
                  <td>
                    {" "}
                    <div
                      onClick={() => strategyToEdit(strategy)}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {" "}
                      Copy
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {
  setStrategyToEdit: strategyActions.setStrategyToEdit,
})(TemplatesPage);
