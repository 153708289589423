import { adminConstants } from "../_redux/admin/admin.actions";
import { brokersConstants } from "../_redux/brokers/brokers.actions";
import { paymentsConstants } from "../_redux/payments/payments.actions";
import { strategyConstants } from "../_redux/strategies/strategy.actions";
import { templatesConstants } from "../_redux/templates/templates.actions";
import { userConstants } from "../_redux/users/user.constants";

const initialState = {
  admin: {},
  brokers: {},
  payments: {},
  strategies: {
    isFetchingStrategies: false,
    isChangingStrategies: false,
    errorChangingStrategies: "",
  },
  templates: {},
  users: {},
};

export const apiStatusReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    // strategies
    case strategyConstants.STRATEGY_CREATE_START:
    case strategyConstants.STRATEGY_UPDATE_START:
      return {
        ...state,
        strategies: {
          ...state.strategies,
          isChangingStrategies: true,
        },
      };

    case strategyConstants.STRATEGY_CREATE_SUCCESS:
    case strategyConstants.STRATEGY_CREATE_FAIL:
    case strategyConstants.STRATEGY_UPDATE_SUCCESS:
    case strategyConstants.STRATEGY_UPDATE_FAIL:
      return {
        ...state,
        strategies: {
          ...state.strategies,
          isChangingStrategies: false,
        },
      };

    case userConstants.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
