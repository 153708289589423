import * as Blockly from "blockly/core";

// ---------- //

Blockly.Blocks["priceOf_dropdown"] = {
  init: function () {
    this.jsonInit({
      type: "priceOf_dropdown",
      message0: "Price of %1",
      args0: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      extensions: ["assets_list_extension"],
      output: true,
    });
  },
};

Blockly.JavaScript["priceOf_dropdown"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `INDPRICE`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["LowerBoilingerBand20"] = {
  init: function () {
    this.jsonInit({
      type: "LowerBoilingerBand20",
      message0: "Lower Boilinger Band (20 Day Period) of%1",
      args0: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["LowerBoilingerBand20"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `INDLOWERBOLLINGERBANDSx${asset}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["UpperBoilingerBand20"] = {
  init: function () {
    this.jsonInit({
      type: "UpperBoilingerBand20",
      message0: "Upper Boilinger Band (20 Day Period) of %1",
      args0: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["UpperBoilingerBand20"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `INDUPPERBOLLINGERBANDSx${asset}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["ExponentialMovingAvg89Day"] = {
  init: function () {
    this.jsonInit({
      type: "ExponentialMovingAvg89Day",
      message0: "Price of %2 %1 Exponential Moving Avg (89)",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDEMA89CROSSOVER"],
            ["crossunder", "INDEMA89CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["ExponentialMovingAvg89Day"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["ExponentialMovingAvg55Day"] = {
  init: function () {
    this.jsonInit({
      type: "ExponentialMovingAvg55Day",
      message0: "Price of %2 %1 Exponential Moving Avg (55)",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDEMA55CROSSOVER"],
            ["crossunder", "INDEMA55CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["ExponentialMovingAvg55Day"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["ExponentialMovingAvg9Day"] = {
  init: function () {
    this.jsonInit({
      type: "ExponentialMovingAvg9Day",
      message0: "Price of %2 %1 Exponential Moving Avg (9)",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDEMA9CROSSOVER"],
            ["crossunder", "INDEMA9CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["ExponentialMovingAvg9Day"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["ExponentialMovingAvg20Day"] = {
  init: function () {
    this.jsonInit({
      type: "ExponentialMovingAvg20Day",
      message0: "Price of %2 %1 Exponential Moving Avg (20)",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDEMA20CROSSOVER"],
            ["crossunder", "INDEMA20CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["ExponentialMovingAvg20Day"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["PricePercentChange"] = {
  init: function () {
    this.jsonInit({
      type: "PricePercentChange",
      message0: "Price Percent Change of %1",
      args0: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["PricePercentChange"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `PRICEPERCENTCHANGE`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["MACD9Day"] = {
  init: function () {
    this.jsonInit({
      type: "MACD9Day",
      message0: "MACD 9 Day Signal of %1",
      args0: [{ type: "input_dummy", name: "ASSETSLIST" }],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["MACD9Day"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `INDMACD`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["MACD1226"] = {
  init: function () {
    this.jsonInit({
      type: "MACD1226",
      message0: "MACD (9,12,26) histogram of %2 %1",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDMACDCROSSOVER"],
            ["crossunder", "INDMACDCROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["MACD1226"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["VWAP_dropdown"] = {
  init: function () {
    this.jsonInit({
      type: "VWAP_dropdown",
      message0: "VWAP of %1",
      args0: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["VWAP_dropdown"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `INDVWAP`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["RSI_dropdown"] = {
  init: function () {
    this.jsonInit({
      type: "RSI_dropdown",
      message0: "RSI (14) of %1",
      args0: [{ type: "input_dummy", name: "ASSETSLIST" }],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["RSI_dropdown"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  var code = `await calculateIndicatorValues({indicator:"INDRSI14", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["SMA50_dropdown"] = {
  init: function () {
    this.jsonInit({
      type: "SMA50_dropdown",
      message0: "Price of %2 %1 Simple Moving Avg (50)",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDSMA50CROSSOVER"],
            ["crossunder", "INDSMA50CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["SMA50_dropdown"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["SMA200_dropdown"] = {
  init: function () {
    this.jsonInit({
      type: "SMA200_dropdown",
      message0: "Price of %2 %1 Simple Moving Avg (200) ",
      args0: [
        {
          type: "field_dropdown",
          name: "strat",
          options: [
            ["crossover", "INDSMA200CROSSOVER"],
            ["crossunder", "INDSMA200CROSSUNDER"],
          ],
        },
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      output: true,
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["SMA200_dropdown"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("ASSET");
  let indicator = block.getFieldValue("strat");
  var code = `await calculateIndicatorValues({indicator:"${indicator}", asset:"${
    asset || ""
  }"})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //
