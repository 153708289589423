import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Button, Col, Row } from "react-bootstrap";
import { FaCog } from "react-icons/fa";

import Logo from "./Logo";
import Logger from "../_utils/logger";
import { useAuth } from "../_utils/auth_context";
import { formatFireError } from "../firebase";

import { userLogout } from "../_redux/users/user.actions";

const Header = (props) => {
  const { user } = props;
  /** user authentification  */
  const { currentUser, loading, logout } = useAuth();
  /** mbile menu activation */
  const [menuActive, setMenuActive] = useState(false);

  /** router hook for navigation */
  const navigate = useNavigate();
  const location = useLocation();

  //console.log(location);
  // console.log(user);

  //location.pathname
  /** Dom references  */
  const nav = useRef(null);
  const hamburger = useRef(null);

  /**
   * close the menu
   *
   * @param {*} event
   */
  function handleMenu(event) {
    if (menuActive) closeMenu();
    else openMenu();
  }

  /**
   * open the mobile menu
   *
   * @param {*} event
   */
  function openMenu(event) {
    document.body.classList.add("off-nav-is-active");
    if (nav.current != null) {
      nav.current.style.maxHeight = nav.current.scrollHeight + "px";
      setMenuActive(true);
    }
  }

  function closeMenu() {
    document.body.classList.remove("off-nav-is-active");
    if (nav.current != null) {
      nav.current.style.maxHeight = null;
      setMenuActive(false);
    }
  }
  async function onLogout() {
    Logger.info("onLogout", "[HEADER]");
    try {
      // these logouts will need to be simplified
      await logout();
      await props.userLogout();
    } catch (error) {
      NotificationManager.error(formatFireError(error.code));
    }
  }

  function activateSettings() {
    const element = document.querySelector(".header-nav__menu");
    element.classList.toggle("is-active");
  }

  return (
    <header>
      <div className="container">
        <div
          style={
            props.isShowingLogo
              ? { justifyContent: "space-between" }
              : { justifyContent: "flex-end" }
          }
          className={classNames("site-header-inner mr-3")}
        >
          {props.isShowingLogo && <Logo />}
          {/* Authenticated user header */}
          {user && !loading && (
            <>
              <Row>
                <button ref={hamburger} className="header-nav-toggle">
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={nav}
                  className={classNames("header-nav", "is-active")}
                >
                  <div className="header-nav-inner position-relative">
                    <ul className={classNames("list-reset text-xxs")}>
                      <Col>
                        <li
                          className="text-gray-500"
                          style={{ marginRight: "10px" }}
                        >
                          {user.firstname}
                        </li>
                      </Col>
                      <Col>
                      <li>
                        <button
                          style={{ marginTop: "5px" }}
                          href="#"
                          className="pl-5 text-gray-500"
                          onClick={activateSettings}
                        >
                          <FaCog />
                        </button>
                        <div className="header-nav__menu position-absolute z-10 right-100 top-100 mt-1 bg-surface-light rounded-md" style ={{right: "1vw"}}>
                          <ul className="header-nav__menu__custom position-relative list-none divide-y divide-gray-700" style={{backgroundColor: "#0E0E13"}}>
                            {user.admin && (
                              <li className="mb-0 px-4 py-2">
                                <Link
                                  to="/admin"
                                  className="text-xs text-capitalize p-0 text-gray-500 hover:text-gray-700"
                                  onClick={closeMenu}
                                >
                                  Switch to Admin Panel
                                </Link>
                              </li>
                            )}

                            <li className="mb-0 px-4 py-2">
                              <Link
                                to="/settings"
                                onClick={closeMenu}
                                className="text-xs text-capitalize p-0 text-gray-500 hover:text-gray-700"
                              >
                                Settings
                              </Link>
                            </li>
                            {/* <li className="mb-0 px-4 py-2">
                              <Link
                                to="/connect"
                                onClick={closeMenu}
                                className="text-xs text-capitalize p-0 text-gray-500 hover:text-gray-700"
                              >
                                Connect
                              </Link>
                            </li> */}
                            <li className="mb-0 px-4 py-2">
                              <Link
                                to="/billing"
                                onClick={closeMenu}
                                className="text-xs text-capitalize p-0 text-gray-500 hover:text-gray-700"
                              >
                                Billing
                              </Link>
                            </li>
                            <li className="mb-0 px-4 py-2">
                              <Link
                                onClick={async () => await onLogout()}
                                className="text-xs text-capitalize p-0 text-gray-500 hover:text-gray-700"
                              >
                                Logout
                              </Link>
                            </li>
                            
                          </ul>
                        </div>
                      </li>
                      </Col>
                    </ul>
                  </div>
                </nav>
              </Row>
            </>
          )}

          {/* Public header */}
          {!currentUser && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={handleMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>

              <nav ref={nav} className={classNames("header-nav", "is-active")}>
                <div className="header-nav-inner">
                  <ul className={classNames("list-reset text-xxs d-flex mr-5")}>
                    <li>
                      <Link
                        to="/login/"
                        onClick={closeMenu}
                        style={{ color: "white" }}
                      >
                        Login
                      </Link>
                    </li>
                  </ul>
                  <ul className="list-reset header-nav-right">
                    <li>
                      <Link
                        to="/signup/"
                        className="button button-wide-mobile button-sm"
                        style={{ color: "black" }}
                        onClick={closeMenu}
                      >
                        Sign up
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
  strategy: storeState.strategy,
  broker: storeState.brokers,
  apiStatus: storeState.apiStatus,
});

export default connect(mapStateToProps, {
  userLogout,
})(Header);
