import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import BrokerCard from "./broker-card.js";

const Home = (props) => {
  const { user } = props;
  const [connectedAccounts, setConnectedAccounts] = useState([])


  useEffect(() => {
    if(!user.availWeb){
      window.location.href="/home2";
    }
    setConnectedAccounts(user.connectedAccounts ? user.connectedAccounts : [])
  }, [user])


  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          color: "white",
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "40px",
        }}
      >
        {`👋 Welcome Back to Daedalus, ${user.firstname}`}
      </div>
       
      <Row>
        {connectedAccounts.map((account, index) => (
          <div className="col-auto" key={index} align="center">
            <BrokerCard account={account} />
          </div>
        ))}

        <div className="col-auto">
          <Container
            opacity="100%"
            style={{
              display: "flex",
              width: "15rem",
              height: "8rem",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              outline: "1px dashed #4285F4",
            }}
          >
            <Link to="/connect">
              <span
                style={{
                  fontSize: "16px",
                  color: "#4285F4",
                  justifySelf: "center",
                }}
              >
                + Connect Additional Accounts
              </span>
            </Link>
          </Container>
        </div>
      </Row>
    </div>
  );


}

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});



export default connect(mapStateToProps, {})(Home);
