import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { NotificationManager } from "react-notifications";
import { addCreditCardSuccess } from "../_redux/payments/payments.actions.js";

const AddCreditCardDialog = ({ open, setOpen, addCreditCardSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url:
          window.location.hostname === "localhost"
            ? "http://localhost:3000/billing"
            : "https://app.daedalusfi.com/billing",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    const mountEffect = async () => {
      if (!stripe) {
        return;
      }
      const clientSecret = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret"
      );

      if (clientSecret) {
        setLoading(true);
        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);

        let message = "";

        if (setupIntent?.status === "succeeded") {
          message = "Congratulations, Credit Card Successfully Added!";
          await addCreditCardSuccess();
        } else if (setupIntent?.status === "processing") {
          message = "Processing...";
        } else if (setupIntent?.status === "requires_payment_method") {
          message = "Require Payemnt Method";
        }
        NotificationManager.success(message);

        navigate("/billing", { replace: true });

        handleClose();
      }
    };

    mountEffect();
  }, [stripe]);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <>
        <DialogTitle
          id="responsive-dialog-title"
          color={"black"}
          textAlign="center"
        >
          {loading ? "Saving Info" : "Add Credit Card Info"}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            "Please Wait..."
          ) : (
            <form onSubmit={handleSubmit}>
              <PaymentElement />
              <button disabled={!stripe}>Submit</button>
              {errorMessage && <div>{errorMessage}</div>}
            </form>
          )}
        </DialogContent>
      </>
    </Dialog>
  );
};
const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});
export default connect(mapStateToProps, {
  addCreditCardSuccess,
})(AddCreditCardDialog);
