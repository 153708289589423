import { NotificationManager } from "react-notifications";
import api from "../../_api/api";

import { getAllCoinbaseAssets } from "../../_api/coinbase.service";

// import { RestClient } from "ftx-api";

// const FTXclient = new RestClient(
//   API_KEY,
//   PRIVATE_KEY

//   // restClientOptions,
//   // requestLibraryOptions
// );

export const brokersConstants = {
  FETCH_ASSETS_ALPACA_START: "FETCH_ASSETS_ALPACA_START",
  FETCH_ASSETS_ALPACA_SUCCESS: "FETCH_ASSETS_ALPACA_SUCCESS",
  FETCH_ASSETS_ALPACA_FAIL: "FETCH_ASSETS_ALPACA_FAIL",
  FETCH_ASSETS_COINBASE_START: "FETCH_ASSETS_COINBASE_START",
  getAllCoinbaseAssets: "FETCH_ASSETS_COINBASE_SUCCESS",
  FETCH_ASSETS_COINBASE_FAIL: "FETCH_ASSETS_COINBASE_FAIL",
  FETCH_ASSETS_FTX_START: "FETCH_ASSETS_FTX_START",
  FETCH_ASSETS_FTX_SUCCESS: "FETCH_ASSETS_FTX_SUCCESS",
  FETCH_ASSETS_FTX_FAIL: "FETCH_ASSETS_FTX_FAIL",

  FETCH_CRYPTO_LISTIGS_START: "FETCH_CRYPTO_LISTIGS_START",
  FETCH_CRYPTO_LISTIGS_SUCCESS: "FETCH_CRYPTO_LISTIGS_SUCCESS",
  FETCH_CRYPTO_LISTIGS_FAIL: "FETCH_CRYPTO_LISTIGS_FAIL",
  FETCH_STOCK_LISTIGS_START: "FETCH_STOCK_LISTIGS_START",
  FETCH_STOCK_LISTIGS_SUCCESS: "FETCH_STOCK_LISTIGS_SUCCESS",
  FETCH_STOCK_LISTIGS_FAIL: "FETCH_STOCK_LISTIGS_FAIL",

  FETCH_STOCK_PRICE_START: "FETCH_STOCK_PRICE_START",
  FETCH_STOCK_PRICE_SUCCESS: "FETCH_STOCK_PRICE_SUCCESS",
  FETCH_STOCK_PRICE_FAIL: "FETCH_STOCK_PRICE_FAIL",
};

export const fetchCryptoListings = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_CRYPTO_LISTIGS_START,
      });

      const cryptoListings  = await api.brokers.getCryptoListings();

      dispatch({
        type: brokersConstants.FETCH_CRYPTO_LISTIGS_SUCCESS,
        payload: { cryptoListings: cryptoListings },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_CRYPTO_LISTIGS_FAIL,
      });
      return false;
    }
  };
};

export const fetchStockListings = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_STOCK_LISTIGS_START,
      });
      const stockListings = await api.brokers.getStockListings();
      dispatch({
        type: brokersConstants.FETCH_STOCK_LISTIGS_SUCCESS,
        payload: { stockListings: stockListings },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_STOCK_LISTIGS_FAIL,
      });
      return false;
    }
  };
};

export const fetchStockPrice = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_STOCK_PRICE_START,
      });
      const stockPrice = await api.brokers.getStockPrice(payload);
      dispatch({
        type: brokersConstants.FETCH_STOCK_PRICE_SUCCESS,
        payload: { stockPrice: stockPrice },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_STOCK_PRICE_FAIL,
      });
      return false;
    }
  };
};

export const fetchAssetsAlpaca = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_ALPACA_START,
      });

      const { allAssetsAlpaca, stockAssetsAlpaca, cryptoAssetsAlpaca } =
        await api.brokers.getAlpacaAssets();

      dispatch({
        type: brokersConstants.FETCH_ASSETS_ALPACA_SUCCESS,
        payload: { allAssetsAlpaca, stockAssetsAlpaca, cryptoAssetsAlpaca },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_ALPACA_FAIL,
      });
      return false;
    }
  };
};

export const fetchAssetsFTX = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_FTX_START,
      });

      const { allAssetsFTX } = await api.brokers.getFTXAssets();

      dispatch({
        type: brokersConstants.FETCH_ASSETS_FTX_SUCCESS,
        payload: { allAssetsFTX: allAssetsFTX || [] },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_FTX_FAIL,
      });
      return false;
    }
  };
};

export const fetchAssetsCoinbase = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_COINBASE_START,
      });

      const coinbaseAllAssets = await api.brokers.getAllCoinbaseAssets();

      dispatch({
        type: brokersConstants.FETCH_ASSETS_COINBASE_SUCCESS,
        payload: { coinbaseAllAssets: coinbaseAllAssets || [] },
      });
      return true;
    } catch (e) {
      dispatch({
        type: brokersConstants.FETCH_ASSETS_COINBASE_FAIL,
      });
      return false;
    }
  };
};
