import { templatesConstants } from "./templates.actions";

const initialState = {
  isInitialized: false,
};

export const templatesReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case templatesConstants.TEMPLATESTRATEGIES_FETCH_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};
