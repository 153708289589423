import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import BuySellModal from "./BuySellModal";
import { callService } from "../_api/api";
import { Button } from "react-bootstrap";
import AddPortfiloModal from "./AddPortfiloModal";
import EditPortfolioModal from "./EditPortfolioModal";
import { currenciesList } from "../_assets/currencyList";
import {
  deletePortfolio,
  fetchPortfolios,
  fetchPortfolioTransactions,
} from "../_redux/portfolio/portfolio.actions";
import { deleteTransaction } from "../_redux/transaction/transaction.actions";
import { FaPen, FaPlus } from "react-icons/fa";

import {
  fetchCryptoListings,
  fetchStockListings,
} from "../_redux/brokers/brokers.actions";
import { useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import PorofolioCard from "./portfolioCard";
import Asssets from "./assets";
import axios from "axios";

let isFetched = false;

const MyPortfolio = (props) => {
  const [accountBalance, setAccountBalance] = useState(0);
  const [tradingType, setTradingType] = useState(null);
  const [showTradingModal, setTradingModal] = useState(false);
  const [portfolioModal, setPortfolioModal] = useState(false);
  const [editPortfolioModal, setEditPortfolioModal] = useState(false);
  const [portfolioDoc, setPortfolioDoc] = useState({});
  const [positions, setPositions] = useState([]);
  const [positionsAlpaca, setPositionsAlpaca] = useState([]);

  const dispatch = useDispatch();
  const {
    allPortfolios,
    portfolioTransactions,
    cryptoListings,
    stockListings,
  } = props;
  const user = useSelector((state) => state.user.user);
  const [account, setAccount] = useState(
    useSelector((state) => {
      return state.account;
    })
  );

  const [transactions, setTransactions] = useState([]);

  const handleDeleteTransaction = async (id) => {
    const resData = dispatch(deleteTransaction(portfolioDoc.id, id));
  };

  useEffect(() => {
    if (!user || !user.uid) return;
    const initializeReducers = async () => {
      props.fetchPortfolios(user);
      props.fetchCryptoListings();
      props.fetchStockListings();
    };
    initializeReducers();
    return () => {};
  }, [user]);

  useEffect(() => {
    if (allPortfolios && allPortfolios.length) {
      setPortfolioDoc(allPortfolios[0]);
    }
  }, [allPortfolios]);

  const updatePortfolioDoc = (name) => {
    portfolioDoc.name = name;
    setPortfolioDoc({ ...portfolioDoc });
  };

  useEffect(() => {
    if (portfolioDoc.id) {
      props.fetchPortfolioTransactions(portfolioDoc.id);
    }
  }, [portfolioDoc]);

  // useEffect(() => {
  //   (async () => {
  //     const newAccount = user.connectedAccounts.filter(
  //       (acct) => acct.name === account.name
  //     );
  //     setAccountBalance(parseFloat(newAccount[0]?.balance));
  //     const newPositions = [];
  //     newAccount[0]?.positions.forEach((newPosition) => {
  //       const filteredResult = positions.filter(
  //         (oldPosition) =>
  //           newPosition.symbol === oldPosition.symbol &&
  //           newPosition.quantity === oldPosition.quantity
  //       );
  //       if (filteredResult.length > 0) {
  //         newPositions.push(filteredResult[0]);
  //       } else {
  //         newPositions.push(newPosition);
  //       }
  //     });
  //     isFetched = false;
  //     setPositions(newPositions);
  //     setAccount(account);
  //   })();
  // }, [user]);

  useEffect(() => {
    (async () => {
      if (positions.length > 0 && !isFetched) {
        isFetched = true;
        await calculatePositionValue();
      }
    })();
  }, [positions]);

  useEffect(() => {
    getAlpacaPositions(account);
  }, account);

  const getAlpacaPositions = async (account) => {
    try {
      let alpacaPositions = await callService("getAlpacaPositions", account);
      if (alpacaPositions.data.success) {
        setPositionsAlpaca(alpacaPositions.data.account);
      }
    } catch (e) {
      return e;
    }
  };

  const calculatePositionValue = async () => {
    const newPositions = [];
    for (let position of positions) {
      let newPosition = { ...position };
      let symbol = position.symbol;
      if (!newPosition.latestPrice) {
        if (account.type === "crypto") symbol = symbol.replace("USD", "");
        const response = await callService("getSymbolQuote", {
          accountType: account.type,
          symbol,
          accountName: account.name,
        });
        newPosition = { ...newPosition, ...response.data };
      }
      newPositions.push(newPosition);
    }
    setPositions(newPositions);
  };
  const openTradingModal = (type) => {
    setTradingType(type);
    setTradingModal(true);
  };
  const onSaveTrading = () => {
    setTradingModal(false);
    setTradingType(null);
  };
  const onCloseTradingModal = () => {
    setTradingModal(false);
    setTradingType(null);
  };

  const openEditPortfolioModal = (doc) => {
    setPortfolioDoc(doc);
    setEditPortfolioModal(true);
  };

  const formatGainText = (gl) => {
    if (gl === undefined) return "Calculating...";
    else if (gl === null) return "0%";
    return gl.toFixed(4) + " %";
  };

  const [portfolioCurrentValue, setPortfolioCurrentValue] = useState(0);
  const [portfolioValue, setPortfolioValue] = useState(0);

  const getPortfolioValue = async () => {
    let currentValue = 0;
    let value = 0;
    for (let i of portfolioTransactions) {
      const url = `https://min-api.cryptocompare.com/data/price?fsym=${i.symbol}&tsyms=${i.currency}&api_key=5a76a5adc984a5eaf9eadb1a2fbfbbb273141d318043c8ffb987ffd8e3932349`;
      const response = await fetch(url);
      const result = await response.json();
      const currentPrice =
        parseFloat(result[i.currency]) * parseFloat(i.amount);
      const price = parseFloat(i.price) * parseFloat(i.amount);

      currentValue += currentPrice;
      value += price;
    }
    setPortfolioCurrentValue(currentValue);
    setPortfolioValue(value);
  };

  useEffect(() => {
    getPortfolioValue();
  }, [portfolioTransactions]);

  return (
    <>
      <section className="flex" style={{ minHeight: "80vh" }}>
        <div className="flex-1 p-8">
          <div class="d-flex justify-content-around">
            <PorofolioCard
              portfolioDoc={portfolioDoc}
              portfolioCurrentValue={portfolioCurrentValue}
              portfolioValue={portfolioValue}
              portfolioTransactions={portfolioTransactions}
            />
            <div>
              <div className="d-flex flex-row justify-content-end"></div>
              <div className="mb-3">
                Current account balance : {user.connectedAccounts[0].balance}
              </div>

              <Button
                variant="success"
                size="sm"
                className="mr-4 ml-4"
                onClick={() => setPortfolioModal(true)}
                disabled={!portfolioDoc}
              >
                <FaPlus />
              </Button>

              <Button
                variant="success"
                size="sm"
                className="mr-4 ml-4"
                onClick={() => setEditPortfolioModal(true)}
                disabled={!portfolioDoc}
              >
                <FaPen />
              </Button>

              <span className=" float-right">
                <DropdownButton
                  as={ButtonGroup}
                  id={`dropdown-button-drop`}
                  size="sm"
                  variant="secondary"
                  title={portfolioDoc.name}
                >
                  {allPortfolios.map((portfolio, index) => (
                    <Dropdown.Item
                      eventKey="4"
                      key={index}
                      onClick={() => setPortfolioDoc(portfolio)}
                    >
                      {portfolio.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </span>
            </div>
          </div>

          <hr />

          {/* <Button
            variant="danger"
            size="sm"
            className="ml-4 float-right"
            onClick={() => setPortfolioModal(true)}
          >
            + Add Portfolio
          </Button> */}

          {/* <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3">Symbol</th>
                <th className="px-6 py-3">Quantity</th>
                <th className="px-6 py-3">Current Value</th>
                <th className="px-6 py-3">Last Price</th>
                <th className="px-6 py-3">Total Gain/Loss</th>
                <th className="px-6 py-3">Today's Gain/Loss</th>
              </tr>
            </thead>
            <tbody>
              {account.name == "Alpaca"
                ? positionsAlpaca.map((position, index) => {
                    return (
                      <tr
                        key={index}
                        className="cursor-pointer border-b transition duration-300 ease-in-out hover:bg-gray-300 hover:text-black"
                      >
                        <td className="px-6 py-4">{position.symbol}</td>
                        <td className="px-6 py-4">{position.qty}</td>
                        <td className="px-6 py-4">{position.current_price}</td>
                        <td className="px-6 py-4">{position.lastday_price}</td>
                        <td className="px-6 py-4">
                          {parseFloat(position.market_value) -
                            parseFloat(position.cost_basis)}
                        </td>
                        <td className="px-6 py-4">
                          {position.unrealized_intraday_pl}
                        </td>
                      </tr>
                    );
                  })
                : positions.map((position, index) => {
                    return (
                      <tr
                        key={index}
                        className="cursor-pointer border-b transition duration-300 ease-in-out hover:bg-gray-300 hover:text-black"
                      >
                        <td className="px-6 py-4">{position.symbol}</td>
                        <td className="px-6 py-4">{position.quantity}</td>
                        <td className="px-6 py-4">
                          {position.latestPrice
                            ? "$" +
                              (
                                position.quantity * position.latestPrice
                              ).toFixed(2)
                            : "Calculating..."}
                        </td>
                        <td className="px-6 py-4">
                          {position.latestPrice
                            ? "$" + position.latestPrice
                            : "Calculating..."}
                        </td>
                        <td className="px-6 py-4">
                          {formatGainText(position.totalGL)}
                        </td>
                        <td className="px-6 py-4">
                          {formatGainText(position.todayGL)}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table> */}

          <Asssets
            portfolioTransactions={portfolioTransactions}
            openEditPortfolioModal={openEditPortfolioModal}
            handleDeleteTransaction={handleDeleteTransaction}
            openTradingModal={openTradingModal}
          />
        </div>
      </section>
      <BuySellModal
        type={tradingType}
        isOpen={showTradingModal}
        onSave={() => onSaveTrading()}
        onClose={() => onCloseTradingModal()}
        account={account}
        portfolioDoc={portfolioDoc}
        listings={cryptoListings}
        currenciesList={currenciesList}
        tradingType={tradingType}
        setTradingType={setTradingType}
      />

      <AddPortfiloModal
        isOpen={portfolioModal}
        onSave={() => setPortfolioModal(false)}
        onClose={() => setPortfolioModal(false)}
        account={account}
        user={user}
        portfolioDoc={portfolioDoc}
      />

      {portfolioDoc ? (
        <EditPortfolioModal
          isOpen={editPortfolioModal}
          onSave={() => setEditPortfolioModal(false)}
          onClose={() => setEditPortfolioModal(false)}
          account={account}
          user={user}
          portfolioDoc={portfolioDoc}
          updatePortfolioDoc={updatePortfolioDoc}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (storeState) => ({
  allPortfolios: storeState.portfolios.allPortfolios,
  portfolioTransactions: storeState.portfolios.portfolioTransactions,
  cryptoListings: storeState.brokers.cryptoListings,
  stockListings: storeState.brokers.stockListings,
});

const actionCreators = {
  fetchPortfolios: fetchPortfolios,
  fetchPortfolioTransactions: fetchPortfolioTransactions,
  fetchCryptoListings: fetchCryptoListings,
  fetchStockListings: fetchStockListings,
};

export default connect(mapStateToProps, actionCreators)(MyPortfolio);
