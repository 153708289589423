import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "firebase/compat/firestore";

import { FaChartArea } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { Row, Col } from "react-bootstrap";
import StrategiesPage from "../strategies/strategies-page";
import StockChartGroup from "./charts/stock-chart-group";
import AccountPortfolio from "./account-portfolio";

import StrategyCard from "./strategy-card";

import { setStrategyToEdit } from "../_redux/strategies/strategy.actions";

const BrokerShowStatusPage = (props) => {
  const { user, strategies } = props;

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
     if(!user.availWeb){
    window.location.href="/home2";
  }
  }, [user]);
  return (
    <section className="Dashboard flex bg-surface" id="dashboard">
      <h6 className="text-lg" align="center">
        {params.brokerName === "alpaca"
          ? "Alpaca Status"
          : params.brokerName === "ftx"
          ? "FTX Status"
          : params.brokerName === "daedalus-stocks"
          ? "Daedalus Stocks Trading Status"
          : "ERROR"}
      </h6>
      <div className="flex flex-1 p-8">
        <div className="panel bg-surface flex-1">
          <div className="panel__container flex mt-5">
            <div className="panel__item d-block flex-1 mr-4">
              <div className="flex items-center">
                <FaChartArea className="text-primary mr-2" />
                <h3 className="text-lg">Gainers</h3>
              </div>
              <StockChartGroup />
            </div>
            <div className="panel__item d-block flex-1">
              <div className="flex items-center">
                <FaChartArea className="text-primary mr-2" />
                {/* <h3 className="text-lg">{account.name} Portfolio</h3> */}
              </div>
              {/* <AccountPortfolio account_name={account.name} /> */}
            </div>
          </div>
          <div className="panel__low">
            <div className="panel__bottom-title"></div>
            <div>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <div
                    style={{
                      backgroundColor: "black",
                      height: "566px",
                      paddingTop: "20px",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "30px",
                        color: "white",
                        marginTop: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      My Strategies
                      <AddCircleIcon
                        onClick={() => {
                          props.setStrategyToEdit(null);
                          navigate("/strategy-builder/" + params.brokerName);
                        }}
                        style={{
                          marginLeft: "10px",
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <Row>
                      {strategies
                        .filter((strategy) => {
                          switch (params.brokerName) {
                            case "daedalus-paper":
                              if (strategy.broker === "daedalus-paper") {
                                return true;
                              }
                              break;
                            case "alpaca":
                              if (
                                strategy.broker === "alpaca" ||
                                strategy.exchange === "Alpaca"
                              ) {
                                return true;
                              }
                              break;
                            case "ftx":
                              if (strategy.broker === "ftx") {
                                return true;
                              }
                              break;
                            default:
                              return false;
                          }
                        })
                        .map((strategy, index) => (
                          <Col xs="4" className="mb-2" key={index}>
                            <StrategyCard strategy={strategy} />
                          </Col>
                        ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
  strategies: storeState.strategy.strategies,
  broker: storeState.brokers,
  apiStatus: storeState.apiStatus,
});

export default connect(mapStateToProps, {
  setStrategyToEdit,
})(BrokerShowStatusPage);
