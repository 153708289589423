import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUsersForAdmin } from "../_redux/admin/admin.actions";
import { userActions } from "../_redux/users/user.actions";


const AdminPage = (props) => {
  const { allUsers } = props;

  // console.log(allUsers);

  useEffect(() => {
    const initializeReducers = async () => {
      // await is not needed here
      props.fetchUsersForAdmin();
    };
    initializeReducers();

    return () => {};
  }, []);

  const updateUserRole = async (uid, checked) => {
    await props.updateUser({uid: uid, availWeb: checked});
    await props.fetchUsersForAdmin();
  }

  return (
    <div className="devPanel">
      <div className="topbar">
        <h1>Admin Panel</h1>
      </div>
      <div className="devPanel__content">
        {allUsers && (
          <table>
            <tbody>
              <tr>
                <th>Id</th>
                <th>isAdmin</th>
                <th>Avail Web</th>
                <th>Username/Email</th>
                <th>Date Joined</th>

                <th>Subscription Tier</th>
              </tr>
              {allUsers.map((user, index) => (
                <tr key={index}>
                  <td>{user.uid}</td>
                  <td>{user.admin ? "X" : ""}</td>
                  <td>
                    <input type="checkbox" onChange={(e) => updateUserRole(user.uid, e.target.checked)} checked={user.availWeb} id={`switch ${index}`} class="toggle-switch" />
                    <label for={`switch ${index}`} class="toggle-label">
                      Toggle
                    </label>
                  </td>
                  <td>{user.email}</td>
                  <td>{new Date(user.datejoined).toString()}</td>
                  <td>{user.subscriptionTier}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState) => ({
  allUsers: storeState.admin.allUsers,
});

const actionCreators = {
  updateUser: userActions.updateUserV2,
  fetchUsersForAdmin: fetchUsersForAdmin
};

export default connect(mapStateToProps, actionCreators)(AdminPage);


