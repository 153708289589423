import { paymentsConstants } from "./payments.actions";

const initialState = {
  isInitialized: false,
  isCreditCardAdded: false,
};

export const paymentsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case paymentsConstants.CREDIR_CARD_SUCCESS:
      return {
        ...state,
        isCreditCardAdded: true,
      };

    case paymentsConstants.CREDIR_CARD_FAIL:
      return {
        ...state,
        isCreditCardAdded: false,
      };
    default:
      return state;
  }
};
