import { userConstants } from "./user.constants";

let userLocalStorage = JSON.parse(localStorage.getItem("user"));

// user ? { loggedIn: true, user: user } : { user: null };
const initialState = {
  loggedIn: userLocalStorage ? true : false,
  user: userLocalStorage || null,
};

export function userReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case userConstants.USER_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };

    case userConstants.USER_LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
