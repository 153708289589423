import { NotificationManager } from "react-notifications";
import api from "../../_api/api";

export const adminConstants = {
  ADMIN_USERS_FETCH_START: "ADMIN_USERS_FETCH_START",
  ADMIN_USERS_FETCH_SUCCESS: "ADMIN_USERS_FETCH_SUCCESS",
  ADMIN_USERS_FETCH_FAIL: "ADMIN_USERS_FETCH_FAIL",
};

export const fetchUsersForAdmin = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: adminConstants.ADMIN_USERS_FETCH_START,
      });

      const allUsers = await api.admin.fetchUsersForAdmin();

      dispatch({
        type: adminConstants.ADMIN_USERS_FETCH_SUCCESS,
        payload: { allUsers },
      });
      return true;
    } catch (e) {
      dispatch({
        type: adminConstants.ADMIN_USERS_FETCH_FAIL,
      });
      return false;
    }
  };
};
