import LogoAlpaca from "./_assets/img/logo-alpaca.png";
import LogoBinance from "./_assets/img/logo-binance.png";
import LogoCoinbase from "./_assets/img/logo-coinbase.png";
import LogoFTX from "./_assets/img/logo-ftx.png";
import LogoRobinhood from "./_assets/img/logo-robinhood.png";

export const config = {
  // Brokers
  alpaca: {
    name: "Alpaca",
    logo: LogoAlpaca,
    // client_id: "c44e66c3f86085894899596d1e049e14", //old
    client_id: "00b01ddc5e277a97487a473dc91ccb0b",
    oauth_url: "https://app.alpaca.markets/oauth/authorize?",
    state: "alpaca_state_daedalus",
    // https://alpaca.markets/docs/api-references/oauth-api/#allowed-scopes
    scope: "account:write trading data",
    isOAuthEnabled: true,
  },
  binance: {
    name: "Binance",
    logo: LogoBinance,
    client_id:
      "KlhyPQ4mrn51v2obn6Szb19fcfPct2wIwoG26dLDHSZJWRCMc9c2TSJgyFucmdqj",
    client_secret:
      "qZdoqRruNd5hZiBRt5ngb2cihxSO9vaGaObjW6MXmGOI2LmKncVwMOg5MRZwJxva",
    oauth_url: "https://accounts.binance.com/en/oauth/authorize?",
    state: "377f36a4557ab5935b3611",
    scope: "user:balance",
    isCryptoOnly: true,
  },
  coinbase: {
    name: "Coinbase",
    logo: LogoCoinbase,
    client_id:
      "58054d15376a178e2129198e65f2713f9049e8f9c7c4dff472abec66c3825ced",
    oauth_url: "https://www.coinbase.com/oauth/authorize?",
    state: "coinbase_state_daedalus",
    scope: ["wallet:accounts:read", "wallet:user:read", "wallet:user:email"],
    isCryptoOnly: true,
  },
  ftx: {
    name: "FTX",
    logo: LogoFTX,
    client_id: "",
    oauth_url: "",
    state: "",
    scope: "",
    isOAuthEnabled: true,
  },
  robinhood: {
    name: "Robinhood",
    logo: LogoRobinhood,
  },
  // Market data
  coinmarketcap: {
    base_url: "https://pro-api.coinmarketcap.com",
    // key: "b54bcf4d-1bca-4e8e-9a24-22ff2c3d462c",
    key: "941b5b38-6f6c-4f57-bfe0-673aa3045a15",
  },
  iex: {
    base_url: "https://cloud.iexapis.com",
    publish_key: "pk_fde490c1a26e43798c9463412f26ada3",
    secret_key: "Tsk_d2d73826817b46fd9b312af3845b4c0e",
  },
  // External services
  stripe: {
    publishable_key_test:
      "pk_test_51KHZbuENaAzJyBIeI8cZP5CBEF1HFu110B2Kbnjo1Mb439SsEGzhKBGYK27SudEijF5iS3jvNnjxLLyPNndAd5vZ00RdYNj3oU",
    // publishable_key_live: "pk_live_",
  },
  // Internal stuff
  globalVariables: {
    // https://stackoverflow.com/questions/10456174/oauth-how-to-test-with-local-urls
    // oauth_redirect_uri: window.location.origin + "/",
    // oauth_redirect_uri: "https://bit.ly/3L16yL4",
    oauth_redirect_uri: "com.daedalusfi://oauth2redirect", // needs to be whitelisted for Alpaca
  },
};

export const API = {
  key:
    process.env.FINANCIAL_MODEL_API_KEY || "8149215831d107c58f0ca191c40ebddd",
  url: "https://financialmodelingprep.com/api/v3",
};
