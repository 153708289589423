export const userInformationInputStyles = {
    color: "#A2A2A5",
    backgroundColor: "#1A1C38",
    border: "0",
    fontWeight: 300,
    fontSize: 15,
    padding: "20px",
    borderRadius: "10px",
    marginBottom: "5vh",
};

export const signOnStyles = {
    color: "#A2A2A5",
    backgroundColor: "#191922",
    border: "0",
    width: "20rem",
    height:"2rem",
    fontWeight: 300,
    fontSize: 15,
    padding: "20px",
    borderRadius: "10px",
    marginTop: "2vh",
    input__webkit_autofill:{WebkitTextFillColor: "#191922 !important"}
}