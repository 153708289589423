import { strategyConstants } from "./strategy.actions";

const initialState = {
  strategyToEdit: null,
  isInitializedStrategies: false,
  strategies: [],
  isInitializedTemplateStrategies: false,
  templateStrategies: [],
};

export const strategyReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case strategyConstants.SET_STRATEGY_TO_EDIT:
      return { ...state, strategyToEdit: action.strategy };

    case strategyConstants.STRATEGIES_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedStrategies: true,
        strategies: payload.strategies.sort((a, b) =>
          a.created._seconds < b.created._seconds ? -1 : 1
        ),
      };

    case strategyConstants.TEMPLATESTRATEGIES_FETCH_SUCCESS:
      return {
        ...state,
        isInitializedTemplateStrategies: true,
        templateStrategies: payload.templateStrategies.sort((a, b) =>
          a.created._seconds < b.created._seconds ? -1 : 1
        ),
      };

    default:
      return state;
  }
};
