import firebase from "firebase/app";
import "firebase/auth";
import { parsePhoneNumberWithError, CountryCode } from "libphonenumber-js";
import isLowercase from "validator/lib/isLowercase";
import isUppercase from "validator/lib/isUppercase";
import isAlpha from "validator/lib/isAlpha";

export const formatPhoneNumberIntoE164 = (phoneNumber) => {
  //if phoneNumber is parseable then it is returned in E.164 format,
  //otherwise the empty string is returned.
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(phoneNumber, "US");
    //the following line can check if the parsed number is also a valid one
    //an example of valid US number is "(702) 365-1234"
    //console.log(formattedPhoneNumber ? formattedPhoneNumber.isValid() : "");
    //
    //Output number is of type E164Number, so it's convenient to cast it.
    //https://github.com/catamphetamine/libphonenumber-js/blob/master/types.d.ts
    return formattedPhoneNumber ? String(formattedPhoneNumber.number) : "";
  } catch (error) {
    return "";
  }
};

export const formatPhoneNumberIntoNational = (phoneNumber, countryCode) => {
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(
      phoneNumber,
      countryCode || "US"
    );
    return formattedPhoneNumber
      ? String(formattedPhoneNumber.formatNational())
      : "";
  } catch (error) {
    return "";
  }
};

export const isValidPassword = (password) => {
  const mockupCondition = !(
    password.length < 6 ||
    isLowercase(password) || //only lowercase
    isUppercase(password) || //only uppercase
    isAlpha(password)
  ); //only letters (a-zA-Z)

  return true;
};

/*
 * returns difference between two numbers in %
 * @param {a} 1 number
 * @param {b} 2 number
 */

export function relDiff(a, b) {
  return 100 * Math.abs((a - b) / ((a + b) / 2));
}

/*
 * returns formatted number
 * @param {x} number
 */

export function numberWithCommas(x) {
  if (typeof x !== "undefined") {
    return x.toLocaleString();
  } else {
    return "";
  }
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export function writeUserData(user) {
  let uid = makeid(10);
  // firebase.database().ref('users/' + uid).set({
  //   ...user,
  //   uniqueId : uid
  // }).then((res)=> console.log(res))
  // .catch((err)=>{
  // 	console.log("ERROR INDATABASE")
  // 	console.error(err)
  // })
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// Use Regex to remove all charcaters after hyphen including hyphen. If no hyphen then don't change anything.
const removeHyphen = (str) => {
  return str.replace(/\-.+$/, "");
};
