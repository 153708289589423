import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Image, Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import { NotificationManager } from "react-notifications";
import { encodeCrypto } from "../_utils/encodeDecode";
import { callService } from "../_api/api";

import OauthPopup from "react-oauth-popup";
import { config } from "../config";

import { updateUser } from "../_redux/users/user.actions";
import ModalHeader from "react-bootstrap/ModalHeader";

const ACCOUNT_TYPE = {
  LIVE: "Live",
  PAPER: "Paper"
};

const initialState = {
  formData: {
    accountType: ACCOUNT_TYPE.LIVE,
    paper_api_key: "",
    paper_secret_key: "",
    paper_passphrase: "", // coinbase only
    live_api_key: "",
    live_secret_key: "",
    live_passphrase: "" // coinbase only
  },
  formErrors: {
    paper_api_key: "",
    paper_secret_key: "",
    paper_passphrase: "", // coinbase only
    live_api_key: "",
    live_secret_key: "",
    live_passphrase: "" // coinbase only
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFormData":
      let _formData = { ...state.formData };
      let _formErrors = { ...state.formErrors };

      _formData[action.field] = action.payload;

      return {
        ...state,
        formData: _formData,
        formErrors: { ..._formErrors, [action.field]: "" }
      };

    case "setFormErrors":
      return {
        ...state,
        formErrors: action.payload
      };

    case "reset":
      const { linkedAccount } = action.payload;
      let accountType = initialState.formData.accountType;
      let paper_api_key = "";
      let paper_secret_key = "**********";
      let paper_passphrase = "";
      let live_api_key = "";
      let live_secret_key = "**********";
      let live_passphrase = "";
      let resetState = JSON.parse(JSON.stringify(initialState)); //deep copy

      switch (linkedAccount.name) {
        case "Alpaca":
          accountType = linkedAccount.Alpaca.paper ? ACCOUNT_TYPE.PAPER : ACCOUNT_TYPE.LIVE;
          paper_api_key = linkedAccount.Alpaca.paper_api_key;
          paper_secret_key = linkedAccount.Alpaca.paper_secret_key;
          live_api_key = linkedAccount.Alpaca.live_api_key;
          live_secret_key = linkedAccount.Alpaca.live_secret_key;
          break;
        case "Binance":
          break;
        case "Coinbase":
          break;
        case "FTX":
          break;
        default:
          break;
      }

      resetState.formData.accountType = accountType;

      resetState.formData.paper_api_key = paper_api_key;
      resetState.formData.paper_secret_key = paper_secret_key;
      resetState.formData.paper_passphrase = paper_passphrase;

      resetState.formData.live_api_key = live_api_key;
      resetState.formData.live_secret_key = live_secret_key;
      resetState.formData.live_passphrase = live_passphrase;

      return resetState;
    default:
      throw new Error();
  }
};

const BrokerCard = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { formData, formErrors } = state;

  const { user, broker, linkedAccount } = props;

  const navigate = useNavigate();


  useEffect(() => {
    if (linkedAccount) {
      // console.log(linkedAccount);
      dispatch({
        type: "reset",
        payload: { linkedAccount }
      });
    }

    return () => {
    };
  }, [linkedAccount]);

  const [loading, setLoading] = useState(false);
  const [isBrokerConfigurationOpen, setBrokerConfigurationOpen] = useState(false);

  const checkAndSubmitForm = async () => {
    let formData = state.formData;
    let formErrors = state.formErrors;

    // trims strings
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === "string" ||
        formData[key] instanceof String
      ) {
        formData[key] = formData[key].trim();
      }
    });

    if (formData.paper_api_key.length === 0) {
      formErrors.paper_api_key = "Empty or invalid value";
    }

    if (formData.paper_secret_key.length === 0) {
      formErrors.paper_secret_key = "Empty or invalid value";
    }

    const hasErrors = Object.values(formErrors).some(
      (error) => error.length !== 0
    );

    if (hasErrors) {
      dispatch({
        type: "setFormErrors",
        payload: formErrors
      });
    } else {
      const newUser = user;
      const accountData = {
        [broker.name]: {
          // these are the only keys used, but this should be
          // split into paper and live keys
          api_key: formData.paper_api_key,
          secret_key: formData.paper_secret_key,
          passphrase: formData.paper_passphrase,
          //
          paper_api_key: formData.paper_api_key,
          paper_secret_key: formData.paper_secret_key,
          paper_passphrase: formData.paper_passphrase,
          //
          live_api_key: formData.live_api_key,
          live_secret_key: formData.live_secret_key,
          live_passphrase: formData.live_passphrase
          //
          // access_token  // for OAuth, in due time
          // scope         // for OAuth, in due time
          // token_type    // for OAuth, in due time
        },
        name: broker.name,
        balance: 100000, // TODO get this number from the API
        paperAccount: formData.accountType === ACCOUNT_TYPE.PAPER,
        type: broker.isCryptoOnly ? "crypto" : "stock",
        positions: []
      };
      let isAccountExist = false;

      let connectedAccounts = user.connectedAccounts.map((account) => {
        if (account.name === broker.name) {
          isAccountExist = true;
          return accountData;
        }
        return account;
      });

      if (!isAccountExist) {
        connectedAccounts = [
          ...connectedAccounts,
          accountData
        ];
      }

      newUser.connectedAccounts = connectedAccounts;

      setLoading(true);
      let success = await props.updateUser(newUser);
      setLoading(false);

      if (success) {
        setBrokerConfigurationOpen(false);
        NotificationManager.success(
          `${broker.name} added, you can now set up strategies and automate trades`
        );
        navigate("/home");
      } else {
        NotificationManager.error("Error occured, please try to put the correct API key.");
      }
    }
  };

  // https://alpaca.markets/docs/api-references/oauth-api/
  // https://alpaca.markets/docs/oauth/guide/
  // https://alpaca.markets/learn/oauth-react-firebase-1/
  // https://alpaca.markets/learn/how-to-create-your-own-trading-app-using-oauth-with-react/
  // const onCode = async (code, name) => {
  //   try {
  //     let payload = {
  //       authData: "",
  //       authType: "",
  //       isPrimary: true,
  //       defaultCrypto: {
  //         balance: 0,
  //         type: "crypto",
  //       },
  //       defaultStocks: {
  //         balance: 0,
  //         type: "stocks",
  //       },
  //     };

  //     let tokenPayload = {
  //       code: code,
  //       apiType: name,
  //       redirect_uri: "",
  //     };

  //     let isSucces = false;

  //     switch (name) {
  //       case "Coinbase":
  //         {
  //           tokenPayload.redirect_uri =
  //             config.globalVariables.oauth_redirect_uri;
  //           const res = await callService("getApiAuthToken", tokenPayload);
  //           if (!res.data.status) throw { message: res.data.message };
  //           tokenPayload["code"] = res.data.data;
  //           payload["authData"] = res.data.data;
  //           payload["authType"] = "Coinbase";
  //           payload["isPrimary"] = true;
  //           isSucces = true;
  //         }
  //         break;
  //       case "Alpaca":
  //         {
  //           tokenPayload.redirect_uri =
  //             config.globalVariables.oauth_redirect_uri;
  //           const res = await callService("getApiAuthToken", tokenPayload);
  //           if (!res.data.status) throw { message: res.data.message };
  //           tokenPayload["code"] = res.data.data;
  //           payload["authData"] = res.data.data;
  //           payload["authType"] = "Alpaca";
  //           payload["isPrimary"] = true;
  //           isSucces = true;
  //         }
  //         break;
  //       case "Binance": {
  //         tokenPayload.redirect_uri = config.globalVariables.oauth_redirect_uri;
  //         tokenPayload.code = encodeCrypto(JSON.stringify(tokenPayload.code));
  //         const reqPayload = {
  //           router: "/getAccount",
  //           authType: "Binance",
  //           ...tokenPayload,
  //         };
  //         const res = await callService("apiRouter", reqPayload);
  //         if (!res.data.status) throw { message: res.data.message };
  //         payload["authData"] = tokenPayload.code;
  //         payload["authType"] = "Binance";
  //         payload["isPrimary"] = true;
  //         isSucces = true;
  //       }
  //       default:
  //         break;
  //     }
  //     if (isSucces) {
  //       await callService("connectAccount", payload);
  //       this.props.history.push("/");
  //     } else {
  //       NotificationManager.error("Connection Failed, Please try again.");
  //     }
  //   } catch (err) {
  //     NotificationManager.error("Connection Failed, Please try again.");
  //   }
  // };
  //
  // let oauth_redirect_uri = config.globalVariables.oauth_redirect_uri;
  // let oauth_broker_url = `${broker.oauth_url}response_type=code&client_id=${broker.client_id}&redirect_uri=${oauth_redirect_uri}&state=${broker.state}&scope=${broker.scope}`;

  return (
    <>
      <Col md="4" className="d-flex justify-content-center">
        <Button
          style={{ minHeight:"10vh", padding:"20px", margin:"20px",  backgroundColor: "#0E0E13", border: "1px solid #1F1F1F" }}
          onClick={() => {
            if (broker.name == "Alpaca"){
              setBrokerConfigurationOpen(true)
            } else {
              NotificationManager.error("This broker is not supported yet.");
            }
          }}
        >
          <Image src={broker.logo} width={250} height={4} />
        </Button>
      </Col>
      {/* <Col md="1">
        {linkedAccount && <p>Current balance: {linkedAccount.balance}</p>} */}
        {/*{isEditing ? (*/}
        {/*  <>*/}
        {/*    <Button*/}
        {/*      disabled={loading || broker.name !== "Alpaca"}*/}
        {/*      onClick={async () => await checkAndSubmitForm()}*/}
        {/*    >*/}
        {/*      Save*/}
        {/*    </Button>*/}
        {/*    {linkedAccount && (*/}
        {/*      <Button*/}
        {/*        disabled={loading}*/}
        {/*        onClick={async () =>*/}
        {/*          dispatch({*/}
        {/*            type: "reset",*/}
        {/*            payload: { linkedAccount }*/}
        {/*          })*/}
        {/*        }*/}
        {/*      >*/}
        {/*        Reset*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  <Button*/}
        {/*    disabled={loading}*/}
        {/*    onClick={async () =>*/}
        {/*      dispatch({*/}
        {/*        type: "setIsEditing",*/}
        {/*        payload: { isEditing: true }*/}
        {/*      })*/}
        {/*    }*/}
        {/*  >*/}
        {/*    Edit*/}
        {/*  </Button>*/}
        {/*)}*/}

        {/* {broker.isOAuthEnabled && (
          <span>
            <OauthPopup
              url={oauth_broker_url}
              // onCode={(code, name) => onCode(code, name)}
              onCode={(code, params) => {
                console.log("wooooo a code", code);
                console.log(
                  "alright! the URLSearchParams interface from the popup url",
                  params
                );
              }}
              onClose={() => {}}
            >
              <Button disabled={loading} onClick={() => {}}>connect OAuth</Button>
            </OauthPopup>
          </span>
        )}
      </Col> */}

      {/* <Row>
          <Col md="4">
            <Form.Label>LIVE api_key</Form.Label>
            <Form.Control
              type="text"
              placeholder="live_api_key"
              name="live_api_key"
              value={formData.live_api_key}
              disabled={loading || !isEditing}
              onChange={(e) =>
                dispatch({
                  type: "setFormData",
                  field: e.target.name,
                  payload: e.target.value,
                })
              }
            />
          </Col>
          {formErrors.live_api_key && (
            <Form.Text className="text-danger">
              {formErrors.live_api_key}
            </Form.Text>
          )}
          <Col md="4">
            <Form.Label>LIVE secret_key</Form.Label>
            <Form.Control
              type="text"
              placeholder="live_secret_key"
              name="live_secret_key"
              value={formData.live_secret_key}
              disabled={loading || !isEditing}
              onChange={(e) =>
                dispatch({
                  type: "setFormData",
                  field: e.target.name,
                  payload: e.target.value,
                })
              }
            />
            {formErrors.live_secret_key && (
              <Form.Text className="text-danger">
                {formErrors.live_secret_key}
              </Form.Text>
            )}
          </Col>
          {broker.name === "Coinbase" && (
            <Col md="4">
              <Form.Label>LIVE passphrase</Form.Label>
              <Form.Control
                type="text"
                placeholder="live_passphrase"
                name="live_passphrase"
                value={formData.live_passphrase}
                disabled={loading || !isEditing}
                onChange={(e) =>
                  dispatch({
                    type: "setFormData",
                    field: e.target.name,
                    payload: e.target.value,
                  })
                }
              />
              {formErrors.live_passphrase && (
                <Form.Text className="text-danger">
                  {formErrors.live_passphrase}
                </Form.Text>
              )}
            </Col>
          )}
        </Row> */}

      

      <Modal show={isBrokerConfigurationOpen} size="lg" contentClassName="bg-dark">
        <ModalHeader>
          <h1>Configure {broker.name}</h1>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <BootstrapSwitchButton
                checked={formData.accountType === ACCOUNT_TYPE.PAPER}
                onlabel={ACCOUNT_TYPE.PAPER}
                onstyle="secondary"
                offlabel={ACCOUNT_TYPE.LIVE}
                offstyle="success"
                size="xm"
                width={100}
                onChange={(checked) => {
                  dispatch({
                    type: "setFormData",
                    field: "accountType",
                    payload: checked ? ACCOUNT_TYPE.PAPER : ACCOUNT_TYPE.LIVE
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Label>{formData.accountType} API Key</Form.Label>

              {ACCOUNT_TYPE.LIVE === formData.accountType &&
                <><Form.Control
                  type="text"
                  placeholder="Enter live API key"
                  name="live_api_key"
                  value={formData.live_api_key}
                  disabled={loading}
                  onChange={(e) =>
                    dispatch({
                      type: "setFormData",
                      field: e.target.name,
                      payload: e.target.value
                    })
                  }
                />

                  {formErrors.live_api_key && (
                    <Form.Text className="text-danger">
                      {formErrors.live_api_key}
                    </Form.Text>
                  )}
                </>
              }

              {ACCOUNT_TYPE.PAPER === formData.accountType &&
                <><Form.Control
                  type="text"
                  placeholder="Enter paper API key"
                  name="paper_api_key"
                  value={formData.paper_api_key}
                  disabled={loading}
                  onChange={(e) =>
                    dispatch({
                      type: "setFormData",
                      field: e.target.name,
                      payload: e.target.value
                    })
                  }
                />
                  {formErrors.paper_api_key && (
                    <Form.Text className="text-danger">
                      {formErrors.paper_api_key}
                    </Form.Text>
                  )}
                </>
              }
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Label>{formData.accountType} Secret Key</Form.Label>

              {ACCOUNT_TYPE.LIVE === formData.accountType &&
                <>
                  <Form.Control
                    type="text"
                    placeholder="Enter live secret key"
                    name="live_secret_key"
                    value={formData.live_secret_key}
                    disabled={loading}
                    onChange={(e) =>
                      dispatch({
                        type: "setFormData",
                        field: e.target.name,
                        payload: e.target.value
                      })
                    }
                  />
                  {formErrors.live_secret_key && (
                    <Form.Text className="text-danger">
                      {formErrors.live_secret_key}
                    </Form.Text>
                  )}
                </>
              }

              {ACCOUNT_TYPE.PAPER === formData.accountType &&
                <>
                  <Form.Control
                    type="text"
                    placeholder="Enter paper secret key"
                    name="paper_secret_key"
                    value={formData.paper_secret_key}
                    disabled={loading}
                    onChange={(e) =>
                      dispatch({
                        type: "setFormData",
                        field: e.target.name,
                        payload: e.target.value
                      })
                    }
                  />

                  {formErrors.paper_secret_key && (
                    <Form.Text className="text-danger">
                      {formErrors.paper_secret_key}
                    </Form.Text>
                  )}
                </>
              }


            </Col>
            {broker.name === "Coinbase" && (
              <Col md="4">
                <Form.Label>PAPER passphrase</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="paper_passphrase"
                  name="paper_passphrase"
                  value={formData.paper_passphrase}
                  disabled={loading}
                  onChange={(e) =>
                    dispatch({
                      type: "setFormData",
                      field: e.target.name,
                      payload: e.target.value
                    })
                  }
                />

                {formErrors.live_passphrase && (
                  <Form.Text className="text-danger">
                    {formErrors.live_passphrase}
                  </Form.Text>
                )}

                {formErrors.paper_passphrase && (
                  <Form.Text className="text-danger">
                    {formErrors.paper_passphrase}
                  </Form.Text>
                )}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="success"
            size="sm"
            disabled={loading || broker.name !== "Alpaca"}
            onClick={async () => await checkAndSubmitForm()}
          >
            Save
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => setBrokerConfigurationOpen(false)}
            disabled={loading}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user
});

export default connect(mapStateToProps, { updateUser })(BrokerCard);
