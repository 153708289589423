import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Image } from "react-bootstrap";
import LogoRobinhood from "../_assets/img/logo-robinhood.png";
import LogoCoinbase from "../_assets/img/logo-coinbase.png";
import LogoBinance from "../_assets/img/logo-binance.png";
import LogoAlpaca from "../_assets/img/logo-alpaca.png";
import LogoFTX from "../_assets/img/logo-ftx.png";
import DaedalusLogo from "../_assets/img/logo.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { getCoinbaseAssetCurrentPrice } from "../_api/exchanges/coinbase";
import { callService } from "../_api/api";
import { config } from "../config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Alert } from "@mui/material";

const Container = styled.div`
  background-color: #212329;
  border-radius: 15px;
  padding-top: 10px;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  margin-bottom: 20px;
  height: 275px;
  @media screen and (min-width: 992px) {
    width: 29vw;
  }
`;

const ImageWrapper = styled.div`
  color: white;
  font-size: 60px;
  font-weight: bold;
  font-size: 28px;
  margin-left: 10px;
  display: flex;
  justify-content: flex-start;
`;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <MoreVertIcon style={{ color: "white", height:"20px"}} />
    {children}
  </a>
));

let isFetched = false;
const getCorrectAPI = async (type, symbol) => {
  if (type === "stock") {
    return `${config.iex.base_url}/stable/stock/${symbol}/quote?displayPercent=true&token=${config.iex.publish_key}`;
  }
  // if (type === "crypto") {
  //   let exchangeData = {
  //     "Coinbase": getCoinbaseAssetCurrentPrice({symbol: `${symbol}`})
  //   }
  //   let price = await exchangeData['Coinbase']
  //   return price.data
  // }
};

function separateWithComma(numbr) {
  //seprte numbers with comma
  let number = String(numbr);
  if (number.split(".").length === 1) {
    let na = number.split(""); //new array
    let nnl = na.length; // array length
    let counter = nnl;
    let returnable = [];
    for (let i = 0; i < Math.ceil(nnl / 3); i++) {
      na.length <= 3
        ? returnable.push(na.join(""))
        : //let nn =  //new number
          returnable.push(na.splice(counter - 3, 3).join(""));
      counter = counter - 3;
    }
    return returnable.reverse().join(",");
  } else {
    return separateWithComma(number.split(".")[0]) + "." + number.split(".")[1];
  }
}

const BrokerCard = (props) => {
  const { account } = props;
  const accountName = account.name;

  const [totalBalance, setTotalBalance] = useState(0);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (account) {
      setPositions(account.positions);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (positions.length > 0 && !isFetched) {
        isFetched = true;
        const newPositions = [];
        for (let position of positions) {
          let newPosition = { ...position };
          let symbol = position.symbol;
          const url = await getCorrectAPI(account.type, symbol);
          const response = url; // await fetch(url);
          const result = { latestPrice: response }; //await response.json()
          if (result.latestPrice) {
            newPosition.latestPrice = result.latestPrice;
            if (!newPosition.latestPrice) {
              const response = await callService("getSymbolQuote", {
                accountType: account.type,
                symbol,
                accountName: account.name,
              });
              newPosition = { ...newPosition, ...response.data };
            }
            newPositions.push(newPosition);
          }
          calculateTotalBalance(newPositions);
        }
      }
      if (positions.length === 0) {
        setTotalBalance(account.balance.toFixed(2));
      }
    })();
  }, [positions]);

  const calculateTotalBalance = (positions) => {
    let totalBalance = account.balance;
    for (let position of positions) {
      totalBalance += position.latestPrice * position.quantity;
    }
    setTotalBalance(totalBalance.toFixed(2));

    setLoading(false);
  };

  const getBrokerLogo = () => {
    let image = DaedalusLogo;

    switch (accountName) {
      case "Crypto Paper Trading":
      case "Stock Paper Trading":
      case "Daedalus Paper Trading":
      case "daedalus-paper":
        image = DaedalusLogo;
        break;
      case "Alpaca":
      case "alpaca":
        image = LogoAlpaca;
        break;
      case "binance":
        image = LogoBinance;
        break;
      case "coinbase":
        image = LogoCoinbase;
        break;
      case "ftx":
        image = LogoFTX;
        break;
      case "robinhood":
        image = LogoRobinhood;
        break;
      default:
        image = DaedalusLogo;
        break;
    }
    return image;
  };

  const getRoute = () => {
    let route = "/broker/daedalus-paper";

    switch (accountName) {
      case "Crypto Paper Trading":
      case "Stock Paper Trading":
      case "Daedalus Paper Trading":
      case "daedalus-paper":
        route = "/broker/daedalus-paper";
        break;
      case "Alpaca":
      case "alpaca":
        route = "/broker/alpaca";
        break;
      case "ftx":
        route = "/broker/ftx";
        break;
    }
    return route;
  };

  return (
    <Container
      opacity="100%"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#191922",
        width: "17rem",
        height: "8rem",
        justifyContent: "space-between",
        alignItems: "flex-start",
        outline: "1px solid #282B34",
        borderRadius: "8px",
      }}
    >
      <div className="d-flex w-100 justify-content-between">
        <Link
          to={getRoute()}
          className="d-flex flex-column align-items-between w-100"
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "normal",
              fontSize: "smaller",
              paddingLeft: "10px",
            }}
          >
            {account.name}
          </span>
          <ImageWrapper>
            <span
              style={{
                backgroundColor: "#282B34",
                padding: "8px",
                borderRadius: "8px",
                marginTop: "5px",
              }}
            >
              $
              {loading ? (
                separateWithComma(totalBalance)
              ) : (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "-50px" }}
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}
            </span>
          </ImageWrapper>
        </Link>
        <Dropdown >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          </Dropdown.Toggle>
          <div style={{display:"none"}}></div>
          <Dropdown.Menu variant="dark" style={{ minWidth:"auto", fontSize: "14px", outline: "1px solid #282B34", backgroundColor: "rgb(25, 25, 34)"}}>
            {/* TO DO: make these functional */}
            <Dropdown.Item href="#">Info</Dropdown.Item>
            <Dropdown.Item  href="#">Edit</Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item className="text-danger" href="#">Delete </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          height: "auto",
          paddingRight: "30px",
          backgroundColor: "#0E0E13",
          width: "100%",
        }}
      >
        <Image
          style={{ width: "123px", marginLeft: "10px" }}
          src={getBrokerLogo()}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (storeState) => ({});

export default connect(mapStateToProps, {})(BrokerCard);
