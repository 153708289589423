export const currenciesList = [
    {
        value: "USD",
        label: "USD"
    },
    {
        value: "EUR",
        label: "EUR"
    },
    {
        value: "ARS",
        label: "ARS"
    },
    {
        value: "AMD",
        label: "AMD"
    },
    {
        value: "AUD",
        label: "AUD"
    },
    {
        label: "AZN",
        label: "AZN"
    },
    {
        value: "BHD",
        label: "BHD"
    },
    {
        value: "BDT",
        label: "BDT"
    },
    {
        value: "BYN",
        label: "BYN"
    },
    {
        value: "BMD",
        label: "BMD"
    },
    {
        value: "BOB",
        label: "BOB"
    },
    {
        value: "BAM",
        label: "BAM"
    },
    {
        value: "BRL",
        label: "BRL"
    },
    {
        value: "BGN",
        label: "BGN"
    },
    {
        value: "KHR",
        label: "KHR"
    },
    {
        value: "CAD",
        label: "CAD"
    },
    {
        value: "CLP",
        label: "CLP"
    },
    {
        value: "CNY",
        label: "CNY"
    },
    {
        value: "COP",
        label: "COP"
    },
    {
        value: "CRC",
        label: "CRC"
    },
    {
        value: "HRK",
        label: "HRK"
    },
    {
        value: "CUP",
        label: "CUP"
    },
    {
        value: "CZK",
        label: "CZK"
    },
    {
        value: "DZD",
        label: "DZD"
    },
    {
        value: "DKK",
        label: "DKK"
    },
    {
        value: "DOP",
        label: "DOP"
    },
    {
        value: "EGP",
        label: "EGP"
    },
    {
        value: "GEL",
        label: "GEL"
    },
    {
        value: "GHS",
        label: "GHS"
    },
    {
        value: "GTQ",
        label: "GTQ"
    },
    {
        value: "HNL",
        label: "HNL"
    },
    {
        label: "HKD",
        value: "HKD"
    },
    {
        value: "HUF",
        label: "HUF"
    },
    {
        value: "ISK",
        label: "ISK"
    },
    {
        value: "INR",
        label: "INR"
    },
    {
        value: "IDR",
        label: "IDR"
    },
    {
        value: "IRR",
        label: "IRR"
    },
    {
        value: "IQD",
        label: "IQD"
    },
    {
        value: "ILS",
        label: "ILS"
    },
    {
        value: "JMD",
        label: "JMD"
    },
    {
        value: "JPY",
        label: "JPY"
    },
    {
        value: "JOD",
        label: "JOD"
    },
    {
        value: "KZT",
        label: "KZT"
    },
    {
        value: "KES",
        label: "KES"
    },
    {
        value: "KWD",
        label: "KWD"
    },
    {
        value: "KGS",
        label: "KGS"
    },
    {
        value: "LBP",
        label: "LBP"
    },
    {
        value: "MKD",
        label: "MKD"
    },    {
        value: "MYR",
        label: "MYR"
    },
    {
        value: "MUR",
        label: "MUR"
    },
    {
        value: "MXN",
        label: "MXN"
    },
    {
        value: "MDL",
        label: "MDL"
    },
    {
        value: "MNT",
        label: "MNT"
    },
    {
        value: "MAD",
        label: "MAD"
    },
    {
        value: "MMK",
        label: "MMK"
    },
    {
        value: "NAD",
        label: "NAD"
    },    {
        value: "NPR",
        label: "NPR"
    },
    {
        value: "TWD",
        label: "TWD"
    },
    {
        value: "NZD",
        label: "NZD"
    },
    {
        value: "NIO",
        label: "NIO"
    },
    {
        value: "NGN",
        label: "NGN"
    },
    {
        value: "NOK",
        label: "NOK"
    },    {
        value: "OMR",
        label: "OMR"
    },
    {
        value: "PKR",
        label: "PKR"
    },
    {
        value: "PAB",
        label: "PAB"
    },
    {
        value: "PEN",
        label: "PEN"
    },
    {
        value: "PHP",
        label: "PHP"
    },
    {
        value: "PLN",
        label: "PLN"
    },    {
        value: "GBP",
        label: "GBP"
    },
    {
        value: "QAR",
        label: "QAR"
    },
    {
        value: "RON",
        label: "RON"
    },
    {
        value: "RUB",
        label: "RUB"
    },
    {
        value: "SAR",
        label: "SAR"
    },
    {
        value: "RSD",
        label: "RSD"
    },    {
        value: "SGD",
        label: "SGD"
    },
    {
        value: "ZAR",
        label: "ZAR"
    },
    {
        value: "KRW",
        label: "KRW"
    },
    {
        value: "SSP",
        label: "SSP"
    },
    {
        value: "VES",
        label: "VES"
    },
    {
        value: "LKR",
        label: "LKR"
    },    {
        value: "SEK",
        label: "SEK"
    },
    {
        value: "CHF",
        label: "CHF"
    },
    {
        value: "THB",
        label: "THB"
    },
    {
        value: "TTD",
        label: "TTD"
    },
    {
        label: "TND",
        value: "TND"
    },
    {
        value: "TRY",
        label: "TRY"
    },    {
        value: "UGX",
        label: "UGX"
    },
    {
        label: "UAH",
        value: "UAH"
    },
    {
        label: "AED",
        value: "AED"
    },
    {
        value: "UYU",
        label: "UYU"
    },
    {
        value: "UZS",
        label: "UZS"
    },
    {
        value: "VND",
        label: "VND"
    }
]