/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaChartLine,
  FaHome,
  FaChartPie,
  FaSignOutAlt,
  FaGlassWhiskey,
} from "react-icons/fa";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../_utils/auth_context";
import { formatFireError } from "../firebase";
import { API } from "../config";
import { connect } from "react-redux";
import { userLogout } from "../_redux/users/user.actions";
import Logo from "./Logo";
import { Row, Col } from "react-bootstrap";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import { borderRight } from "@mui/system";
function SideBar(props) {
  const { currentUser, logout } = useAuth();
  const [path, setPath] = useState("");
  const [marketStatus, setMarketStatus] = useState(false);
  /** router hook for navigation */
  const navigate = useNavigate();
  const location = useLocation();
  const lowerCasePath = path.toLowerCase();
  const isHomeOrConnect = lowerCasePath === "/home" || lowerCasePath === "/connect" || lowerCasePath === "/home2" || lowerCasePath === "/email-verify";
  console.log()

  const _fetchMarketStatus = async () => {
    const { data } = await axios(
      `${API.url}/is-the-market-open?apikey=${API.key}`
    );
    if (data.isTheStockMarketOpen) {
      setMarketStatus(true);
    }
  };

  useEffect(() => {
    setPath(location.pathname);
  });

  async function onLogout() {
    try {
      // these logouts will need to be simplified
      await logout();
      await props.userLogout();
    } catch (error) {
      NotificationManager.error(formatFireError(error.code));
    }
  }

  useEffect(() => {
    if (currentUser != null) {
      _fetchMarketStatus();
    }
  }, [currentUser]);
  return (

    <div
      className="vh-100 position-fixed"
      style={{
        width:"210px",
        left: 0,
        top: 0,
        zIndex: 999,
        backgroundColor: "#0E0E13",
        borderRight: "1px solid #1F1F1F",
      }}
    >
      <ul style={{ color: "#D9D9D9", margin: 0, padding: "10px", marginTop: "20px" , fontSize:"16px"}}>
        <div style={{marginLeft: "10px", marginBottom: "30px"}}>
          <Logo />
        </div>

        <il>
          <a
            href="/home"
            style={{
              marginTop: "10px",
            }}
            className="nav-link px-2 py-2"
          >
            <span className="ms-1 d-none d-sm-inline">
              <HomeOutlinedIcon
                style={{ margin: 0, color: `${path === "/home" ? "#6BE7AF":  "#D9D9D9"}`}}
              />
              <span   style={{ color: `${path === "/home" ? "#6BE7AF" : "#D9D9D9"}`,  marginLeft: "30px" }}>Home</span>
            </span>
          </a>
        </il>
        { !isHomeOrConnect && (
        <il>
          <a
          //TO DO: CHANGE TO be dynamic subpath
            href="/broker/daedalus-stocks"
            style={{
              marginTop: "10px",
            }}
            className="nav-link px-2 py-2"
          >
            <span className="ms-1 d-none d-sm-inline" >
              <GridViewOutlinedIcon
                style={{color: `${path === "/broker/daedalus-stocks" ? "#6BE7AF" : "#D9D9D9"}`, margin: 0}}
              />
              <span  style={{ color: `${path === "/broker/daedalus-stocks" ? "#6BE7AF" : "#D9D9D9"}`,marginLeft: "30px" }}>Dashboard</span>
            </span>
          </a>
        </il>
        )
        }
       { !isHomeOrConnect && (

        <il className={`${path === "/my-portfolio" ? "border-1 rounded" : ""}`}>
          <a
            href="/my-portfolio"
            style={{
              marginTop: "10px",
            }}
            className="nav-link px-2 py-2"
          >
            <span className="ms-1 d-none d-sm-inline" >
              <PieChartOutlineOutlinedIcon
                style={{color: `${path === "/my-portfolio" ? "#6BE7AF" : "#D9D9D9"}`, margin: 0}}
                
              />
              <span style={{ color: `${path === "/my-portfolio" ? "#6BE7AF" : "#D9D9D9"}`, marginLeft: "30px" }}>My Portfolio</span>
            </span>
          </a>
        </il>
        )}
        { !isHomeOrConnect && (
        <il>
          <a
            href="/settings"
            style={{
              marginTop: "10px",
            }}
            className="nav-link px-2 py-2"
          >
            <span className="ms-1 d-none d-sm-inline" >
              <SettingsOutlinedIcon
                style={{color: `${path === "/settings" ? "#6BE7AF" : "#D9D9D9"}`, margin: 0}}
                
              />
              <span style={{color: `${path === "/settings" ? "#6BE7AF" : "#D9D9D9"}`, marginLeft: "30px" }}>Settings</span>
            </span>
          </a>
        </il>
       )}
        {/* <il>
        
          <a
            href="/login"
            style={{
              position:"absolute", bottom:10,
            }}
            onClick={onLogout}
            className="nav-link px-2 py-2"
          >
            <span className="ms-1 d-none d-sm-inline" >
              <LogoutOutlinedIcon
                style={{ margin: 0 }}
              />
              <span style={{ marginLeft: "30px" }}>Logout</span>
            </span>
          </a>
        </il> */}
      </ul>
    </div>
  );
}

const mapStateToProps = (storeState) => ({});

export default connect(mapStateToProps, {
  userLogout,
})(SideBar);
