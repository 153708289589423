import * as Blockly from "blockly/core";

// https://github.com/google/blockly-samples/tree/master/plugins/field-date
// import FieldDate from "@blockly/field-date";
import "@blockly/field-date";

// https://github.com/google/blockly-samples/tree/master/plugins/field-grid-dropdown
import { FieldGridDropdown } from "@blockly/field-grid-dropdown";
import "@blockly/field-grid-dropdown";

// ---------- //

Blockly.Blocks["condition_singularDateWindow"] = {
  init: function () {
    let today = new Date();
    let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

    // WARNING: in class Date() the month is 0-indexed, hence the +1 for the month
    let todayString = `${Number(today.getFullYear()).toLocaleString("en-US", {
      minimumIntegerDigits: 4,
      useGrouping: false,
    })}-${Number(today.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${Number(today.getDate()).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;

    // WARNING: in class Date() the month is 0-indexed, hence the +1 for the month
    let tomorrowString = `${Number(tomorrow.getFullYear()).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}-${Number(tomorrow.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}-${Number(tomorrow.getDate()).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`;

    this.jsonInit({
      type: "condition_singularDateWindow", //must be <= 30 chars
      colour: 230,
      tooltip: "From date to date",
      message0: "date is between %1 and %2",
      output: "Boolean", // null | true | "Number" | "Boolean"
      args0: [
        {
          type: "field_date",
          name: "STARTDATE",
          check: "String",
          date: todayString, //format is "date": "2020-02-20"
        },
        {
          type: "field_date",
          name: "ENDDATE",
          check: "String",
          date: tomorrowString, //format is "date": "2020-02-20"
          check: "String",
        },
      ],
      // previousStatement: null,
      // nextStatement: null,
    });

    // this.setColour(160);

    var thisBlock = this;
  },
};

Blockly.JavaScript["condition_singularDateWindow"] = function (block) {
  // const value = block.getField("STARTDATE").getText()
  const startDate = block.getFieldValue("STARTDATE");
  const endDate = block.getFieldValue("ENDDATE");
  const code =
    `(` +
    `( new Date("${startDate}").setHours(0,0,0,0) <= new Date() )` +
    ` && ` +
    `( new Date() <= new Date("${endDate}").setHours(23,59,59,999) )` +
    `)`;
  // return 'console.log(\'custom block\');\n';
  // return 'console.log(' + block.getField('DATE').getText() + ');\n';
  //
  // TODO: Change ORDER_NONE to the correct strength.
  // https://developers.google.com/blockly/guides/create-custom-blocks/operator-precedence
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// Blockly.Python['new_boundary_function'] = function (block) {
//   var text_name = block.getFieldValue('Name');
//   var statements_content = Blockly.Python.statementToCode(block, 'Content');
//   // TODO: Assemble Python into code variable.
//   var code = 'def ' + text_name + '(_object,**kwargs):\n' + statements_content + '\n';
//   return code;
// };

// Blockly.Python['return'] = function (block) {
//   var value_name = Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC);
//   // TODO: Assemble Python into code variable.
//   var code = 'return ' + value_name + '\n';
//   return code;
// };

// ---------- //

Blockly.Blocks["condition_recurringDateWindow"] = {
  init: function () {
    const dayOptions = [...Array(31).keys()].map((number) => [
      String(number + 1), // this is the value shown in dropdown, human-readable-value
      //this is the key, language-neutral-key
      Number(number + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    ]);

    const monthOptions = [...Array(12).keys()].map((number) => [
      // in setMonth(number, 1) it's better to keep 1 to not incurr in a bug
      new Date(new Date().setMonth(number, 1)).toLocaleString("en-US", {
        month: "short",
      }),
      // beware of +1 here
      Number(number + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    ]);

    this.appendDummyInput()
      .appendField("date in year is between ")
      .appendField(
        new FieldGridDropdown(dayOptions, this.validate_STARTDAY, {
          columns: 5,
          // primaryColour: null,
          // borderColour: null,
        }),
        "STARTDAY"
      )
      .appendField(" of ")
      .appendField(
        new FieldGridDropdown(monthOptions, this.validate_STARTMONTH, {
          columns: 3,
        }),
        "STARTMONTH"
      )
      .appendField(" and ")
      .appendField(
        new FieldGridDropdown(dayOptions, this.validate_ENDDAY, {
          columns: 5,
        }),
        "ENDDAY"
      )
      .appendField(" of ")
      .appendField(
        new FieldGridDropdown(monthOptions, this.validate_ENDMONTH, {
          columns: 3,
        }),
        "ENDMONTH"
      );

    // initializes fields
    this.setFieldValue("12", "ENDMONTH");
    this.setFieldValue("31", "ENDDAY");

    this.setOutput(true, "Boolean");
    this.setColour(230);
    this.setTooltip("From date to date every year");
  },

  // In validators it's not possible to alter the values shows
  // in the dropdown menu, so the only way it so force them straight
  // See: https://github.com/google/blockly/issues/1288

  validate_STARTDAY: function (valueToValidate) {
    let validatedValue = valueToValidate;
    const thisBlock = this.getSourceBlock();
    const current_STARTMONTH = Number(thisBlock.getFieldValue("STARTMONTH"));
    if (Number(valueToValidate) > 31) {
      validatedValue = "31";
    }
    if (current_STARTMONTH === 2 && Number(valueToValidate) > 28) {
      validatedValue = "28";
    }
    if (
      (current_STARTMONTH === 4 ||
        current_STARTMONTH === 6 ||
        current_STARTMONTH === 9 ||
        current_STARTMONTH === 11) &&
      Number(valueToValidate) > 30
    ) {
      validatedValue = "30";
    }
    //return null;
    return validatedValue;
  },

  validate_STARTMONTH: function (valueToValidate) {
    const thisBlock = this.getSourceBlock();
    const current_STARTDAY = Number(thisBlock.getFieldValue("STARTDAY"));

    if (valueToValidate === "02" && current_STARTDAY > 28) {
      thisBlock.setFieldValue("28", "STARTDAY");
    }

    if (
      (valueToValidate === "04" ||
        valueToValidate === "06" ||
        valueToValidate === "09" ||
        valueToValidate === "11") &&
      current_STARTDAY > 30
    ) {
      thisBlock.setFieldValue("30", "STARTDAY");
    }

    // console.log(this.getSourceBlock().getField("STARTDAY"));
    // console.log(this.getSourceBlock().getFieldValue("STARTDAY"));

    return valueToValidate;
  },

  validate_ENDDAY: function (valueToValidate) {
    let validatedValue = valueToValidate;
    const thisBlock = this.getSourceBlock();
    const current_ENDMONTH = Number(thisBlock.getFieldValue("ENDMONTH"));
    if (Number(valueToValidate) > 31) {
      validatedValue = "31";
    }
    if (current_ENDMONTH === 2 && Number(valueToValidate) > 28) {
      validatedValue = "28";
    }
    if (
      (current_ENDMONTH === 4 ||
        current_ENDMONTH === 6 ||
        current_ENDMONTH === 9 ||
        current_ENDMONTH === 11) &&
      Number(valueToValidate) > 30
    ) {
      validatedValue = "30";
    }
    return validatedValue;
  },

  validate_ENDMONTH: function (valueToValidate) {
    const thisBlock = this.getSourceBlock();
    const current_ENDDAY = Number(thisBlock.getFieldValue("ENDDAY"));
    if (valueToValidate === "02" && current_ENDDAY > 28) {
      thisBlock.setFieldValue("28", "ENDDAY");
    }
    if (
      (valueToValidate === "04" ||
        valueToValidate === "06" ||
        valueToValidate === "09" ||
        valueToValidate === "11") &&
      current_ENDDAY > 30
    ) {
      thisBlock.setFieldValue("30", "ENDDAY");
    }
    return valueToValidate;
  },
};

Blockly.JavaScript["condition_recurringDateWindow"] = function (block) {
  // const value = block.getField("STARTDATE").getText()
  const startDay = Number(block.getFieldValue("STARTDAY"));
  const startMonth = Number(block.getFieldValue("STARTMONTH"));
  const endDay = Number(block.getFieldValue("ENDDAY"));
  const endMonth = Number(block.getFieldValue("ENDMONTH"));

  let code = `( true )`;

  // if the end date is set as prior to the start date the condition defaults to false.
  // This check is necessary otherwise the function would fail
  // if the startTime is in a year and the endtime is in year+1
  if (
    new Date(new Date().setHours(23, 59, 59, 999)).setMonth(
      endMonth - 1,
      endDay
    ) <
    new Date(new Date().setHours(0, 0, 0, 0)).setMonth(startMonth - 1, startDay)
  ) {
    code = `( false )`;
  } else {
    // WARNING: in class Date() the month is 0-indexed, hence the -1
    code =
      `(` +
      `( new Date(new Date().setHours(0,0,0,0)).setMonth(${
        startMonth - 1
      }, ${startDay}) <= new Date() )` +
      ` && ` +
      `( new Date() <= new Date(new Date().setHours(23,59,59,999)).setMonth(${
        endMonth - 1
      }, ${endDay}) )` +
      `)`;
  }
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["condition_daytypeDate"] = {
  init: function () {
    let dayOptions = [
      ["Sunday", "0"],
      ["Monday", "1"],
      ["Tuesday", "2"],
      ["Wednesday", "3"],
      ["Thursday", "4"],
      ["Friday", "5"],
      ["Saturday", "6"],
    ];

    this.jsonInit({
      type: "condition_daytypeDate", //must be <= 30 chars
      colour: 230,
      tooltip: "Day of the week",
      message0: "day is %1",
      output: "Boolean", // null | true | "Number" | "Boolean"
      args0: [
        {
          type: "field_dropdown",
          name: "DAYTYPE",
          check: "String",
          options: dayOptions,
        },
      ],
    });
  },
};

Blockly.JavaScript["condition_daytypeDate"] = function (block) {
  let dayType = Number(block.getFieldValue("DAYTYPE"));
  let code = `(` + `( new Date().getDay() === ${dayType} )` + `)`;
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["condition_daytypeDateWindow"] = {
  init: function () {
    let dayOptions = [
      ["Sunday (0)", "0"],
      ["Monday (1)", "1"],
      ["Tuesday (2)", "2"],
      ["Wednesday (3)", "3"],
      ["Thursday (4)", "4"],
      ["Friday (5)", "5"],
      ["Saturday (6)", "6"],
    ];

    this.jsonInit({
      type: "condition_daytypeDateWindow", //must be <= 30 chars
      colour: 230,
      tooltip: "From day of the week to day of the week",
      message0: "day is between %1 and %2",
      output: "Boolean", // null | true | "Number" | "Boolean"
      args0: [
        {
          type: "field_dropdown",
          name: "DAYTYPESTART",
          check: "String",
          options: dayOptions,
        },
        {
          type: "field_dropdown",
          name: "DAYTYPEEND",
          check: "String",
          options: dayOptions,
        },
      ],
    });

    // initializes field
    this.setFieldValue("6", "DAYTYPEEND");
  },
};

Blockly.JavaScript["condition_daytypeDateWindow"] = function (block) {
  let dayTypeStart = Number(block.getFieldValue("DAYTYPESTART"));
  let dayTypeEnd = Number(block.getFieldValue("DAYTYPEEND"));

  let code =
    `(` +
    `( ${dayTypeStart} <= new Date().getDay() ) ` +
    ` && ` +
    `( new Date().getDay() <= ${dayTypeEnd} )` +
    `)`;

  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["condition_timeWindow"] = {
  init: function () {
    const hourOptions = [...Array(24).keys()].map((number) => [
      Number(number).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      Number(number).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    ]);

    const minuteOptions = [...Array(60).keys()].map((number) => [
      Number(number).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      Number(number).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    ]);

    this.jsonInit({
      type: "condition_daytypeDateWindow", //must be <= 30 chars
      colour: 230,
      tooltip: "From hh:mm to hh:mm",
      message0: "time is between %1 : %2 and %3 : %4",
      output: "Boolean", // null | true | "Number" | "Boolean"
      args0: [
        {
          type: "field_grid_dropdown",
          name: "HOURSTART",
          check: "String",
          options: hourOptions,
          columns: 6,
        },
        {
          type: "field_grid_dropdown",
          name: "MINUTESTART",
          check: "String",
          options: minuteOptions,
          columns: 10,
        },
        {
          type: "field_grid_dropdown",
          name: "HOUREND",
          check: "String",
          options: hourOptions,
          columns: 6,
        },
        {
          type: "field_grid_dropdown",
          name: "MINUTEEND",
          check: "String",
          options: minuteOptions,
          columns: 10,
        },
      ],
    });

    // initializes field
    this.setFieldValue("01", "MINUTESTART");
    this.setFieldValue("23", "HOUREND");
    this.setFieldValue("59", "MINUTEEND");
  },
};

Blockly.JavaScript["condition_timeWindow"] = function (block) {
  let hourStart = Number(block.getFieldValue("HOURSTART"));
  let minuteStart = Number(block.getFieldValue("MINUTESTART"));
  let hourEnd = Number(block.getFieldValue("HOUREND"));
  let minuteEnd = Number(block.getFieldValue("MINUTEEND"));

  let code = `( true )`;

  // if the end time is set as prior to the start time the condition defaults to false.
  // this check is necessary otherwise the function would fail
  // if the startTime is in a day and the endtime is in day+1
  if (
    new Date().setHours(hourEnd, minuteEnd, 0, 0) <
    new Date().setHours(hourStart, minuteStart, 0, 0)
  ) {
    code = `( false )`;
  } else {
    code =
      `(` +
      `( new Date().setHours(${hourStart},${minuteStart},0,0)  <= new Date() )` +
      ` && ` +
      `( new Date() <= new Date().setHours(${hourEnd},${minuteEnd},59,999) )` +
      `)`;
  }

  return [code, Blockly.JavaScript.ORDER_NONE];
};
