import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Image, Button } from "react-bootstrap";
import Toggle from "react-toggle";
import Loader from "../_layout/Loader";
import "react-toggle/style.css";
import EditIcon from "@mui/icons-material/Edit";

import { NotificationManager } from "react-notifications";
import stratPhoto from "../_assets/img/stratPhoto.png";

import {
  setStrategyToEdit,
  deleteStrategy,
} from "../_redux/strategies/strategy.actions";

let strategyImageURL = stratPhoto;
//let strategyImageURL = "https://firebasestorage.googleapis.com/v0/b/daedalus-ecc1f.appspot.com/o/Strategy_Images%2FScreen%20Shot%202022-07-30%20at%208.43.06%20PM.png?alt=media&token=6f1c5116-e685-4ddf-bc0e-080bc9c07c97";

function performanceCalc(strat) {
  if (strat.type === "paper_trader") {
    let p = strat.paperTraderPerformance
      .map((a) => a.performance)
      .reduce((a, b) => Number(a) + Number(b), 0);
    return p.toFixed(2);
  }
  if (strat.type === "live_trader") {
    let p = strat.liveTraderPerformance
      .map((a) => a.performance)
      .reduce((a, b) => Number(a) + Number(b), 0);
    return p.toFixed(2);
  }
}

const StrategyCard = (props) => {
  const { strategy, name } = props;

  const params = useParams();
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "400px",
        height: "500px",
        borderRadius: "20px",
        backgroundColor: "#212329",
        color: "white",
      }}
    >
      <Image style={{ marginTop: "30px" }} src={strategyImageURL} />
      <div
        style={{
          marginTop: "-300px",
          textAlign: "left",
          marginLeft: "30px",
          fontSize: "20px",
          width: "400px",
          height: "100px",
        }}
      >
        {strategy.name.length > 24
          ? strategy.name.substring(0, 24) + "..."
          : strategy.name}
      </div>
      <Col style={{ marginLeft: "310px", marginTop: "-100px" }}>
        <Button
          style={{
            backgroundColor: "#52A669",
            border: "none",
            width: "60px",
            height: "25px",
            color: "white",
            marginBottom: "10px",
          }}
          onClick={() => {
            props.setStrategyToEdit(strategy);
            navigate("/strategy-builder/" + params.brokerName);
          }}
        >
          <div style={{ marginTop: "-6px", fontSize: "15px" }}>Edit</div>
        </Button>
        <Button
          style={{
            backgroundColor: "#52A669",
            border: "none",
            width: "60px",
            height: "25px",
            color: "white",
            marginBottom: "10px",
          }}
          onClick={() => {
            NotificationManager.info("Coming Soon!");
          }}
        >
          <div style={{ marginTop: "-6px", fontSize: "15px" }}> Earn</div>
        </Button>
        <Button
          style={{
            backgroundColor: "#52A669",
            border: "none",
            width: "60px",
            height: "25px",
            color: "white",
            marginBottom: "10px",
          }}
          onClick={async () => await props.deleteStrategy(strategy)}
        >
          <div style={{ marginTop: "-6px", fontSize: "15px" }}> Delete</div>
        </Button>
        {/* <Toggle
          defaultChecked={strategy.isLive}
          aria-label="No label tag"
          onChange={async () => {
            // props.strategyToEditAction(strategy);
            console.log("strategy", strategy.uid);
            console.log("strategy", strategy.isLive);
            // setLoading(true);
            let isLive = !strategy.isLive;
            let strategyUid = strategy.uid;

            try {
              await updateStrategy({ isLive, strategyUid });
              NotificationManager.success(
                "Strategy status updated successfully!"
              );
              window.location.replace("/broker");
            } catch (error) {
              NotificationManager.error("Error updating strategy data");
            }
          }}
        /> */}
      </Col>
      <div style={{ marginTop: "130px", marginLeft: "10px", fontSize: "15px" }}>
        YTD Return
      </div>
      <div style={{ marginLeft: "10px", fontSize: "30px" }}>
        {performanceCalc(strategy) < 0 ? "" : ""}{" "}
        {performanceCalc(strategy) ? performanceCalc(strategy) : "0.00"}%
      </div>
      <div style={{ marginLeft: "10px", fontSize: "15px" }}>
        on Broker: {strategy.exchange}
      </div>
      <div style={{ marginLeft: "10px", fontSize: "15px" }}>
        next_execution_at:{" "}
        {new Date(strategy.next_execution_at).toLocaleString() || null}
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>Description</div>
      <div
        style={{
          textAlign: "left",
          marginTop: "10px",
          fontSize: "12px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        {strategy.description
          ? ""
          : "You can add a descripiton to your strategy by clicking the edit button"}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
  strategies: storeState.strategy.strategies,
  broker: storeState.brokers,
  apiStatus: storeState.apiStatus,
});

export default connect(mapStateToProps, {
  setStrategyToEdit,
  deleteStrategy,
})(StrategyCard);
