import { adminConstants } from "./admin.actions";

const initialState = {
  allUsers: [],
  isInitializedAllUsers: false,
};

export const adminReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case adminConstants.ADMIN_USERS_FETCH_SUCCESS:
      return {
        ...state,
        allUsers: payload.allUsers.sort((a, b) =>
          new Date(a.datejoined).getTime() < new Date(b.datejoined).getTime()
            ? -1
            : 1
        ),

        isInitializedAllUsers: true,
      };

    default:
      return state;
  }
};
