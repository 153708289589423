import { NotificationManager } from "react-notifications";
import api from "../../_api/api";
import { TransactionConstants } from "./transaction.constants";

export const addTransaction = (portfolio, transaction) => {
  return async (dispatch) => {
    dispatch({
      type: TransactionConstants.TRANSACTION_CREATE_START,
    });

    return await api.transaction.addTransaction(portfolio, transaction).then(
      (user) => {
        dispatch({
          type: TransactionConstants.TRANSACTION_CREATE_SUCCESS,
          portfolio,
        });
        return user;
      },
      (error) => {
        dispatch({
          type: TransactionConstants.TRANSACTION_CREATE_FAILED,
          error: error.message,
        });
      }
    );
  };
};

export const deleteTransaction = (portfolio_id, transaction_id) => {
  return async (dispatch) => {
    dispatch({
      type: TransactionConstants.TRANSACTION_DELETE_START,
    });

    return await api.portfolios
      .deleteTransaction(portfolio_id, transaction_id)
      .then(
        (user) => {
          NotificationManager.success("Transaction Deleted Successfully");
          dispatch({ type: TransactionConstants.TRANSACTION_DELETE_SUCCESS });
          return user;
        },
        (error) => {
          NotificationManager.error(error.message);
          dispatch({
            type: TransactionConstants.TRANSACTION_DELETE_FAILED,
            error: error.message,
          });
        }
      );
  };
};
