import * as Blockly from "blockly/core";
import { v4 as uuidv4 } from "uuid";

// ---------- //

Blockly.Blocks["buy_block"] = {
  init: function () {
    this.jsonInit({
      type: "buy_block",
      message0: "Buy $ %1 worth",
      args0: [
        {
          type: "field_number",
          name: "BUY_AMOUNT",
        },
      ],
      message1: "of%1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["buy_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let amount = block.getFieldValue("BUY_AMOUNT");
  let asset = block.getFieldValue("ASSET");
  // var code = `result= ACTBUY`;
  // var code = `result = {action: "buy", amount:${amount}, id: "${uuidv4()}"}`
  let code = `executeTrade({action: "buy", amount:${amount}, id: "${uuidv4()}", asset:"${asset}", qtySpecified: false})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// ---------- //

Blockly.Blocks["sell_block"] = {
  init: function () {
    this.jsonInit({
      type: "sell_block",
      message0: "Sell $ %1 worth",
      args0: [
        {
          type: "field_number",
          name: "SELL_AMOUNT",
        },
      ],
      message1: "of%1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["sell_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let amount = block.getFieldValue("SELL_AMOUNT");
  let asset = block.getFieldValue("ASSET");
  // var code = `result= ACTSELL`;
  // var code = `result = {action: "sell", amount:${amount}, id: "${uuidv4()}"}`
  let code = `executeTrade({action: "sell", amount:${amount}, id: "${uuidv4()}",asset:"${asset}", qtySpecified: false})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// ---------- //

Blockly.Blocks["buy_qty_block"] = {
  init: function () {
    console.log("Buy quantify block here ..")
    this.jsonInit({
      type: "buy_qty_block",
      message0: "Buy %1 ",
      args0: [
        {
          type: "field_number",
          name: "BUY_AMOUNT",
        },
        // , {
        //   "type": "input_dummy",
        //   "name": "ASSETSLIST",
        // }
      ],
      message1: "%1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["buy_qty_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let amount = block.getFieldValue("BUY_AMOUNT");
  let asset = block.getFieldValue("ASSET");
  // var code = `result= ACTBUY`;
  // var code = `result = {action: "buy", amount:${amount}, id: "${uuidv4()}"}`
  let code = `executeTrade({action: "buy", amount:${amount}, id: "${uuidv4()}",asset:"${asset}", qtySpecified: true})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

// ---------- //

Blockly.Blocks["sell_qty_block"] = {
  init: function () {
    this.jsonInit({
      type: "sell_qty_block",
      message0: "Sell %1 ",
      args0: [
        {
          type: "field_number",
          name: "SELL_AMOUNT",
        },
      ],
      message1: "%1 ",
      args1: [
        {
          type: "input_dummy",
          name: "ASSETSLIST",
        },
      ],
      previousStatement: true,
      nextStatement: "Action",
      extensions: ["assets_list_extension"],
    });
  },
};

Blockly.JavaScript["sell_qty_block"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let amount = block.getFieldValue("SELL_AMOUNT");
  let asset = block.getFieldValue("ASSET");
  // var code = `result= ACTSELL`;
  // var code = `result = {action: "sell", amount:${amount}, id: "${uuidv4()}"}`
  let code = `executeTrade({action: "sell", amount:${amount}, id: "${uuidv4()}",asset:"${
    asset || ""
  }", qtySpecified: true})`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};
