import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import "firebase/compat/firestore";
import Logger from "./_utils/logger";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = firebase.initializeApp({
  apiKey: "AIzaSyDkjnSLr2ZR9t74lztyWM7WS9IvF8VCQS0",
  authDomain: "daedalus-ecc1f.firebaseapp.com",
  projectId: "daedalus-ecc1f",
  storageBucket: "daedalus-ecc1f.appspot.com",
  messagingSenderId: "111586643997",
  appId: "1:111586643997:web:f03c0d0acaca6937caf6f4",
  measurementId: "G-F891Q3SX24",
});

const db = app.firestore();

export { db };

export function formatFireError(code) {
  switch (code) {
    case "auth/user-not-found":
      return "Email or password incorrect";
    case "auth/wrong-password":
      return "password incorrect";
    default:
      return "Error occured,please try again later.";
  }
}

export const auth = app.auth();
export const functions = getFunctions(app);

// the following snippet connects frontend to backend when working in "local"

// if (process.env.NODE_ENV === "development") {
//   connectFunctionsEmulator(functions, "localhost", 5000);
// }

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export default app;
