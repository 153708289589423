import React from "react";
import { db } from "../firebase";
import classNames from "classnames";
import { SectionProps } from "../_utils/utils-section-props";
import { Link, useNavigate } from "react-router-dom";
import Input from "../_layout/Input";
import { Button, Form } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/auth";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { connect } from "react-redux";
import { userActions } from "../_redux/users/user.actions";
import { formatFireError } from "../firebase";
import { emailInBetaList } from "../_api/user.service";

import BackgroundImage from "../_assets/img/signinbg.png";

import { signOnStyles } from "../_ui/theme.js";

const initialState = {
  formData: {
    email: "",
    password: "",
  },
  formErrors: {
    email: "",
    password: "",
  },
};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      emailAllowedToSignUp: false,
      checkInList: false,
    };
  }

  signUps = async (e) => {
    console.log(e)
    e.preventDefault();
    const { register, navigate } = this.props;
    let formData = new FormData(e.target.form);
    this.setState({ loading: true });
    let email = formData.get("email");

    //Check if in beta list

    let isInBetaList = false;
    let result = await db
      .collection("beta_invites")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        let x = 0;
        let res = [];
        while (x < data.length) {
          res.push(data[x].email);
          x++;
        }
        if (res.length > 0) {
          isInBetaList = res.indexOf(email) !== -1;
          this.setState({ emailAllowedToSignUp: isInBetaList });
        }
      });
    
      try {
        const resData = await register({
          email: formData.get("email"),
          password: formData.get("password"),
          firstName: formData.get("firstname"),
          lastName: formData.get("lastname"),
        });
        if (resData) {
          navigate("/connect");
        }
      } catch (error) {
        this.setState({ loading: false });
        NotificationManager.error(formatFireError(error.code));
      }

      this.setState({ loading: false });
   
      this.setState({ loading: false });
    
    
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      register,
      navigate,
      ...props
    } = this.props;

    const { loading } = this.state;

    const outerClasses = classNames(
      "signin section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "signin-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <div
        className="container mx-auto"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "80%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <section
          className={classNames(
            "signin section",
            topOuterDivider && "has-top-divider",
            bottomOuterDivider && "has-bottom-divider",
            hasBgColor && "has-bg-color",
            invertColor && "invert-color",
            className
          )}
        >
          <div className="container">
            <div
              className={classNames(
                "signin-inner section-inner",
                topDivider && "has-top-divider",
                bottomDivider && "has-bottom-divider"
              )}
            >
              <text className="mb-3 fw-bold fs-5 text-light">{`👋 Welcome! We exist to make trading easier`}</text>
              <br />
              <text className="mb-0 fw-bold fs-5 text-light">{`\nSign up and start trading with us!`}</text>
              <div className="tiles-wrap justify-content-start">
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <form
                      onSubmit={(e) => {
                        this.signUps(e);
                      }}
                    >
                        <div className="">
                          <Input
                            style={signOnStyles}
                            label="First name"
                            placeholder="First name"
                            labelHidden
                            name="firstname"
                            defaultValue=""
                            disabled={loading}
                            required
                          />
                        </div>
                        <div className="">
                          <Input
                            style={signOnStyles}
                            label="Last name"
                            placeholder="Last name"
                            labelHidden
                            name="lastname"
                            defaultValue=""
                            disabled={loading}
                            required
                          />
                        </div>
                        <div className="">
                          <Input
                            style={signOnStyles}
                            type="email"
                            label="Email"
                            placeholder="Email"
                            defaultValue=""
                            name="email"
                            labelHidden
                            disabled={loading}
                            required
                          />
                        </div>
                        <div className="mb-2">
                          <Input
                            style={signOnStyles}
                            type="password"
                            label="Password"
                            name="password"
                            defaultValue=""
                            placeholder="Password"
                            disabled={loading}
                            labelHidden
                            required
                          />
                        </div>
                        <div className="mt-3 ">
                          <Button
                            color="primary"
                            style={{
                              color: "black",
                              border: 0,
                              backgroundColor: "#00EBAA",
                              width: "20rem",
                            }}
                            loading={loading}
                            disabled={loading}
                            wide
                            onClick={(e) => this.signUps(e)}
                          >
                            Sign up
                          </Button>
                        </div>
                    </form>

                    <div className="my-1 center-content">or</div>

                    <a
                      class="btn btn-outline-dark"
                      href="/login"
                      role="button"
                      style={{
                        textTransform: "none",
                        width: "20rem",
                        color: "white",
                        borderColor: "#306BF1",
                        backgroundColor: `rgb(48,107,241, 0.1)`,
                        marginBottom: "1rem",
                      }}
                    >
                      <img
                        width="20px"
                        alt="Google sign-in"
                        style={{ display: "inline-block", marginRight: "1rem" }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                      />
                      Continue with Google
                    </a>

                  
                    <div className="signin-bottom">
                      <div className="pt-8 text-xs">
                        Already have an account?
                        <Link to="/login/" className="ml-3 text-primary">
                          {"\tLogin"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <SignupForm {...props} navigate={navigate} />;
}

const actionCreators = {
  register: userActions.register,
};

export default connect(null, actionCreators)(WithNavigate);
