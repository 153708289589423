/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author samelh@google.com (Sam El-Husseini)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks

import * as Blockly from "blockly/core";

// var cryptoList = require("../blockly-asset-lists/cryptoList");
// var stockList = require("../blockly-asset-lists/stockList");
// let coinList = require("../../_api/coinbase.service").getAllCoinbaseAssets;
// cryptoList = cryptoList.list;
// stockList = stockList.list;

// var loaded = { loaded: false, data: [["Loading...", ""]] };

// let showCoinList = (async function () {
//   let loadingData, sortedData;
//   const persistVariables = JSON.parse(localStorage.getItem("persist:root"));

//   console.log("HERE");
//   if (
//     JSON.parse(persistVariables.account).name == "Alpaca" ||
//     JSON.parse(persistVariables.account).name == "Stock Paper Trading"
//   ) {
//     console.log("HERE2");
//     sortedData = stockListAlpaca.list;
//     // to test the crypto with alpaca
//     // sortedData = cryptoListAlpaca.list
//   } else {
//     console.log("HERE3");
//     loadingData = await coinList();
//     sortedData = loadingData.data
//       .filter((asset) => asset.match(/USD$/))
//       .sort()
//       .map((x) => [x.match(/.*(?=-)/)[0], x]);
//   }
//   if (sortedData) {
//     loaded.loaded = true;
//     loaded.data = [...sortedData];
//   }
// })();

//Dynamic Rendering of this list in the crypto  drop down menu
// Blockly.Extensions.register("assets_list_extension", function () {
//   // let y = await coinList()
//   // let z= y.data.filter(asset=> asset.match(/USD$/)).sort().map(x=> [x.match(/.*(?=-)/)[0], x])
//   let x = 0;
//   this.getInput("ASSETSLIST").appendField(
//     new Blockly.FieldDropdown(function () {
//       var options = [];

//       for (var i = 0; i < loaded.data.length; i++) {
//         x++;
//         options.push(loaded.data[i]);
//       }
//       return options;
//     }),
//     "ASSET"
//   );
// });

// field time request
// https://github.com/google/blockly-samples/issues/522
//
// Type-checks
// https://developers.google.com/blockly/guides/create-custom-blocks/type-checks
//
// Fields
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks#args
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks#fields
// https://developers.google.com/blockly/guides/create-custom-blocks/fields/built-in-fields/overview

// ---------- //

Blockly.Blocks["constant"] = {
  init: function () {
    this.jsonInit({
      type: "constant",
      message0: "%1",
      args0: [
        {
          type: "field_input",
          name: "constantvalue",
        },
      ],
      output: true,
    });
  },
};

Blockly.JavaScript["constant"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("constantvalue");
  var code = asset;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["buul"] = {
  init: function () {
    this.jsonInit({
      type: "buul",
      message0: "%1",
      args0: [
        {
          type: "field_dropdown",
          name: "buulean",
          options: [
            ["True", "True"],
            ["False", "False"],
          ],
        },
      ],
      output: true,
    });
  },
};

Blockly.JavaScript["buul"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("buulean");
  var code = asset;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};

// ---------- //

Blockly.Blocks["dollar_value"] = {
  init: function () {
    this.jsonInit({
      type: "dollar_value",
      message0: "$ %1",
      args0: [
        {
          type: "field_number",
          name: "DOLLAR_VALUE",
        },
      ],
      output: true,
    });
  },
};

Blockly.JavaScript["dollar_value"] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  let asset = block.getFieldValue("DOLLAR_VALUE");
  var code = `INDPRICE`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE];
};
