import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Row, Container } from "react-bootstrap";
//
import GenericSection from "../_layout/GenericSection";
import BrokerCard from "./broker-card.js";

const Home = (props) => {
  const { user } = props;

  useEffect(() => {
   
  }, [user]);

  return (
    <div style={{height:"100vh"}}>
      <div
        style={{
          color: "white",
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "40px"
        }}
      >
        {/* {`👋 Welcome Back to Daedalus, ${user.firstname}\n`} */}
       { 'You do not seem to have been approved for access to the trading system yet.'}
      </div>

    
    </div>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {})(Home);
