import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import DaedalusLogo from "../_assets/img/logo.png";
// const imageLogo = require("../../../_assets/logo.png")
const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <Link to="/home" className="flex items-center">
        <Image
          src={DaedalusLogo}
          alt="Daedalus"
          width={150}
          // height={80}
        />
      </Link>
    </div>
  );
};

export default Logo;
