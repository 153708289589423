import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";
import { createBrowserHistory } from "history";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";

import "./_ui/scss/style.scss";
import "./App.css";

import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//
import { adminReducer } from "./_redux/admin/admin.reducer";
import { portfolioReducer } from "./_redux/portfolio/portfolio.reducer";
import { brokersReducer } from "./_redux/brokers/brokers.reducer";
import { paymentsReducer } from "./_redux/payments/payments.reducer";
import { strategyReducer } from "./_redux/strategies/strategy.reducer";
import { templatesReducer } from "./_redux/templates/templates.reducer";
import { userReducer } from "./_redux/users/user.reducer";
//
import { userConstants } from "./_redux/users/user.constants";

import { apiStatusReducer } from "./_api/api-status-reducer";

const cors = require("cors")({
  origin: "*",
  credentials: true,
  methods: "GET",
});

const history = createBrowserHistory();

const appReducer = combineReducers({
  admin: adminReducer,
  portfolios: portfolioReducer,
  apiStatus: apiStatusReducer,
  brokers: brokersReducer,
  strategy: strategyReducer,
  payments: paymentsReducer,
  templates: templatesReducer,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.USER_LOGOUT_SUCCESS) {
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage,
  },
  persistedReducer = persistReducer(persistConfig, rootReducer),
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
  store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk))
  ),
  persistor = persistStore(store);

// const app = (
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//       {/* </Router> */}
//     </PersistGate>
//   </Provider>
// );
//ReactDOM.render(app, document.getElementById("root"));

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </Router> */}
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// // Before
// import { render } from 'react-dom';
// const container = document.getElementById('app');
// render(<App tab="home" />, container);

// // After
// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);
