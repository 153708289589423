export default {
  formInput: {
    color: "#A2A2A5",
    backgroundColor: "#1A1C38",
    border: "0",
    fontWeight: 300,
    fontSize: 15,
    padding: "20px",
    borderRadius: "10px",
    marginBottom: "5vh",
  },
  brokerLogo: {
    //
  },
  header: {
    logo: {
      //
    },
    title: {
      //
    },
  },
};
