import { NotificationManager } from "react-notifications";
import api from "../../_api/api";
import { userService } from "../../_api/user.service";
import { userConstants } from "./user.constants";

export const userActions = {
  login,
  logout,
  register,
  updateUserV2,
  loginSuccess,
};

function loginSuccess(user) {
  return {
    type: userConstants.USER_LOGIN_SUCCESS,
    user,
  };
}

function login(username, password, propshistory) {
  return (dispatch) => {
    dispatch({ type: userConstants.USER_LOGIN_START });

    userService.login(username, password).then(
      (user) => {
        dispatch({ type: userConstants.USER_LOGIN_SUCCESS, user });
        propshistory.replace("/home");
      },
      (error) => {
        NotificationManager.error(error.message);
        dispatch({ type: userConstants.LOGIN_FAIL });
        // dispatch(alertActions.error(error.message));
      }
    );
  };
}



function logout() {
  return { type: userConstants.USER_LOGOUT_SUCCESS };
}

export const userLogout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: userConstants.USER_LOGOUT_START,
        payload: {},
      });

      // this is the right place to centralize logout
      // but the app is not yet ready for it

      dispatch({
        type: userConstants.USER_LOGOUT_SUCCESS,
        payload: {},
      });
      return true;
    } catch (e) {
      dispatch({
        type: userConstants.USER_LOGOUT_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

function register(user) {
  return (dispatch) => {
    dispatch({ type: userConstants.USER_LOGIN_START });
    console.log("working...")
    return userService.register(user).then(
      (user) => {
        NotificationManager.success("Registration successful");
        dispatch({ type: userConstants.USER_LOGIN_SUCCESS, user });
        return user;
      },
      (error) => {
        NotificationManager.error(error.message);
        dispatch({ type: userConstants.LOGIN_FAIL, error: error.message });
        // dispatch(alertActions.error(error.message));
      }
    );
  };
}

function updateUserV2(user) {
  return (dispatch) => {
    dispatch({ type: userConstants.USER_UPDATE_START });
    return userService.updateUser(user).then(
      (user) => {
        dispatch({ type: userConstants.USER_UPDATE_SUCCESS, user });
        NotificationManager.success("Updated");
        return user;
      },
      (error) => {
        NotificationManager.error("Error");
        alert(error.message)
        dispatch({ type: userConstants.USER_UPDATE_FAIL, error: error.message });
        // dispatch(alertActions.error(error.message));
      }
    );
  };
}

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.USER_UPDATE_START,
    });


    const { success } = await api.user.updateUser(user);

    if (success) {
      NotificationManager.success("Updated");

      dispatch({
        type: userConstants.USER_UPDATE_SUCCESS,
        payload: {}, // @devGabe : the user in the store is updated from HERE
      });
      return true;
    } else {
      throw new Error();
    }


  } catch (e) {
    NotificationManager.error("Error");

    dispatch({
      type: userConstants.USER_UPDATE_FAIL,
    });
    return false;
  }
};
