import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";

import PhoneInput from "react-phone-input-2";

import isEmail from "validator/lib/isEmail";
import isPostalCode from "validator/lib/isPostalCode";
import { isValidName, isValidAddress, isValidPostalCode } from "../_utils/utils-validators.js";
import theme from "../_ui/theme-default";
import { formatPhoneNumberIntoE164 } from "../_utils/utils";

import { updateUser } from "../_redux/users/user.actions";

const initialState = {
  formData: {
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    postalCode: "",
    phone: "",
  },
  formErrors: {
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    postalCode: "",
    phone: "",
  },
  subscriptionTier: "",
  isEditing: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFormData":
      let _formData = {
        ...state.formData,
      };
      let _formErrors = {
        ...state.formErrors,
      };

      _formData[action.field] = action.payload;

      return {
        ...state,
        formData: _formData,
        formErrors: {
          ..._formErrors,
          [action.field]: "",
        },
        isEditing: true,
      };

    case "setFormErrors":
      return {
        ...state,
        formErrors: action.payload,
      };

    case "setPhoneNumber":
      let __formData = {
        ...state.formData,
      };
      let __formErrors = {
        ...state.formErrors,
      };
      __formData[action.field] = action.payload.phone;
      __formErrors[action.field] = "";

    case "setIsEditing":
      return {
        ...state,
        isEditing: action.payload.isEditing,
      };

    case "reset":
      const { user } = action.payload;
      let resetState = JSON.parse(JSON.stringify(initialState)); //deep copy

      resetState.formData.firstname = user.firstname;
      resetState.formData.lastname = user.lastname;
      resetState.formData.email = user.email;
      resetState.formData.address = user.address;
      resetState.formData.phone = user.phone;
      resetState.formData.postalCode = user.postalCode;

      return resetState;
    default:
      throw new Error();
  }
};

const SettingsPage = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = props;
  const { formData, formErrors } = state;

  useEffect(() => {
    if (user) {
      dispatch({
        type: "reset",
        payload: {
          user: user,
        },
      });
    }
  }, [user]);

  const checkAndSubmitForm = async () => {
    let formData = {
      ...state.formData,
    };
    let formErrors = {
      ...state.formErrors,
    };

    // trims strings
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === "string" ||
        formData[key] instanceof String
      ) {
        formData[key] = formData[key].trim();
      }
    });

    formData.phone = formatPhoneNumberIntoE164(formData.phone);

    if (!isValidName(formData.firstname)) {
      formErrors.firstname = "Empty or invalid value";
    }
    if (!isValidName(formData.lastname)) {
      formErrors.firstname = "Empty or invalid value";
    }
    if (!isEmail(formData.email)) {
      formErrors.email = "Empty or invalid value";
    }
    if (!isValidPostalCode(formData.postalCode, "US")) {
      formErrors.postalCode = "Empty or invalid value";
    }
    if (!isValidAddress(formData.address)) {
      formErrors.address = "Empty or invalid value";
    }

    const hasErrors = Object.values(formErrors).some(
      (error) => error.length !== 0
    );

    if (hasErrors) {
      console.log(formErrors);
      dispatch({
        type: "setFormErrors",
        payload: formErrors,
      });
    } else {
      const updatedUser = {
        firstname: formData.firstname || "",
        lastname: formData.lastname || "",
        email: formData.email || "",
        address: formData.address || "",
        phone: formData.phone || "",
        postalCode: formData.postalCode || "",
      };
      await props.updateUser(updatedUser);
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: "30px",
          marginLeft: "50px",
          color: "#00EBAA",
          fontSize: 30,
        }}
      >
        Settings
      </div>
      <Container
        style={{
          minWidth: "150vh",
          padding: "50px",
        }}
      >
        <Card
          style={{
            backgroundColor: "#111329",
          }}
        >
          <Card.Body>
            <Row xs={1} md={2} className="p-4 pb-0">
              <Col>
                <Card
                  style={{
                    backgroundColor: "#111329",
                  }}
                  border="0"
                >
                  <Container className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <Card.Img
                        style={{
                          width: "120px",
                        }}
                        className="img-fluid rounded-circle border border-dark border-3"
                        //NEED TO BE USER's and have image upload options
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-2.webp"
                        alt="Generic placeholder image"
                        fluid="true"
                      />
                    </div>
                    <Card.Body>
                      <Card.Title className="mb-0">
                        {user.firstname + " " + user.lastname}
                      </Card.Title>
                      <Card.Subtitle className="mt-0 text-muted">
                        Location, State
                      </Card.Subtitle>
                    </Card.Body>
                  </Container>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#1A1C38",
                  }}
                  className="text-center"
                >
                  <Container className="d-flex">
                    <Card.Body>
                      <Card.Title>
                        <span
                          style={{
                            color: "#00EBAA",
                          }}
                        >
                          SUBSCRIPTION PLAN
                        </span>
                        : {user.subscriptionTier.toUpperCase()}
                      </Card.Title>
                      <Card.Text className="text-decoration-underline">
                        <Link to="/billing"> Upgrade </Link>
                      </Card.Text>
                    </Card.Body>
                  </Container>
                </Card>
              </Col>
            </Row>
            <Col>
              <Card
                style={{
                  backgroundColor: "#111329",
                }}
                border="0"
              >
                <Card.Body>
                  <Card.Title
                    style={{
                      color: "#00EBAA",
                    }}
                  >
                    USER DETAILS
                  </Card.Title>
                  <Row xs={1} md={2} className="g-4">
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          style={theme.formInput}
                          placeholder="Name"
                          name="firstname"
                          disabled={false}
                          value={formData.firstname || ""}
                          onChange={(e) =>
                            dispatch({
                              type: "setFormData",
                              field: e.target.name,
                              payload: e.target.value,
                            })
                          }
                        />
                        {formErrors.firstname && (
                          <Form.Text className="text-danger">
                            {formErrors.firstname}
                          </Form.Text>
                        )}
                        <Form.Control
                          style={theme.formInput}
                          placeholder="Email"
                          name="email"
                          disabled={false}
                          value={formData.email || ""}
                          onChange={(e) =>
                            dispatch({
                              type: "setFormData",
                              field: e.target.name,
                              payload: e.target.value,
                            })
                          }
                        />
                        {formErrors.email && (
                          <Form.Text className="text-danger">
                            {formErrors.email}
                          </Form.Text>
                        )}
                        <Form.Control
                          style={theme.formInput}
                          placeholder="Lastname"
                          name="lastname"
                          disabled={false}
                          value={formData.lastname || ""}
                          onChange={(e) =>
                            dispatch({
                              type: "setFormData",
                              field: e.target.name,
                              payload: e.target.value,
                            })
                          }
                        />
                        {formErrors.lastname && (
                          <Form.Text className="text-danger">
                            {formErrors.lastname}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Control
                        style={theme.formInput}
                        placeholder="Address"
                        name="address"
                        disabled={false}
                        value={formData.address || ""}
                        onChange={(e) =>
                          dispatch({
                            type: "setFormData",
                            field: e.target.name,
                            payload: e.target.value,
                          })
                        }
                      />
                      {formErrors.address && (
                        <Form.Text className="text-danger">
                          {formErrors.address}
                        </Form.Text>
                      )}
                      <PhoneInput
                        inputProps={{
                          // name: "phone",
                          // label: "phone",
                          required: true,
                        }}
                        inputStyle={theme.formInput}
                        // specialLabel={"Phone Number"}
                        specialLabel={""}
                        country={"us"} // initialcountry (in some cases behaves weirdly)
                        onlyCountries={["ca", "mx", "us"]}
                        countryCodeEditable={false}
                        disableDropdown={false}
                        placeholder={"Phone Number"}
                        value={formData.phone || ""}
                        onChange={(value, country, e, formattedValue) =>
                          dispatch({
                            type: "setPhoneNumber",
                            field: "phone", // do not use e.target.name here
                            payload: {
                              phoneNumber: "+" + value,
                              countryCode: country.countryCode,
                            },
                          })
                        }
                      />
                      {formErrors.phone && (
                        <Form.Text className="text-danger">
                          {formErrors.phone}
                        </Form.Text>
                      )}
                      <Form.Control
                        style={theme.formInput}
                        placeholder="Postal Code"
                        name="postalCode"
                        disabled={false}
                        value={formData.postalCode || ""}
                        onChange={(e) =>
                          dispatch({
                            type: "setFormData",
                            field: e.target.name,
                            payload: e.target.value,
                          })
                        }
                      />
                      {formErrors.postalCode && (
                        <Form.Text className="text-danger">
                          {formErrors.postalCode}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col className="my-3 d-flex justify-content-center">
              <Button
                className="px-5 py-3"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#5E67DF",
                  border: "0",
                }}
                onClick={async () => await checkAndSubmitForm()}
              >
                Save changes
              </Button>
            </Col>
          </Card.Body>
        </Card>
      </Container>
      {/* <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              disabled={false}
              value={formData.password}
              onChange={(e) =>
                dispatch({
                  type: "setFormData",
                  field: e.target.name,
                  payload: e.target.value,
                })
              }
            />
            {formErrors.password && (
              <Form.Text className="text-danger">{formErrors.password}</Form.Text>
            )}
          </Form.Group> */}
    </>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {
  updateUser,
})(SettingsPage);
