export const defaultStocksList = ["ABEO", "BMAQ", "TSLA"];

export const defaultCryptosList = [
  "AAVE/USD",
  "AAVE/USDT",
  "ALGO/USD",
  "ALGO/USDT",
  "AVAX/BTC",
  "AVAX/USD",
  "AVAX/USDT",
];
