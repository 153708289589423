import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "./Logo";
import {Link} from 'react-router-dom'

function Footer(props) {
  const [topOuterDivider, settopOuterDivider] = useState(
    props.topOuterDivider || false
  );
  const [topDivider, settopDivider] = useState(props.topDivider || false);

  return (
    <footer
      {...props}
      className={classNames(
        "site-footer center-content-mobile",
        topOuterDivider && "has-top-divider",
        props.className
      )}
    >
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-top space-between text-xxs container">
            <Logo />
            <div className="footer-bottom space-between text-xxs invert-order-desktop pt-0">
              <div className="footer-copyright text-lg">
                &copy;{" "}
                {`${new Date().getFullYear()} Daedalus, all rights
                reserved`}
              </div>
            </div>
            <div {...props} className={classNames("footer-social", null)}>
              <ul className="list-reset">
                <li>
                  <a href="https://twitter.com/Daedalustrading">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Twitter</title>
                      <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/daedalustrading/">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Instagram</title>
                      <g>
                        <circle cx="12.145" cy="3.892" r="1" />
                        <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                        <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-top space-between text-xxs invert-order-desktop pt-0 align-items-center 
          justify-content-center">
              <div className="footer-bottom text-lg">
                <Link to="/privacy-policy" className="px-4 py-8">
                   Privacy Policy
                </Link>
                <Link to="/terms-of-service" className="px-4 py-8">
                   Terms of Service
                </Link>
                <Link to="/beta-agreement-android" className="px-4 py-8">
                   Beta Agreement
                </Link>
              </div>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
