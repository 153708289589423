import React, { useState } from 'react';
import Select from 'react-select';


const SelectDropDown = ({ options, label, setValue }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div>
      <label className='form-label'>{label}</label>
      <Select
        defaultValue={selectedOption}
        onChange={(e) => setValue(e.value)}
        options={options}
      />
    </div>
  );
}

export default SelectDropDown;