export const PortfolioConstants = {
    PORTFOLIOS_USERS_FETCH_START: "PORTFOLIOS_USERS_FETCH_START",
    PORTFOLIOS_USERS_FETCH_SUCCESS: "PORTFOLIOS_USERS_FETCH_SUCCESS",
    PORTFOLIOS_USERS_FETCH_FAIL: "PORTFOLIOS_USERS_FETCH_FAIL",

    PORTFOLIO_TRANSACTIONS_START: "PORTFOLIO_TRANSACTIONS_START",
    PORTFOLIOS_TRANSACTIONS_SUCCESS: "PORTFOLIOS_TRANSACTIONS_SUCCESS",
    PORTFOLIOS_TRANSACTIONS_FAIL: "PORTFOLIOS_TRANSACTIONS_FAIL",

    PORTFOLIO_UPDATE_START: "PORTFOLIO_UPDATE_START",
    PORTFOLIO_UPDATE_SUCCESS: "PORTFOLIO_UPDATE_SUCCESS",
    PORTFOLIO_UPDATE_FAILED: "PORTFOLIO_UPDATE_FAILED",
  
    PORTFOLIO_CREATE_START: "PORTFOLIO_CREATE_START",
    PORTFOLIO_CREATE_SUCCESS: "PORTFOLIO_CREATE_SUCCESS",
    PORTFOLIO_CREATE_FAILED: "PORTFOLIO_CREATE_FAILED",

    PORTFOLIO_DELETE_START: "PORTFOLIO_DELETE_START",
    PORTFOLIO_DELETE_SUCCESS: "PORTFOLIO_DELETE_SUCCESS",
    PORTFOLIO_DELETE_FAILED: "PORTFOLIO_DELETE_FAILED",
  
  };
  