import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
// Layouts
import LayoutDefault from "./_layout/LayoutDefault";

// Views public
import BetaAgreementAndroidPage from "./users/beta-agreement-android-page";
import Login from "./users/login-page";
import PrivacyPolicyPage from "./users/privacy-policy-page";
import Signup from "./users/signup-page";
import TermsOfServicePage from "./users/terms-of-service-page";
// Views private
import AdminUsersPage from "./admin/admin-users-page.js";
import Billing from "./payments/billing-page";
import BrokersConnectPage from "./brokers/brokers-connect-page";
import BrokerShowStatusPage from "./brokers/broker-show-status-page";
import EmailVerify from "./users/email-verify";
import Home from "./home/home-page";
import Home2 from "./home/home-block";
import MyPortfolio from "./my-portfolio/MyPortfolio";
import SettingsPage from "./users/settings-page";
import StrategiesPage from "./strategies/strategies-page";
import StrategyBuilderPage from "./strategies/strategy-builder-page";
import SubscriptionCanceled from "./payments/Canceled";
import SubscriptionSuccess from "./payments/Success";
import TemplatesPage from "./templates/templates-page";

import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthProvider from "./_utils/auth_context";
import RequireAuth from "./_routing/require_auth";

import { fetchStrategies } from "./_redux/strategies/strategy.actions";
import { fetchTemplateStrategies } from "./_redux/templates/templates.actions";
import {
  fetchAssetsAlpaca,
  fetchAssetsFTX,
  fetchAssetsCoinbase,
} from "./_redux/brokers/brokers.actions";

const App = (props) => {
  const { user } = props;

  const getUnProtectedRouteElem = (component) => {
    if (user) {
      return <Navigate to="/home" />;
    }
    return component;
  };

  useEffect(() => {
    const initializeReducers = async () => {
      // await is not needed here
      props.fetchStrategies();
      props.fetchTemplateStrategies();
      //
      props.fetchAssetsAlpaca();
      //  props.fetchAssetsFTX();
      // props.fetchAssetsCoinbase();
    };
    initializeReducers();

    return () => {};
  }, []);

  return (
    <AuthProvider>
      <Routes>
        <Route element={<LayoutDefault />}>
          <Route exact path="/" element={getUnProtectedRouteElem(<Login />)} />
          {user && user.admin && (
            <Route
              exact
              path="/admin"
              element={
                <RequireAuth>
                  <AdminUsersPage />
                </RequireAuth>
              }
            />
          )}
          <Route
            exact
            path="/login"
            element={getUnProtectedRouteElem(<Login />)}
          />
          <Route
            exact
            path="/signup"
            element={getUnProtectedRouteElem(<Signup />)}
          />
          <Route
            path="/beta-agreement-android"
            exact
            element={getUnProtectedRouteElem(<BetaAgreementAndroidPage />)}
          />
          <Route
            path="/privacy-policy"
            exact
            element={getUnProtectedRouteElem(<PrivacyPolicyPage />)}
          />
          <Route
            path="/terms-of-service"
            exact
            element={<TermsOfServicePage />}
          />
          <Route
            exact
            path="/email-verify"
            element={
              <RequireAuth>
                <EmailVerify />
              </RequireAuth>
            }
          />
          <Route
            path="/billing"
            exact
            element={
              <RequireAuth>
                <Billing />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/strategy-builder/:brokerName"
            element={
              <RequireAuth>
                <StrategyBuilderPage />
              </RequireAuth>
            }
          />
          <Route
            path="/connect"
            exact
            element={
              <RequireAuth>
                <BrokersConnectPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/home2"
            element={
              <RequireAuth>
                <Home2 />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            exact
            element={
              <RequireAuth>
                <SettingsPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/broker/:brokerName"
            element={
              <RequireAuth>
                <BrokerShowStatusPage />
              </RequireAuth>
            }
          />
          <Route
            path="/my-portfolio"
            exact
            element={
              <RequireAuth>
                <MyPortfolio />
              </RequireAuth>
            }
          />
          <Route
            path="/strategies"
            exact
            element={
              <RequireAuth>
                <StrategiesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/templates"
            exact
            element={
              <RequireAuth>
                <TemplatesPage />
              </RequireAuth>
            }
          />
          <Route
            path="/subscription-success"
            exact
            element={
              <RequireAuth>
                <SubscriptionSuccess />
              </RequireAuth>
            }
          />
          <Route
            path="/subscription-canceled"
            exact
            element={
              <RequireAuth>
                <SubscriptionCanceled />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

const mapStateToProps = (storeState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {
  fetchStrategies,
  fetchTemplateStrategies,
  fetchAssetsFTX,
  fetchAssetsAlpaca,
  fetchAssetsCoinbase,
})(App);
