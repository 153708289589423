import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";

import ModalHeader from "react-bootstrap/ModalHeader";
import Form from "react-bootstrap/Form";
import Input from "../_layout/Input";
import { useSelector, connect } from "react-redux";
import { config } from "../config";
import { cryptoSymbols } from "../_assets/cryptoSymbols";
import { stockSymbols } from "../_assets/stockSymbols";
import AsyncSelect from "react-select/async";
import { callService } from "../_api/api";
import { NotificationManager } from "react-notifications";
import { addTransaction } from "../_redux/transaction/transaction.actions";
import { useDispatch } from "react-redux";
import SelectDropDown from "../_layout/SelectDropDown";
import { fetchStockPrice } from "../_redux/brokers/brokers.actions";

const BuySellModal = (props) => {
  // const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const {
    type = "buy",
    isOpen,
    onClose,
    onSave,
    account,
    portfolioDoc,
    listings,
    currenciesList,
    stockPrice,
    tradingType,
    setTradingType,
  } = props;

  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [date, setDate] = useState(null);
  const [symbol, setSymbol] = useState("BTC");
  const [currency, setCurrency] = useState("USD");
  const [processing, setProcessing] = useState(false);
  const [perType, setPerType] = useState("per coin");
  const [assetType, setAssetType] = useState("crypto");
  const [isHolding, setIsHolding] = useState(false);

  const handleConversion = async () => {
    const url = `https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=${currency}&api_key=5a76a5adc984a5eaf9eadb1a2fbfbbb273141d318043c8ffb987ffd8e3932349`;
    const response = await fetch(url);
    const result = await response.json();
    setPrice(result[currency]);
  };
  useEffect(() => {
    handleConversion();
  }, [symbol, currency]);

  // useEffect(() => {
  //   if(stockPrice && stockPrice){
  //     setPrice(stockPrice.latestPrice)
  //   }
  // }, [stockPrice])

  const filterSymbols = (inputValue) => {
    const options = assetType === "stock" ? stockSymbols : cryptoSymbols;
    return options
      .filter((item) =>
        (item.symbol + " - " + item.name)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
      .splice(0, 100)
      .map((item) => ({
        label: item.symbol + " - " + item.name,
        value: item.symbol,
        symbol: item.symbol,
        currency: item.currency,
      }));
  };
  useEffect(() => {
    if (!isOpen) {
      setPrice(0);
      setQuantity(0);
    }
  }, [isOpen]);

  const loadOptions = (inputValue, callback) => {
    callback(filterSymbols(inputValue));
  };

  const getCurrencies = {
    BTC: "USD",
    ETH: "EUR",
  };

  const process = async () => {
    if (!(quantity > 0)) return alert("Quantity is invalid.");
    if (!symbol) return alert("Symbol is invalid.");

    // setProcessing(true);
    const params = {
      id: Date.now().toString(),
      portfolio_id: portfolioDoc.id,
      symbol: symbol,
      currency: currency,
      date_time: Date.parse(date).toString(),
      price: perType === "per coin" ? quantity * price : price,
      amount: quantity,
      fee_type: currency,
      per_type: perType,
      is_holding: isHolding,
      is_stock: assetType === "stock" ? true : false,
      type: type,
      notes: "",
      notes_via: "",
    };

    const resData = dispatch(addTransaction(portfolioDoc, params));
    resData
      .then((res) => {
        setProcessing(false);
        onSave();
        return NotificationManager.success("Successfully processed.");
      })
      .catch((error) => {
        setProcessing(false);
        onSave();
        if (error.error) {
          return NotificationManager.success(error.error.message);
        }
      });
  };

  const applyPrice = (data) => {
    (async () => {
      if (data?.value) {
        setSymbol(data.symbol);
        setCurrency(data.currency);
        const url = `${config.iex.base_url}/stable/${account.type}/${data.value}/quote?token=${config.iex.publish_key}`;
        const response = await fetch(url);
        const result = await response.json();
        if (result.latestPrice) {
          setPrice(result.latestPrice);
        }
      } else {
        setPrice(0);
      }
    })();
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };

  const getCryptoListingOptions = () => {
    if (!listings || !listings.length) return;

    const options = [];
    for (let i of listings) {
      options.push({
        label: i.name,
        value: i.symbol,
      });
    }
    return options;
  };

  const formatNumeber = (num) => {
    return (Math.round(parseInt(num) * 100) / 100).toLocaleString();
  };

  return (
    <Modal show={isOpen} contentClassName="bg-dark">
      <div class="d-flex justify-content-around mt-2">
        <div>
          <Button
            variant="success"
            size="sm"
            className="ml-4"
            disabled={tradingType === "buy" ? true : false}
            onClick={() => setTradingType("buy")}
          >
            Buy
          </Button>
        </div>
        <div>
          <Button
            variant="success"
            size="sm"
            className="ml-4"
            disabled={tradingType === "sell" ? true : false}
            onClick={() => setTradingType("sell")}
          >
            Sell
          </Button>
        </div>
      </div>

      <ModalBody>
        <SelectDropDown
          options={getCryptoListingOptions()}
          label={"Select Coin"}
          setValue={setSymbol}
        />
        <SelectDropDown
          options={currenciesList}
          label={"Select Currency"}
          setValue={setCurrency}
        />

        {/* <AsyncSelect 
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onInputChange={(newValue) => handleInputChange(newValue)}
          onChange={(data) => {
            applyPrice(data);
          }}
          isClearable={true}
        /> */}

        {/* {assetType === 'stock' && <AsyncSelect options={ stockSymbolOptions} className="mt-3" onChange={(data)=>{applyPrice('stock',data)}} />} */}
        {/* { assetType === 'crypto' && <AsyncSelect options={cryptoSymbolOptions} className="mt-3" onChange={(data)=>{applyPrice('crypto',data)}} />} */}

        <Input
          type="number"
          label="Price: "
          value={price}
          disabled={true}
          size="sm"
          className="bg-secondary"
        />
        <Input
          type="datetime-local"
          label="Date Time: "
          value={date}
          size="sm"
          onChange={(e) => setDate(e.target.value)}
        />
        <Input
          type="number"
          label="Quantity: "
          size="sm"
          onChange={(e) => setQuantity(e.target.value)}
          value={quantity}
        />

        <select
          className="form-control mt-4"
          onChange={(e) => setPerType(e.target.value)}
        >
          <option vlaue="per coin">per coin</option>
          <option value="per total">per total</option>
        </select>

        <input
          type="checkbox"
          className="form-checkbox"
          onChange={(e) => setIsHolding(e.target.checked)}
          value={isHolding}
        />

        <label className="form-label mr-2">
          {" "}
          &nbsp; Deduct from USD holdings
        </label>

        {quantity && price ? (
          <>
            {perType === "per coin" ? (
              <p className="mt-3 mb-3">
                Your transaction is worth ${formatNumeber(quantity * price)} ($
                {quantity} {symbol})
              </p>
            ) : (
              <p className="mt-3 mb-3">
                Your transaction is worth ${formatNumeber(price)} (${1} {symbol}
                )
              </p>
            )}
          </>
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="success"
          size="sm"
          onClick={() => process()}
          disabled={processing}
        >
          Save
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => onClose()}
          disabled={processing}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (storeState) => ({
  stockPrice: storeState.brokers.stockPrice,
});

const actionCreators = {
  fetchStockPrice: fetchStockPrice,
};

export default connect(mapStateToProps, actionCreators)(BuySellModal);
